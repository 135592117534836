import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, IMenu, IMeta, ITextBook, ITextbookBasicData, S3Server } from "../common";
import { logger } from '../logger';

interface IEBookEdit {
    brand: IBrand;
    menu: IMenu;
    item: ITextBook | null;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
  
function EBookEdit(props: IEBookEdit) {
    
    const [version, setVersion] = useState(1);
    const [lastUpdateDate, setLastUpdateDate] = useState("");
    // const [subject, setSubject] = useState("");
    // const [textbookCategory, setTextbookCategory] = useState<IMeta[]>([]);
    const [basicData, setBasicData] = useState(null as any);
    
    const [viewList, setViewList] = useState(false);

    const [searchData, setSearchData] = useState(null as any);

    const [isNext, setIsNext] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const [allTextbookList, setAllTextbookList] = useState([] as any);
    const [selectedTextbookList, setSelectedTextbookList] = useState([] as any);

    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setLastUpdateDate(util.getDate());

            setVersion(1);

            getTextbookList();
            
            const TE_auth_all = (document.getElementById("TE_auth_all") as HTMLInputElement);
            if(TE_auth_all) {
                TE_auth_all.checked = true;
            }

            const TE_title = document.getElementById("TE_title") as HTMLInputElement;
            TE_title.focus();

            (document.getElementById("TE_active") as HTMLInputElement).checked = true;

            const item = props.item;
            if(item) {
                logger.log("props.item", item);

                TE_title.value = item.name;
                if(item.accessLevel === 12) {
                    TE_auth_all.checked = true;
                } else if(item.accessLevel === 1) {
                    const TE_auth_T = (document.getElementById("TE_auth_T") as HTMLInputElement);
                    TE_auth_T.checked = true;
                } else {
                    const TE_auth_S = (document.getElementById("TE_auth_S") as HTMLInputElement);
                    TE_auth_S.checked = true;
                }

                const curriBook = curriInfo?.curriBook.find((_item) => _item.id === item.curriBook);
                if(curriBook) {
                    const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
                    TE_curriBook.value = curriBook.val;
                }
                
                const TE_textbookCategory = document.getElementById("TE_textbookCategory") as HTMLSelectElement;
                TE_textbookCategory.value = item.language;
            
                (document.getElementById("TE_active") as HTMLInputElement).checked = item.is_active;
                (document.getElementById("TE_non_active") as HTMLInputElement).checked = !item.is_active;
            }

            // (document.getElementById("TE_pdf") as HTMLInputElement).checked = true;
            // getTextBookCategory();
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            // logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(selectedTextbookList) {
            if(props.item) {
                logger.log("selectedTextbookList", selectedTextbookList, props.item!.web_textbook_id);

                const textbook = (selectedTextbookList as ITextBook[]).find((_item) => _item.id === props.item!.web_textbook_id)
                if(textbook) {
                    const TE_TextBook = document.getElementById("TE_TextBook") as HTMLSelectElement;
                    TE_TextBook.value = textbook.name;    
                }
            }            
        }
    }, [selectedTextbookList])
    
    // async function getTextBookCategory() {

    //     const list = curriInfo?.textbookCategory;
    //     // const list = await util.getMetaList("1", "textbookCategory");
    //     if(list) {
    //         const _list = list.sort((a, b) => a.id! < b.id! ? -1 : 1);	
    //         setTextbookCategory(_list);    
    //     }
    // }

    async function getTextbookList() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            type: "web",
         
            limit_page: 1,
            limit_pageItemCnt: 10000,

            // curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,
        };

        props.showLoading(true);

        const list = await util.getTextbookList(data);
        setAllTextbookList(list);

        if(curriInfo?.curriBook && curriInfo?.curriBook.length > 0) {

            if(props.item) {
                const curriBook = curriInfo?.curriBook.find((_item) => _item.id === props.item?.curriBook);
                const _list = list.filter((item) => item.curriBook === curriBook?.id);
                const _sortedlist = _list.sort((a, b) => a.id! > b.id! ? 1 : -1);	
    
                setSelectedTextbookList(_sortedlist);

                logger.log("getTextbookList", data, curriBook, _sortedlist);

            } else {
                const curriBook = curriInfo?.curriBook[0];
                const _list = list.filter((item) => item.curriBook === curriBook.id);
                const _sortedlist = _list.sort((a, b) => a.id! > b.id! ? 1 : -1);	
    
                setSelectedTextbookList(_sortedlist);
            }           
        }
        
        props.showLoading(false);
    }

    async function onNext() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "교과서 제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        // const TE_lang = document.getElementById("TE_lang") as HTMLSelectElement;
        // const TE_childBook = document.getElementById("TE_childBook") as HTMLSelectElement;

        // const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
        // const TE_curriYear_val = curriInfo.curriYear[TE_curriYear.selectedIndex];
        const TE_curriBook = document.getElementById("TE_curriBook") as HTMLSelectElement;
        const TE_curriBook_val = curriInfo.curriBook[TE_curriBook.selectedIndex];
        const TE_textbookCategory = document.getElementById("TE_textbookCategory") as HTMLSelectElement;
        const TE_textbookCategory_val = TE_textbookCategory.value;
        
        const TE_active = (document.getElementById("TE_active") as HTMLInputElement);
        // const TE_pdf = (document.getElementById("TE_pdf") as HTMLInputElement);

        const TE_auth_all = (document.getElementById("TE_auth_all") as HTMLInputElement);
        const TE_auth_T = (document.getElementById("TE_auth_T") as HTMLInputElement);
        // const TE_auth_S = (document.getElementById("TE_auth_S") as HTMLInputElement);

        const TE_TextBook = (document.getElementById("TE_TextBook") as HTMLSelectElement);
        const TE_TextBook_val = selectedTextbookList[TE_TextBook.selectedIndex] as ITextBook;

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const data = {
            type: "ebook",
            brand_id: brand.id,
            id: props.item ? props.item.id : undefined,
            name: TE_title.value,

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            // subject: subject,
            // language: TE_lang.value,
            is_active: TE_active.checked,
            // supportPdf: TE_pdf.checked,
            accessLevel: TE_auth_all.checked ? 12 : TE_auth_T.checked ? 1 : 2,
            // child: Number(TE_childBook.value),
            language: TE_textbookCategory_val,
            // curriYear: Number(TE_curriYear_val.id),
            curriBook: Number(TE_curriBook_val.id),

            web_textbook_id: TE_TextBook_val.id,
        };

        props.showLoading(true);

        const result = await util.textbookForSave(data, props.item ? "update" : "insert");
        // if(result) {
        //     setBasicData(result);     
        //     setIsNext(true);    
        // }
        logger.log("onNext", data, result);   

        // logger.log("onNext", data);   

        // alert("새창으로 띄울 경로가 필요합니다.");

        if(result) {
            const item = result as ITextBook;

            const textbook_id = props.item ? props.item.id : item.id;
            const web_textbook_id = props.item ? props.item.web_textbook_id : item.web_textbook_id;

            let url = "/ebookeditor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&textbook_id="+textbook_id
            +"&web_textbook_id="+(web_textbook_id ? web_textbook_id : "")
            +"&type=ebook"
            +"&token="+util.getUserInfo("token");
        
            window.open(url, "_blank", "noopener, noreferrer");                                                            
        }
        props.showLoading(false);

        props.onClose();
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        const curriBook = curriInfo?.curriBook[e.target.selectedIndex];
        if(curriBook) {
            const _list = (allTextbookList as ITextBook[]).filter((item) => item.curriBook === curriBook.id);
            const _sortedlist = _list.sort((a, b) => a.id! > b.id! ? 1 : -1);	
            setSelectedTextbookList(_sortedlist);    
            logger.log("onChangeCurriBook", allTextbookList, curriBook, _list);

        } else {
            setSelectedTextbookList([]);
        }

    }

    return (
        <div>

            {!isNext && <>
            <div className="top-title">
                <h3>e book 교과서 등록</h3>
            </div>

            <h4>교과서 설정</h4>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">e book 교과서 등록</caption>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56">교과서 버전</th>
                        <td className="text-left" colSpan={2}>{version} <span className="text-red ml-5">{lastUpdateDate} 최종 수정</span></td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">교과서 제목</label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_title" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-process">교과과정</label></th>
                        <td className="text-left" colSpan={2}>
                            {/* <select id="TE_curriYear" name="search-3">
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select> */}
                            <select className="" id="TE_curriBook" name="search-3" onChange={(e) => onChangeCurriBook(e)}>
                                {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    {/* <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학교급</label></th>
                        <td className="text-left">
                            <select id="TE_curriSchool" name="search-4">
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <th scope="row" className="w-56">과목</th>
                        <td className="text-left">{subject}</td>
                    </tr> */}
                    {/* <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">하위교과서</label></th>
                        <td className="text-left flex items-center text-xs">
                            <select id="TE_childBook" name="search-5">
                                <option value={0}>없슴</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            <p className="text-center ml-5">* 해당 교육과정/학교급에 따른 교과서를 다중으로 등록할 경우 선택 해 주세요</p>
                        </td>
                    </tr> */}
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">교과서 유형</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_textbookCategory" name="sel-type">
                                {/* <option value="교사용 지도서">교사용 지도서</option>
                                <option value="ebook 교과서">ebook 교과서</option> */}
                                <option value="교과서">교과서</option>
                                <option value="부설 교과서">부설 교과서</option>

                                {/* {textbookCategory && textbookCategory.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })} */}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">사용설정</th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_active" name="rd-active" type="radio" />
                                    <label htmlFor="rd-active">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_non_active" name="rd-active" type="radio" />
                                    <label htmlFor="rd-private">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-lang">웹교과서 선택</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_TextBook">
                                {selectedTextbookList && (selectedTextbookList as ITextBook[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.name}>{item.name}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">접근권한</th>
                        <td className="text-left" colSpan={2}>
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_auth_all" name="rd-access" type="radio" />
                                    <label htmlFor="rd-access1">전체</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_auth_T" name="rd-access" type="radio" />
                                    <label htmlFor="rd-access2">교수자</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_auth_S" name="rd-access" type="radio" />
                                    <label htmlFor="rd-access3">학생</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                    {/* <tr>
                        <th scope="row" className="w-56">PDF교과서 형식 지원</th>
                        <td className="text-left w-56">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="TE_pdf" name="rd-pdf" type="radio" />
                                    <label htmlFor="rd-pdf1">사용가능</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="TE_non_pdf" name="rd-pdf" type="radio" />
                                    <label htmlFor="rd-pdf2">사용불가</label>
                                </span>
                            </div>
                        </td>
                        <td className="text-left w-avail" style={{borderLeftWidth:0}}>
                            <span className="mr-2">URL</span>
                            <input className="w-[600px]" type="text" id="pdfUrl" placeholder="연계되는 PDF교과서의 링크를 입력 해 주세요." />
                        </td>
                    </tr> */}
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
            
            {/* <div className="mt-5 text-right">
                <button type="button" className="btn-sky">다음</button>
            </div> */}

            <div className="flex justify-between mt-5">
                <button type="button" className="btn-white" onClick={props.onClose}>취소</button>
                <button type="button" className="btn-sky" onClick={onNext}>{props.item ? "수정" : "다음"}</button>
            </div></>}

            {/* {isNext && <EBookEdit_1 brand={props.brand} menu={props.menu} originTextBook={basicData} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                props.onClose();
            }} />} */}

        </div>

    );  
}
export default EBookEdit;
