import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';

interface IMetaServiceMapEdit {
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaServiceMapEdit(props: IMetaServiceMapEdit) {

    const [UUID, setUUID] = useState("");
    const [studyMap, setStudyMap] = useState<IMeta[]>([]);
    const [depthList, setDepthList] = useState<number[]>([]);
    const [parentStudyMap, setParentStudyMap] = useState(null as any);
    const [studyMapsList, setStudyMapsList] = useState<IMeta[]>([]);

    const [studyMap1List, setStudyMap1List] = useState<IMeta[]>([]);
    const [studyMap2List, setStudyMap2List] = useState<IMeta[]>([]);
    const [studyMap3List, setStudyMap3List] = useState<IMeta[]>([]);
    const [studyMap4List, setStudyMap4List] = useState<IMeta[]>([]);
    const [studyMap5List, setStudyMap5List] = useState<IMeta[]>([]);
    const [studyMap6List, setStudyMap6List] = useState<IMeta[]>([]);

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    const MetaValList = ["홈(대시보드) > 영역별/단원별/개념별/학습맵", "홈(대시보드) > 스스로학습", "수업자료실", "수업/과제/평가만들기 > AI 출제", "스스로 학습", "교사/학생 > 대시보드 > 목표설정"];

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }

        if(!bDone) {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);

            logger.log("props", props);

            getStudyMap();

            const item = props.item;
            if(item) {
                if(item.uuid && item.uuid.length > 10) {
                    setUUID(item.uuid);
                }

                const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
                if(meta_val) {
                    meta_val.value = item.val;
                }
                const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                if(meta_description) {
                    meta_description.value = item.description ? item.description : "";
                }
                const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                if(meta_code) {
                    meta_code.value = item.code;
                }
                meta_code.readOnly = true;
                meta_code.disabled = true;

            } else {
            }

            const active = (document.getElementById('active') as HTMLInputElement);
            if(props.item) {
                active.checked = props.item.is_active;
            } else {
                active.checked = true;
            }
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(studyMap && studyMap.length > 0) {
            if(props.parentItem && props.parentItem.val1 && props.parentItem.val1?.length > 0) {
                const _studyMap = studyMap.find((meta) => meta.val === props.parentItem?.val1);
                logger.log("_studyMap", _studyMap);

                if(_studyMap) {
                    setParentStudyMap(_studyMap);
                    getMaxDepth(_studyMap);
                }
            }
        }
    }, [studyMap])

    async function getMetaOfMetaExById(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _studyMap = list.find((meta, _) => meta.name === "studyMap");
        if(_studyMap) {
        }    
        // const _studyMap1 = list.find((meta, _) => meta.name === "studyMap1");
        // if(_studyMap) {
        // }    
        // const _studyMap = list.find((meta, _) => meta.name === "studyMap");
        // if(_studyMap) {
        // }    
        // const _studyMap = list.find((meta, _) => meta.name === "studyMap");
        // if(_studyMap) {
        // }    
        // const _studyMap = list.find((meta, _) => meta.name === "studyMap");
        // if(_studyMap) {
        // }    
        // const _studyMap = list.find((meta, _) => meta.name === "studyMap");
        // if(_studyMap) {
        // }    
    }

    async function getStudyMap() {

        props.showLoading(true);

        const listAll = await util.getMetaList("1", "studyMap");
        const _sortedStudyMap = listAll.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const _studyMap = _sortedStudyMap.filter((item) => item.is_active === true);

        logger.log("getStudyMap", _studyMap);
        setStudyMap(_studyMap);
        
        props.showLoading(false);
    }

    async function getMaxDepth(meta: IMeta) {
        const list = await getDescendantsList(meta);
        const _sortedStudyMap = list.sort((a, b) => a.depth! < b.depth! ? 1 : -1);	

        // logger.log("getMaxDepth", _sortedStudyMap);  

        if(_sortedStudyMap && _sortedStudyMap.length > 0) {
            const dlist = [];
            for (let i = 0; i < _sortedStudyMap[0].depth; i++) {
                dlist.push(i+1);
            }
            setDepthList(dlist);
        }

        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        if(props.item && props.item.depth === 3) {
            // getMetaOfMetaExById(String(props.item.id));
            const arr = props.item.val2?.split("#^|");

            if(arr) {
                if(arr.length > 0) {
                    setStudyMap2List(_list.filter((item) => item.parent_id === Number(arr[0])));                        
                    setTimeout(() => {
                        _studyMap1.value = arr[0];
                    }, 10);
                }

                if(arr.length > 1) {
                    setStudyMap3List(_list.filter((item) => item.parent_id === Number(arr[1])));                                                                        
                    setTimeout(() => {
                        _studyMap2.value = arr[1];
                    }, 10);
                }

                if(arr.length > 2) {
                    setStudyMap4List(_list.filter((item) => item.parent_id === Number(arr[2])));                                            
                    setTimeout(() => {
                        _studyMap3.value = arr[2];
                    }, 10);
                }

                if(arr.length > 3) {
                    setStudyMap5List(_list.filter((item) => item.parent_id === Number(arr[3])));                                            
                    setTimeout(() => {
                        _studyMap4.value = arr[3];
                    }, 10);
                }

                if(arr.length > 4) {
                    setTimeout(() => {
                        _studyMap5.value = arr[4];
                    }, 10);
                }
            }    
        }
    }

    async function getDescendantsList(meta: IMeta) {

        props.showLoading(true);

        const list = await util.getDescendantsList(meta.code+"-");
        const _sortedStudyMap = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        setStudyMapsList(_sortedStudyMap);

        const _filtered_1 = list.filter((item) => item.name === "studyMap1");
        setStudyMap1List(_filtered_1);

        props.showLoading(false);

        return _sortedStudyMap;
    }

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    function onSave(): void {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        let val = "";
        if(props.parentItem?.depth === 1) {
            for (let i = 0; i < MetaValList.length; i++) {
                const metaVal_ = document.getElementById("metaVal_"+i) as HTMLInputElement;
                if(metaVal_ && metaVal_.checked) {
                    const metaVal = MetaValList[i];
                    if(val === "") {
                        val = metaVal
                    } else {
                        val = val + "#^|" + metaVal;
                    }
                }                    
            }
        } else if(props.parentItem?.depth === 2) {
            const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
            if(meta_val) {
                val = meta_val.value;
            }
        }

        if(val === "") {
            props.showModal("", props.parentItem?.depth === 1 ? "메타값을 선택해 주세요." : "메타값을 입력해 주세요.");
            return;
        }
    
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked
        const maxDepth = document.getElementById('maxDepth') as HTMLSelectElement;

        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        const parentItem = props.parentItem;
        logger.log('parentItem', parentItem);

        let _code = code;
        let _parent_id = 0;
        let _depth = 1;
        if(parentItem) {
            _code = props.item ? code : parentItem.code + "-" + code;
            _parent_id = parentItem.id!;
            _depth = parentItem.depth + 1;
        }

        let meta_mapping_meta : IMeta[] = [];

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const meta = {
            id: props.item ? props.item.id : undefined,
            brand_id: brand.id ? brand.id : null,

            parent_id: _parent_id,
            code: _code,
            name: "serviceMap",
            val: val,
            is_active: is_active ? true : false,
            description: description,
            depth: _depth,

            name1: "",
            val1: "",

            name2: "",
            val2: "",

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            updater: props.item ? uid : undefined,
            updater_id: props.item ? _id : undefined,
            updater_name: props.item ? uname : undefined,
            
            uuid: props.item ? props.item.uuid : UUID,

        }

        meta.name1 = "studyMap";
        meta.val1 = parentStudyMap ? parentStudyMap.val : "";

        if(props.parentItem?.depth === 1) {
            meta.name2 = "maxDepth";
            meta.val2 = maxDepth.value;
        } else if(props.parentItem?.depth === 2) {

            let studyMapsVal = "";

            meta_mapping_meta.push(parentStudyMap as IMeta);
            
            if(_studyMap1 && _studyMap1.style.display === "") {
                if(_studyMap1.selectedIndex > 0) {
                    const _studyMap1Val = studyMapsList.find((meta) => meta.id === Number(_studyMap1.value));                    
                    if(_studyMap1Val) {
                        studyMapsVal = _studyMap1Val.id + "";
                        meta_mapping_meta.push(_studyMap1Val);
                    }
                } else {
                    props.showModal("", "Depth 지정이 완료되지 않았습니다.");
                    return;        
                }
            }            
            if(_studyMap2 && _studyMap2.style.display === "") {
                if(_studyMap2.selectedIndex > 0) {
                    const _studyMap2Val = studyMapsList.find((meta) => meta.id === Number(_studyMap2.value));                    
                    if(_studyMap2Val) {
                        studyMapsVal = studyMapsVal + "#^|" + _studyMap2Val.id;
                        meta_mapping_meta.push(_studyMap2Val);
                    }
                } else {
                    props.showModal("", "Depth 지정이 완료되지 않았습니다.");
                    return;        
                }
            }            
            if(_studyMap3 && _studyMap3.style.display === "") {
                if(_studyMap3.selectedIndex > 0) {
                    const _studyMap3Val = studyMapsList.find((meta) => meta.id === Number(_studyMap3.value));                    
                    if(_studyMap3Val) {
                        studyMapsVal = studyMapsVal + "#^|" + _studyMap3Val.id;
                        meta_mapping_meta.push(_studyMap3Val);
                    }
                } else {
                    props.showModal("", "Depth 지정이 완료되지 않았습니다.");
                    return;        
                }
            }            
            if(_studyMap4 && _studyMap4.style.display === "") {
                if(_studyMap4.selectedIndex > 0) {
                    const _studyMap4Val = studyMapsList.find((meta) => meta.id === Number(_studyMap4.value));                    
                    if(_studyMap4Val) {
                        studyMapsVal = studyMapsVal + "#^|" + _studyMap4Val.id;
                        meta_mapping_meta.push(_studyMap4Val);
                    }
                } else {
                    props.showModal("", "Depth 지정이 완료되지 않았습니다.");
                    return;        
                }
            }            
            if(_studyMap5 && _studyMap5.style.display === "") {
                if(_studyMap5.selectedIndex > 0) {
                    const _studyMap5Val = studyMapsList.find((meta) => meta.id === Number(_studyMap5.value));                    
                    if(_studyMap5Val) {
                        studyMapsVal = studyMapsVal + "#^|" + _studyMap5Val.id;
                        meta_mapping_meta.push(_studyMap5Val);
                    }
                } else {
                    props.showModal("", "Depth 지정이 완료되지 않았습니다.");
                    return;        
                }
            }  
            
            meta.name2 = "studyMaps";
            meta.val2 = studyMapsVal;
        }
        
        const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": meta_mapping_meta}
        logger.log("meta_mapping_meta", metamap);
    
        if(props.item) {
            updateMetaMetaMap(metamap);  
        } else {
            insertMetaMetaMap(metamap);  
        }
    }
    
    const _studyMap1 = document.getElementById("_studyMap1") as HTMLSelectElement;
    const _studyMap2 = document.getElementById("_studyMap2") as HTMLSelectElement;
    const _studyMap3 = document.getElementById("_studyMap3") as HTMLSelectElement;
    const _studyMap4 = document.getElementById("_studyMap4") as HTMLSelectElement;
    const _studyMap5 = document.getElementById("_studyMap5") as HTMLSelectElement;

    async function onChangeStudyMap1(val: String) {
        // const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
        // logger.log("onChangeStudyMap1", _filtered_2);

        const result = studyMapsList.filter((item) => item.parent_id === Number(val))
        setStudyMap2List(result);
        logger.log("onChangeStudyMap1", val, result);

        _studyMap2.selectedIndex = 0;

        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }
    async function onChangeStudyMap2(val: String) {
        // const _filtered_3 = (studyMapsList as IMeta[]).filter((item) => item.depth === 4);
        // logger.log("onChangeStudyMap2", _filtered_3);

        const result = studyMapsList.filter((item) => item.parent_id === Number(val))
        setStudyMap3List(result);
        logger.log("onChangeStudyMap2", val, result);

        _studyMap3.selectedIndex = 0;

        setStudyMap4List([]);
        setStudyMap5List([]);
    }
    async function onChangeStudyMap3(val: String) {
        // const _filtered_4 = (studyMapsList as IMeta[]).filter((item) => item.depth === 5);
        // logger.log("onChangeStudyMap3", _filtered_4);

        const result = studyMapsList.filter((item) => item.parent_id === Number(val))
        setStudyMap4List(result);
        logger.log("onChangeStudyMap3", val, result);

        _studyMap4.selectedIndex = 0;

        setStudyMap5List([]);
    }
    async function onChangeStudyMap4(val: String) {
        // const _filtered_5 = (studyMapsList as IMeta[]).filter((item) => item.depth === 6);
        // logger.log("onChangeStudyMap4", _filtered_5);

        const result = studyMapsList.filter((item) => item.parent_id === Number(val))
        setStudyMap5List(result);
        logger.log("onChangeStudyMap4", val, result);

        _studyMap5.selectedIndex = 0;
    }

    const hasAuth = util.getHasAuthMenu(props.item ? props.item.creator : "");

    return (
        <div>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">관리테이블</caption>
                <tbody>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">

                            <div style={{display: (props.parentItem && props.parentItem.depth === 1) ? "" : "none"}}>
                            {MetaValList.map((item, idx) => {
                                if(props.item && props.item.depth === 2) {
                                    const metaVal_ = document.getElementById("metaVal_"+idx) as HTMLInputElement;
                                    if(metaVal_) {
                                        const pItemVal = props.item.val + "#^|";
                                        if(pItemVal.includes(item+"#^|")) {
                                            metaVal_.checked = true;
                                        } else {
                                            metaVal_.checked = false;
                                        }    
                                    }
                                }
                                return(
                                    <div key={idx} className="flex items-center">
                                        <input className="ml-2" type="checkbox" id={"metaVal_"+idx} />
                                        <span className="ml-1">{item}</span>
                                    </div>
                                );
                            })}
                            </div>

                            <input type="text" id="meta_val" className="w-avail" style={{display: (props.parentItem && props.parentItem.depth === 2) ? "" : "none"}}/>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                        <td className="text-left">
                            <input type="text" id="meta_description" className="w-avail"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_code" className="w-1/2" />
                        </td>
                    </tr>
                    <tr style={{display: (props.parentItem && props.parentItem.depth === 1) ? "" : "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">Depth 설정 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="maxDepth">
                                {depthList.map((item, idx) => {

                                    if(props.item && props.item.depth === 2) {
                                        const maxDepth = document.getElementById("maxDepth") as HTMLSelectElement;
                                        if(maxDepth) {
                                            maxDepth.value = props.item.val2 ? String(props.item.val2) : "1";
                                        }
                                    }

                                    return(
                                        <option key={idx} value={item}>{item}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr style={{display: (props.parentItem && props.parentItem.depth === 2) ? "" : "none"}}>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">Depth 지정 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">

                            <select className="mr-2" id="_studyMap1" name="studyMap1" onChange={(e) => onChangeStudyMap1(e.target.value)} 
                                style={{display: (props.parentItem?.val2 ? Number(props.parentItem.val2) : 0) > 1 ? "" : "none"}}>
                                <option value="">선택</option>              
                                {studyMap1List && (studyMap1List as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="_studyMap2" name="studyMap2" onChange={(e) => onChangeStudyMap2(e.target.value)}
                                style={{display: (props.parentItem?.val2 ? Number(props.parentItem.val2) : 0) > 2 ? "" : "none"}}>
                                <option value="">선택</option>              
                                {studyMap2List && (studyMap2List as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="_studyMap3" name="studyMap3" onChange={(e) => onChangeStudyMap3(e.target.value)}
                                style={{display: (props.parentItem?.val2 ? Number(props.parentItem.val2) : 0) > 3 ? "" : "none"}}>
                                <option value="">선택</option>              
                                {studyMap3List && (studyMap3List as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="_studyMap4" name="studyMap4" onChange={(e) => onChangeStudyMap4(e.target.value)}
                                style={{display: (props.parentItem?.val2 ? Number(props.parentItem.val2) : 0) > 4 ? "" : "none"}}>
                                <option value="">선택</option>              
                                {studyMap4List && (studyMap4List as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="_studyMap5" name="studyMap5"
                                style={{display: (props.parentItem?.val2 ? Number(props.parentItem.val2) : 0) > 5 ? "" : "none"}}>
                                <option value="">선택</option>              
                                {studyMap5List && (studyMap5List as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>

                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">고유코드</th>
                        <td className="text-left">{UUID}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록자</th>
                        {props.item &&
                        <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                        {!props.item &&
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록일</th>
                        {props.item &&
                        <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                        {!props.item &&
                        <td className="text-left">{util.getDate()}</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
                    
            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                    {!props.item &&
                    <button type="button" className="btn-sky" onClick={onSave}>{"등록"}</button>}
                    {props.item &&
                    <button type="button" className="btn-sky" onClick={onSave} disabled={!hasAuth}>{"수정"}</button>}
                </div>
            </div>

        </div>
    );
}

export default MetaServiceMapEdit;
