import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ISet, ITextBook, ITextBookEvaluation, ITextBookIndex, ITextBookTab, IUser } from "../common";
import { logger } from "../logger";
import { ITextBookIndexCurriculum } from "./TextBookEdit_1";
import { log } from "console";
import PopupSetList from "./PopupSetList";

interface IPopupPnE {
    textbook: ITextBook;
    selectedEditItem?: ITextBookEvaluation;
    onClose: (result: any) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupPnE(props: IPopupPnE) {

    const [setId, setSetId] = useState(null as any);
    const [setName, setSetName] = useState(null as any);
    const [viewPopupSetList, setViewPopupSetList] = useState(false);

    const [textbookSetIds, setTextbookSetIds] = useState<string[]>([]);

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            logger.log("props", props);
    
            getEvaluationList();

            const selectedEditItem = props.selectedEditItem;
            if(selectedEditItem) {
        
                const name = (document.getElementById('name') as HTMLInputElement);
                name.value = selectedEditItem.pne_matrialNm ? selectedEditItem.pne_matrialNm : "";

                const setName = (document.getElementById('setName') as HTMLInputElement);
                setName.value = selectedEditItem.pne_set_name ? selectedEditItem.pne_set_name : "";

                const timTime = selectedEditItem.pne_timTime;
                if(timTime) {
                    const arr = timTime.split(":");
                    if(arr && arr.length === 3) {
                        const hour = (document.getElementById('hour') as HTMLInputElement);
                        hour.value = arr[0];
                        const min = (document.getElementById('min') as HTMLInputElement);
                        min.value = arr[1];
                        const sec = (document.getElementById('sec') as HTMLInputElement);
                        sec.value = arr[2];    
                    }    
                } else {
                    const min = (document.getElementById('min') as HTMLInputElement);
                    min.value = "60";    
                }
        
                setSetId(selectedEditItem.pne_set_id);
                setSetName(selectedEditItem.pne_set_name);

            } else {

                // const hour = (document.getElementById('hour') as HTMLInputElement);
                // hour.value = "00";
                const min = (document.getElementById('min') as HTMLInputElement);
                min.value = "60";
                // const sec = (document.getElementById('sec') as HTMLInputElement);
                // sec.value = "00";    
            }
        }
        bDone = true;
    }, [])

    async function getEvaluationList() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,

            "textbook_id": props.textbook.id, 
            "is_active": true,
    
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10000,
        };

        props.showLoading(true);

        const _textbookSetIds : string[] = [];
        const list = await util.getTextbookEvaluationSearch(data);
        for (let i = 0; i < list.length; i++) {
            const tb = list[i];
            _textbookSetIds.push(String(tb.set_id));
        }
        setTextbookSetIds(_textbookSetIds);
        logger.log("onSearch", data, list, _textbookSetIds);

        props.showLoading(false);
    }

    function onClose() {
        props.onClose(null);
    }

    async function onSave() {

        const brand = util.getCurrentBrand();
        if(!brand || ! brand.id) {
            return;
        }

        const name = (document.getElementById('name') as HTMLInputElement).value;
        if(name === "") {
            props.showModal("", "노출될 처방평가의 이름을 입력해주세요.");
            (document.getElementById('name') as HTMLInputElement).focus();
            return;    
        }
 
        if(!setId) {
            props.showModal("", "세트지를 선택해 주세요.");
            return;    
        }

        let timTime = "";
        const hour = (document.getElementById('hour') as HTMLInputElement);
        const nHour = hour.value === "" ? 0 : Number(hour.value);
        const min = (document.getElementById('min') as HTMLInputElement);
        const nMin = min.value === "" ? 0 : Number(min.value);
        const sec = (document.getElementById('sec') as HTMLInputElement);
        const nSec = sec.value === "" ? 0 : Number(sec.value);

        timTime = (nHour < 10 ? "0"+nHour : ""+nHour) + ":";
        timTime = timTime + (nMin < 10 ? "0"+nMin : ""+nMin) + ":";
        timTime = timTime + (nSec < 10 ? "0"+nSec : ""+nSec);

        const uid = util.getUserInfo("uid");

        const data = {
            id: props.selectedEditItem ? props.selectedEditItem.id : undefined,
            brand_id: brand.id,

            textbook_id: props.textbook.id,
            pne_matrialNm: name,
         
            pne_set_id: setId ? setId : undefined,
            pne_set_name: setName ? setName : undefined,
            pne_timTime: timTime,
        
            is_publicOpen: props.selectedEditItem ? undefined : true,             
            is_active: props.selectedEditItem ? undefined : true,    

            creator: props.selectedEditItem ? undefined : uid,
            updater: props.selectedEditItem ? uid : undefined,
        }

        props.showLoading(true);
        const result = await util.textbookEvaluationForSave(data, props.selectedEditItem ? "update" : "insert");
        logger.log("onSave", data, result);
        props.showLoading(false);

        props.onClose(result);
    }

    function onSearchSet() {
        setViewPopupSetList(true);
    }

    async function onRequstedSet(item: ISet) {
        logger.log("onRequstedSet", item);
        setSetId(item.id);
        const name = item.name+"("+item.id+")"
        setSetName(name);

        const setName = (document.getElementById('setName') as HTMLInputElement);
        setName.value = name;
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">

                <div className="popup-contents-inner">
                    {/* <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&ZeroWidthSpace;</span> */}
                    <div className="popup-box max-w-3xl">
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                            <h2 className="pop-title">처방평가 등록</h2>
                            <div className="px-5">	
                                <h3 className="relative text-lg mt-6 mb-3 pl-1-25 text-slate-900 after:block after:absolute after:left-0 after:top-2.5 after:w-2 after:h-2 after:rounded after:bg-slate-500">기본 설정</h3>
                                <div className="flex items-center bg-gray-50 border border-gray-300 py-3 px-5 rounded-md mb-2">
                                    <strong className="inline-block text-sm font-semibold text-gray-900 w-36">구분</strong>
                                    <div className="text-center w-avail">
                                        <label>처방평가</label>
                                    </div>
                                </div>
                                <div className="flex items-center bg-gray-50 border border-gray-300 py-3 px-5 rounded-md mb-2">
                                    <strong className="inline-block text-sm font-semibold text-gray-900 w-36">{"평가명"}</strong>
                                    <div className="flex-1">
                                        <input type="text" id="name" className="w-full" placeholder={"노출될 평가의 이름을 입력하세요"} onChange={(e) => {
                                            const val = e.currentTarget.value;
                                            if(val && val.length > 500) {
                                                props.showModal("", "평가명 (최대 500byte)입력 가능");
                                                e.currentTarget.value = val.substring(0, 500);
                                            }
                                        }} />
                                    </div>
                                </div>

                                <div className="flex items-center bg-gray-50 border border-gray-300 py-3 px-5 rounded-md">
                                    <strong className="inline-block text-sm font-semibold text-gray-900 w-36">세트지 불러오기</strong>
                                    <div className="flex-1 pr-3 text-center w-avail">
                                        <input className="w-avail" id="setName" type="text" readOnly />
                                    </div>
                                    <button type="button" className="btn-white" onClick={onSearchSet}>불러오기</button>
                                </div>
                                
                                <h3 className="relative text-lg mt-6 mb-3 pl-1-25 text-slate-900 after:block after:absolute after:left-0 after:top-2.5 after:w-2 after:h-2 after:rounded after:bg-slate-500">상세 설정</h3>

                                <div className="bg-gray-50 border border-gray-300 py-3 px-5 rounded-md">
                                    <div className="flex pb-4 border-b border-gray-300">
                                        <svg className="h-8 w-8 text-slate-500 m-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z"></path>  <circle cx="12" cy="12" r="9"></circle>  <polyline points="12 7 12 12 15 15"></polyline>
                                        </svg>
                                        <div className="ml-1">
                                            <strong className="inline-block text-sm font-semibold text-gray-900">타이머 설정</strong>
                                            <p className="text-sm leading-5">
                                                응시 시간을 분/초 단위로 제한할 수 있습니다<br/>
                                                <span className="font-bold bg-yellow-100">타이머가 종료되면 응시 중인 학생들의 과제가 자동 제출됩니다.</span>
                                            </p>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="flex items-center flex-1 px-3 mt-3 ml-5">
                                        <input type="text" id="hour" className="w-12 h-10" placeholder="00" />
                                        <span className="ml-1 mr-4">시</span>
                                        <input type="text" id="min" className="w-12 h-10" placeholder="00" />
                                        <span className="ml-1 mr-4">분</span>
                                        <input type="text" id="sec" className="w-12 h-10" placeholder="00" />
                                        <span className="ml-1">초</span>
                                    </div>
                                </div>
                            </div>
                            <div className="my-5 text-center">
                                <button type="button" className="btn-sky" onClick={onSave}>저장</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {viewPopupSetList &&
            <PopupSetList showModal={props.showModal} showLoading={props.showLoading} search={"평가"} setIds={textbookSetIds} onClose={() => {
                setViewPopupSetList(false);
            }} onRequst={onRequstedSet} />}

        </div>        
    );
}
  
export default PopupPnE;
