import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBook, ITextBookIndex, ITextBookTab, IUser } from "../common";
import { logger } from "../logger";
import { ITextBookIndexCurriculum } from "./TextBookEdit_1";
import { log } from "console";

interface IPopupAddTab {
    textbookCurriculum: ITextBookIndexCurriculum;
    selectedTab?: ITextBookTab;
    textbook?: ITextBook;
    isDefaultTab?: boolean;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
    onSaved: (item: any) => void;
}

function PopupAddTab(props: IPopupAddTab) {

    const [allEBookList, setAllEBookList] = useState<ITextBook[]>(null as any);
    const [isEduTheme, setIsEduTheme] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();
    // const tabTypeList = ["교과서", "문제지", "활동지", "게임", "개별학습"];

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getEBookList();

            (document.getElementById('access_all') as HTMLInputElement).checked = true;            
            (document.getElementById('tab_name') as HTMLInputElement).focus();

            (document.getElementById('is_ebook_active') as HTMLInputElement).checked = true;            
            (document.getElementById('is_ebook_non_active') as HTMLInputElement).checked = false;            

            setIsEduTheme(false);

            logger.log("props", props);

            const selectedTab = props.selectedTab;
            if(selectedTab) {
                // logger.log("selectedTab", selectedTab);
                if(curriInfo && curriInfo.setCategory) {

                    let _setCategory = selectedTab.setCategory;
                    if(!_setCategory) {
                        _setCategory = 31;
                    }
    
                    for (let i = 0; i < curriInfo.setCategory.length; i++) {
                        const setCategory = curriInfo.setCategory[i];
                        const tab_type_ = document.getElementById("tab_type_"+i) as HTMLInputElement;
                        if(_setCategory === setCategory.id) {
                            tab_type_.checked = true;   
                            if(setCategory.code.indexOf("eduThema") === 0) {
                                setIsEduTheme(true);
                            }                         
                        }    
                    }
                }

                if(props.isDefaultTab) {
                    if(curriInfo && curriInfo.setCategory) {
                        for (let i = 0; i < curriInfo.setCategory.length; i++) {
                            const setCategory = curriInfo.setCategory[i];
                            const tab_type_ = document.getElementById("tab_type_"+i) as HTMLInputElement;
                            tab_type_.disabled = true;
                            if(31 === setCategory.id) {
                                tab_type_.checked = true;   
                            }    
                        }
                    }
                }

                const tab_name = document.getElementById("tab_name") as HTMLInputElement;
                tab_name.value = selectedTab.name;
             
                const access_all = document.getElementById('access_all') as HTMLInputElement;
                const access_T = document.getElementById('access_T') as HTMLInputElement;
                const access_S = document.getElementById('access_S') as HTMLInputElement;

                if(selectedTab.accessLevel === 12) {
                    access_all.checked = true;
                    access_T.checked = false;
                    access_S.checked = false;
                } else if(selectedTab.accessLevel === 1) {
                    access_all.checked = false;
                    access_T.checked = true;
                    access_S.checked = false;
                } else if(selectedTab.accessLevel === 2) {
                    access_all.checked = false;
                    access_T.checked = false;
                    access_S.checked = true;
                }
                
                const tab_license = document.getElementById("tab_license") as HTMLInputElement;
                tab_license.value = selectedTab.source ? selectedTab.source : "비상교육";

                const is_ebook_active = document.getElementById('is_ebook_active') as HTMLInputElement;
                const is_ebook_non_active = document.getElementById('is_ebook_non_active') as HTMLInputElement;

                is_ebook_active.checked = selectedTab.is_ebook_active;
                is_ebook_non_active.checked = !selectedTab.is_ebook_active;

                if(selectedTab.is_ebook_active) {
                    (document.getElementById('ebook') as HTMLSelectElement).disabled = false; 
                    (document.getElementById('startPage') as HTMLSelectElement).disabled = false; 
                    (document.getElementById('endPage') as HTMLSelectElement).disabled = false; 
                } else {
                    (document.getElementById('ebook') as HTMLSelectElement).disabled = true; 
                    (document.getElementById('startPage') as HTMLSelectElement).disabled = true; 
                    (document.getElementById('endPage') as HTMLSelectElement).disabled = true; 
                }

                const startPage = document.getElementById('startPage') as HTMLInputElement;
                startPage.value = String(selectedTab.startPage && selectedTab.startPage > -1 ? selectedTab.startPage : "");
                const endPage = document.getElementById('endPage') as HTMLInputElement;
                endPage.value = String(selectedTab.endPage && selectedTab.endPage > -1 ? selectedTab.endPage : "");   
                
                if(props.textbook) {
                    const is_deployed = props.textbook.deployStatus === 1;
                    if(is_deployed) {
                        if(curriInfo && curriInfo.setCategory) {
                            for (let i = 0; i < curriInfo.setCategory.length; i++) {
                                const tab_type_ = document.getElementById("tab_type_"+i) as HTMLInputElement;
                                tab_type_.disabled = true;
                            }
                        }
                        // const tab_name = document.getElementById("tab_name") as HTMLInputElement;
                        // tab_name.disabled = true;
                    }
                }
                const theme_code = document.getElementById('theme_code') as HTMLInputElement;
                theme_code.value = selectedTab.theme_code ? selectedTab.theme_code : "";
                const theme_path = document.getElementById('theme_path') as HTMLInputElement;
                theme_path.value = selectedTab.theme_path ? selectedTab.theme_path : "";    
                const theme_desc = document.getElementById('theme_desc') as HTMLInputElement;
                theme_desc.value = selectedTab.theme_desc ? selectedTab.theme_desc : "";    
            } else {
                if(curriInfo && curriInfo.setCategory) {
                    for (let i = 0; i < curriInfo.setCategory.length; i++) {
                        const setCategory = curriInfo.setCategory[i];
                        const tab_type_ = document.getElementById("tab_type_"+i) as HTMLInputElement;
                        if(31 === setCategory.id) {
                            tab_type_.checked = true;   
                        }    
                    }
                }
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(allEBookList && allEBookList.length > 0) {
            if(props.selectedTab) {
                const ebook = document.getElementById('ebook') as HTMLSelectElement;
                if(props.selectedTab.ebook_id) {
                    ebook.value = String(props.selectedTab.ebook_id);
                } else {
                    ebook.selectedIndex = 0;
                }                
            }
        }
    }, [allEBookList])

    async function getEBookList() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            type: "ebook",
         
            limit_page: 1,
            limit_pageItemCnt: 10000,
        };

        props.showLoading(true);

        const list = await util.getTextbookList(data);
        const _list = list.sort((a, b) => a.name! > b.name! ? 1 : -1);	
        setAllEBookList(_list);
        
        props.showLoading(false);
    }

    function onClose() {
        props.onClose();
    }

    async function onSave() {

        // const brand = util.getCurrentBrand();
        // if(!brand || ! brand.id) {
        //     return;
        // }

        let setCategory = 0;
        curriInfo?.setCategory.map((item, idx) => {
            if((document.getElementById('tab_type_'+idx) as HTMLInputElement).checked) {
                setCategory = item.id!;
            }
        });

        let accessLevel = 12;
        if((document.getElementById('access_all') as HTMLInputElement).checked) {
            accessLevel = 12;
        } else if((document.getElementById('access_T') as HTMLInputElement).checked) {
            accessLevel = 1;
        } else if((document.getElementById('access_S') as HTMLInputElement).checked) {
            accessLevel = 2;
        }

        const name = (document.getElementById('tab_name') as HTMLInputElement).value;
        const license = (document.getElementById('tab_license') as HTMLInputElement).value;

        if(name === "") {
            props.showModal("", "탭에 표기될 제목을 입력해주세요.");
            (document.getElementById('tab_name') as HTMLInputElement).focus();
            return;    
        }

        const is_ebook_active = (document.getElementById('is_ebook_active') as HTMLInputElement).checked;
        const ebook = document.getElementById('ebook') as HTMLSelectElement;
        const ebook_val = ebook.selectedIndex > 0 ? allEBookList[ebook.selectedIndex-1] : undefined;
        const startPage_val = (document.getElementById('startPage') as HTMLInputElement).value;
        const endPage_val = (document.getElementById('endPage') as HTMLInputElement).value;

        const theme_code = (document.getElementById('theme_code') as HTMLInputElement).value;
        const theme_path = (document.getElementById('theme_path') as HTMLInputElement).value;
        const theme_desc = (document.getElementById('theme_desc') as HTMLInputElement).value;
        
        // const _id = util.getUserInfo("id");
        // const uid = util.getUserInfo("uid");
        // const uname = util.getUserInfo("name");

        const data = {
            id: props.selectedTab ? props.selectedTab.id : undefined,
            // brand_id: brand.id,
            // is_publicOpen: true,             
            // is_active: true,    
            name: name,
            // creator: props.selectedTab ? undefined : uid,
            // creator_id: props.selectedTab ? undefined : _id,
            // creator_name: props.selectedTab ? undefined : uname,
            setCategory: setCategory,
            accessLevel: accessLevel,
            source: license,

            textbookCurriculum_key: props.textbookCurriculum.id,
            textbookIndex_id: props.textbookCurriculum.textbookIndex_id,

            // updater: props.selectedTab ? uid : undefined,
            // updater_id: props.selectedTab ? _id : undefined,
            // updater_name: props.selectedTab ? uname : undefined,

            ebook_id: is_ebook_active ? ebook_val?.id : undefined,
            ebook: is_ebook_active ? ebook_val : undefined,
            startPage: is_ebook_active ? startPage_val : undefined,
            endPage: is_ebook_active ? endPage_val : undefined,
            is_ebook_active: is_ebook_active,

            theme_code: theme_code,
            theme_path: theme_path,
            theme_desc: theme_desc,
            
            isDirty: true,
        }

        // props.showLoading(true);

        // const result = await util.textbookTabForSave(data, props.selectedTab ? "update" : "insert");
        // if(result) {
        //     props.onSaved(props.selectedTab ? data : result);
        // }    

        // props.showLoading(false);

        props.onSaved(data);

        // logger.log("onSave", data);

        props.onClose();
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box max-w-xl min-w-[768px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <h2 className="pop-title">{"하위 자료 설정 (탭 " + (props.selectedTab ? "수정" : "추가") + ")"}</h2>
                            <div>
                                <table>
                                    <caption className="sr-only">하위 자료 설정</caption>
                                    <tbody>
                                        <tr>
                                            <th scope="col">자료 유형</th>
                                            <td className="p-0">
                                                <dl className="flex">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">유형선택</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <fieldset aria-labelledby="radio-group">
                                                            {curriInfo && curriInfo.setCategory && curriInfo.setCategory.map((item, idx) => {                                                                
                                                                let isHidden = item.code !== "textbook" && item.code !== "game" && item.code !== "aiStudy";
                                                                if(isHidden) {
                                                                    const brand = util.getCurrentBrand();
                                                                    if(brand && brand.id === 1 && item.code.indexOf("eduThema") === 0) {
                                                                        isHidden = false;
                                                                    }    
                                                                }
                                                                // logger.log(item.code, isHidden, "eduThema");

                                                                return(
                                                                    <span key={idx} style={{display: isHidden ? "none" : ""}}>
                                                                        <input type="radio" id={"tab_type_"+idx} name="rd-type" onChange={(e) => {
                                                                            if(item.code.indexOf("eduThema") === 0) {
                                                                                setIsEduTheme(true);
                                                                            } else {
                                                                                setIsEduTheme(false);
                                                                            }                                                                        
                                                                        }} />
                                                                        <label className="ml-1" htmlFor="rd-type1">{item.val}</label>
                                                                    </span>      
                                                                )
                                                            })}
                                                        </fieldset>
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">기본설정</th>
                                            <td className="p-0">
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">탭 명</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <input id="tab_name" type="text" className="w-96" placeholder="탭에 표기될 제목을 입력해주세요" />
                                                    </dd>
                                                </dl>
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">공개여부</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <fieldset aria-labelledby="radio-group">
                                                            <input type="radio" id="access_all" name="rd-public" />
                                                            <label className="ml-1" htmlFor="rd-public1">전체</label>
                                                            <input type="radio" id="access_T" name="rd-public" />
                                                            <label className="ml-1" htmlFor="rd-public2">교수자</label>
                                                            <input style={{display: "none"}} type="radio" id="access_S" name="rd-public" />
                                                            <label style={{display: "none"}} className="ml-1" htmlFor="rd-public3">학생</label>
                                                        </fieldset>
                                                    </dd>
                                                </dl>
                                                <dl className="flex">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">저작권</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <input id="tab_license" type="text" className="w-96" defaultValue={"비상교육"} />
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>

                                        <tr style={{display: isEduTheme ? "none" : ""}}>
                                            <th scope="col">ebook 설정</th>
                                            <td className="p-0">
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">ebook 사용여부</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <fieldset aria-labelledby="radio-group">
                                                            <div className="radio-wrap">
                                                                <span className="inp-radio text-sky-500">
                                                                    <input id="is_ebook_active" name="rd-use" type="radio" onChange={(e) => {

                                                                        if(e.target.checked) {
                                                                            (document.getElementById('ebook') as HTMLSelectElement).disabled = false; 
                                                                            (document.getElementById('startPage') as HTMLSelectElement).disabled = false; 
                                                                            (document.getElementById('endPage') as HTMLSelectElement).disabled = false; 
                                                                        } else {
                                                                            (document.getElementById('ebook') as HTMLSelectElement).disabled = true; 
                                                                            (document.getElementById('startPage') as HTMLSelectElement).disabled = true; 
                                                                            (document.getElementById('endPage') as HTMLSelectElement).disabled = true; 
                                                                        }

                                                                    }} />
                                                                    <label htmlFor="rd-on">사용</label>
                                                                </span>
                                                                <span className="inp-radio text-red-600">
                                                                    <input id="is_ebook_non_active" name="rd-use" type="radio" onChange={(e) => {

                                                                        if(e.target.checked) {
                                                                            (document.getElementById('ebook') as HTMLSelectElement).disabled = true; 
                                                                            (document.getElementById('startPage') as HTMLSelectElement).disabled = true; 
                                                                            (document.getElementById('endPage') as HTMLSelectElement).disabled = true; 
                                                                        } else {
                                                                            (document.getElementById('ebook') as HTMLSelectElement).disabled = false; 
                                                                            (document.getElementById('startPage') as HTMLSelectElement).disabled = false; 
                                                                            (document.getElementById('endPage') as HTMLSelectElement).disabled = false; 
                                                                        }

                                                                    }} />
                                                                    <label htmlFor="rd-off">미사용</label>
                                                                </span>
                                                            </div>
                                                        </fieldset>
                                                    </dd>
                                                </dl>
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">ebook 선택</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <select className="" id="ebook" name="search-3" onChange={(e) => {
                                                        }}>
                                                            <option value="">선택</option>
                                                            {allEBookList && (allEBookList as ITextBook[]).map((item, idx) => {
                                                                return(
                                                                    <option key={idx} value={item.id}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </dd>
                                                </dl>
                                                <dl className="flex">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">페이지</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <input id="startPage" type="text" className="w-16" />
                                                        <span className="ml-1">~</span> 
                                                        <input id="endPage" type="text" className="w-16 ml-1" />
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>

                                        <tr style={{display: isEduTheme ? "" : "none"}}>
                                            <th scope="col">테마관 설정</th>
                                            <td className="p-0">                                                
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">테마관 코드</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <input id="theme_code" type="text" className="w-96" />
                                                    </dd>
                                                </dl>
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">경로</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <input id="theme_path" type="text" className="w-96" />
                                                    </dd>
                                                </dl>
                                                <dl className="flex">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">설명</dt>
                                                    <dd className="flex items-center text-left px-3">
                                                        <input id="theme_desc" type="text" className="w-96" />
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>

                                        {/* <tr>
                                            <th scope="col">부가설정</th>
                                            <td className="p-0">
                                                <dl className="flex border-b border-slate-300">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">정답 표기</dt>
                                                    <dd className="flex items-center text-left px-3">

                                                    </dd>
                                                </dl>
                                                <dl className="flex">
                                                    <dt className="flex items-center h-12 px-3 w-28 text-left bg-slate-100 font-semibold text-gray-900">제한시간</dt>
                                                    <dd className="flex items-center text-left px-3">

                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>

                            <div className="my-5 text-center">
                                <button type="button" className="btn-sky mr-3" onClick={onSave}>{"저장"}</button>
                                <button type="button" className="btn-white" onClick={onClose}>{"닫기"}</button>
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupAddTab;
