import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, ILibrary, IMenu, IMeta, ITextBook, ITextbookBasicData, S3Server } from "../common";
import { logger } from '../logger';

interface IVisangBookEdit {
    brand: IBrand;
    menu: IMenu;
    item: ITextBook | null;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
  
function VisangBookEdit(props: IVisangBookEdit) {
    
    const [version, setVersion] = useState(1);
    const [lastUpdateDate, setLastUpdateDate] = useState("");

    const [isNext, setIsNext] = useState(false);

    const [dataCategoryList, setDataCategoryList] = useState(null as any);
    const [dataBrandList, setDataBrandList] = useState(null as any);
    const [dataYearList, setDataYearList] = useState(null as any);
    const [visangbookCategoryList, setVisangbookCategoryList] = useState(null as any);

    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getVisangBookMetaInfo();

            setLastUpdateDate(util.getDate());

            setVersion(1);

            const TE_non_active = (document.getElementById("TE_non_active") as HTMLInputElement);
            if(TE_non_active) {
                TE_non_active.checked = true;
            }

            const TE_title = document.getElementById("TE_title") as HTMLInputElement;
            if(TE_title) {
                TE_title.focus();
            }

            const item = props.item;
            if(item) {
                logger.log("props.item -> ", item);
                TE_title.value = item.name;

                if(item.version && item.version > 1) {
                    setVersion(item.version);
                }

                const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);
                TE_is_active.checked = item.is_active;

                const TE_non_active = (document.getElementById("TE_non_active") as HTMLInputElement);
                TE_non_active.checked = !item.is_active;

                const TE_hasgtag = (document.getElementById("TE_hasgtag") as HTMLInputElement);
                let val = "";
                if(TE_hasgtag && item.hashTag && item.hashTag.length > 0) {
                    for (let i = 0; i < item.hashTag.length; i++) {
                        const tag = item.hashTag[i];     
                        if(val === "") {
                            val = tag;
                        } else {
                            val = val + "," + tag;
                        }  
                    }
                }
                TE_hasgtag.value = val;
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(visangbookCategoryList && dataYearList && dataCategoryList && dataBrandList) {
            if(props.item) {
                getVisangBookInfo(props.item.id!);
            }
        }
    }, [visangbookCategoryList, dataYearList, dataCategoryList, dataBrandList])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            // logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    async function getVisangBookMetaInfo() {

        const names = [
            "dataCategory",
            "dataYear",
            "dataBrand",
            "visangbookCategory",
        ];
        const listAll = await util.getMetaNamesList(names);
        listAll.filter((meta) => meta.name === "dataCategory");

        const dataCategory = listAll.filter((meta) => meta.name === "dataCategory");
        setDataCategoryList(dataCategory);    
        const dataYear = listAll.filter((meta) => meta.name === "dataYear");
        setDataYearList(dataYear);    
        const dataBrand = listAll.filter((meta) => meta.name === "dataBrand");
        setDataBrandList(dataBrand);    
        const visangbookCategory = listAll.filter((meta) => meta.name === "visangbookCategory");
        setVisangbookCategoryList(visangbookCategory);    
    }

    async function getVisangBookInfo(id: number) {
        
        const list = await util.getTextBookInfo(id);
        logger.log("getVisangBookInfo", list);

        if(list) {
            const metaMap = (list as ITextBook).metaMap;

            const curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
            if(curriSchool) {
                const _curriSchool = metaMap.find((meta, _) => meta.name === "curriSchool");
                if(_curriSchool) {
                    curriSchool.value = _curriSchool.val;
                }    
            }
            const curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
            if(curriSubject) {
                const _curriSubject = metaMap.find((meta, _) => meta.name === "curriSubject");
                if(_curriSubject) {
                    curriSubject.value = _curriSubject.val;
                }
            }
            const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
            if(TE_category) {
                const _visangbookCategory = metaMap.find((meta, _) => meta.name === "visangbookCategory");
                if(_visangbookCategory) {
                    TE_category.value = _visangbookCategory.val;
                }
            }                
            const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
            if(TE_curriGrade) {
                const _curriGrade = metaMap.find((meta, _) => meta.name === "curriGrade");
                if(_curriGrade) {
                    TE_curriGrade.value = _curriGrade.val;
                }
            }   
            const TE_curriSemester = document.getElementById("TE_curriSemester") as HTMLSelectElement;
            if(TE_curriSemester) {
                const _curriSemester = metaMap.find((meta, _) => meta.name === "curriSemester");
                if(_curriSemester) {
                    TE_curriSemester.value = _curriSemester.val;
                }
            }   
            const TE_dataYear = document.getElementById("TE_dataYear") as HTMLSelectElement;
            if(TE_dataYear) {
                const _dataYear = metaMap.find((meta, _) => meta.name === "dataYear");
                if(_dataYear) {
                    TE_dataYear.value = _dataYear.val;
                }
            }    
            const TE_dataCategory = document.getElementById("TE_dataCategory") as HTMLSelectElement;
            if(TE_dataCategory) {
                const _dataCategory = metaMap.find((meta, _) => meta.name === "dataCategory");
                if(_dataCategory) {
                    TE_dataCategory.value = _dataCategory.val;
                }
            }    
            const TE_dataBrand = document.getElementById("TE_dataBrand") as HTMLSelectElement;
            if(TE_dataBrand) {
                const _dataBrand = metaMap.find((meta, _) => meta.name === "dataBrand");
                if(_dataBrand) {
                    TE_dataBrand.value = _dataBrand.val;
                }
            }    
        }
    }
    
    async function onNext() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const TE_title = document.getElementById("TE_title") as HTMLInputElement;
        if(TE_title.value === "") {
            props.showModal("", "제목을 입력해 주세요.");
            TE_title.focus();
            return;
        }

        const metaMap : IMeta[] = [];

        let TE_curriSchool_val = undefined;
        const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
        if(TE_curriSchool) {
            if(TE_curriSchool.selectedIndex > 0) {
                TE_curriSchool_val = curriInfo.curriSchool[TE_curriSchool.selectedIndex-1];
                metaMap.push(TE_curriSchool_val);
            }
        }
        let TE_curriSubject_val = undefined;
        const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
        if(TE_curriSubject) {
            if(TE_curriSubject.selectedIndex > 0) {
                TE_curriSubject_val = curriInfo.curriSubject[TE_curriSubject.selectedIndex-1];
                metaMap.push(TE_curriSubject_val);
            }
        }
        let TE_category_val = undefined;
        const TE_category = document.getElementById("TE_Category") as HTMLSelectElement;
        if(TE_category) {
            if(TE_category.selectedIndex > 0) {
                TE_category_val = visangbookCategoryList[TE_category.selectedIndex-1];
                metaMap.push(TE_category_val);
            }
        }
        let TE_curriGrade_val = undefined;
        const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
        if(TE_curriGrade) {
            if(TE_curriGrade.selectedIndex > 0) {
                TE_curriGrade_val = curriInfo.curriGrade[TE_curriGrade.selectedIndex-1];
                metaMap.push(TE_curriGrade_val);
            }
        }
        let TE_curriSemester_val = undefined;
        const TE_curriSemester = document.getElementById("TE_curriSemester") as HTMLSelectElement;
        if(TE_curriSemester) {
            if(TE_curriSemester.selectedIndex > 0) {
                TE_curriSemester_val = curriInfo.curriSemester[TE_curriSemester.selectedIndex-1];
                metaMap.push(TE_curriSemester_val);
            }
        }
        let TE_dataYear_val = undefined;
        const TE_dataYear = document.getElementById("TE_dataYear") as HTMLSelectElement;
        if(TE_dataYear) {
            if(TE_dataYear.selectedIndex > 0) {
                TE_dataYear_val = dataYearList[TE_dataYear.selectedIndex-1];
                metaMap.push(TE_dataYear_val);
            }
        }
        let TE_dataCategory_val = undefined;
        const TE_dataCategory = document.getElementById("TE_dataCategory") as HTMLSelectElement;
        if(TE_dataCategory) {
            if(TE_dataCategory.selectedIndex > 0) {
                TE_dataCategory_val = dataCategoryList[TE_dataCategory.selectedIndex-1];
                metaMap.push(TE_dataCategory_val);
            }
        }
        let TE_dataBrand_val = undefined;
        const TE_dataBrand = document.getElementById("TE_dataBrand") as HTMLSelectElement;
        if(TE_dataBrand) {
            if(TE_dataBrand.selectedIndex > 0) {
                TE_dataBrand_val = dataBrandList[TE_dataBrand.selectedIndex-1];
                metaMap.push(TE_dataBrand_val);
            }
        }

        const TE_is_active = (document.getElementById("TE_is_active") as HTMLInputElement);

        const TE_hasgtag = (document.getElementById("TE_hasgtag") as HTMLInputElement);
        const TE_hasgtag_val = TE_hasgtag.value ? TE_hasgtag.value.split(",") : [];

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const data = {
            type: "visangbook",
            brand_id: brand.id,
            id: props.item ? props.item.id : undefined,
            name: TE_title.value,

            creator: uid,
            creator_id: _id,
            creator_name: uname,

            data_type: TE_category_val ? TE_category_val.val : TE_category_val,

            // curriSubject: TE_Subject_val ? TE_Subject_val.id : TE_Subject_val,

            is_active: TE_is_active.checked,
            
            hashTag: TE_hasgtag_val,

            metaMap: metaMap,
        };

        props.showLoading(true);

        // logger.log("onNext", data);   

        const result = await util.textbookForSave(data, props.item ? "update" : "insert");
        logger.log("onNext", data, result);   

        if(result) {
            if(!props.item) {
                const item = result as ITextBook;
            
                // const textbook_id = props.item ? props.item.id : item.id;
                // const web_textbook_id = props.item ? props.item.web_textbook_id : item.web_textbook_id;
                const textbook_id = item.id;
                const web_textbook_id = item.web_textbook_id;
    
                let url = "/ebookeditor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&textbook_id="+textbook_id
                +"&web_textbook_id="+(web_textbook_id ? web_textbook_id : "")
                +"&type=teaching"
                +"&token="+util.getUserInfo("token");
    
                // const url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                // +"&create=article"+"&token="+util.getUserInfo("token");
    
                window.open(url, "_blank", "noopener, noreferrer");            
            }
        }

        props.showLoading(false);

        props.onClose();
    }

    return (
        <div>

            {!isNext && <>
            <div className="top-title">
                <h3>교재 등록</h3>
            </div>

            <h4>i-book 설정</h4>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">i-book 등록</caption>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56">버전</th>
                        <td className="text-left" colSpan={2}>{version} <span className="text-red ml-5">{lastUpdateDate} 최종 수정</span></td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">제목<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_title" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학교</label></th>
                        <td className="text-left">
                            <select id="TE_curriSchool" name="search-4">
                                <option value="">학교</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학년</label></th>
                        <td className="text-left">
                            <select id="TE_curriGrade" name="search-4">
                                <option value="">학년</option>
                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학기</label></th>
                        <td className="text-left">
                            <select id="TE_curriSemester" name="search-4">
                                <option value="">학기</option>
                                {curriInfo && curriInfo?.curriSemester && (curriInfo?.curriSemester as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">과목</label></th>
                        <td className="text-left">
                            <select id="TE_curriSubject" name="search-4">
                                <option value="">과목</option>
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">자료년도</label></th>
                        <td className="text-left">
                            <select id="TE_dataYear" name="search-4">
                                <option value="">자료년도</option>
                                {dataYearList && (dataYearList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">도서분류</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_Category" name="sel-type">
                                <option value="">도서분류</option>
                                {visangbookCategoryList && (visangbookCategoryList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">출처(브랜드)</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_dataBrand" name="sel-type">
                                <option value="">브랜드</option>
                                {dataBrandList && (dataBrandList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-type">자료유형</label></th>
                        <td className="text-left" colSpan={2}>
                            <select id="TE_dataCategory" name="sel-type">
                                <option value="">자료유형</option>
                                {dataCategoryList && (dataCategoryList as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-title">해시태그</label></th>
                        <td className="text-left" colSpan={2}>
                            <input type="text" id="TE_hasgtag" className="w-full" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" className="w-56">사용 여부</th>
                        <td className="text-left text-slate-950 text-sm">
                            <div className="radio-wrap">
                                <span className="inp-radio text-sky-500">
                                    <input id="TE_is_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio text-red-600">
                                    <input id="TE_non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
            
            <div className="flex justify-between mt-5">
                <button type="button" className="btn-white" onClick={props.onClose}>취소</button>
                <button type="button" className="btn-sky" onClick={onNext}>{props.item ? "저장" : "다음"}</button>
            </div></>}

        </div>

    );  
}
export default VisangBookEdit;
