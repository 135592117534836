import { ChangeEvent, useEffect, useState } from "react";
import * as util from '../util';
import { IBrand, ILibrarySearchData, IMeta, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { DatePicker } from '@gsebdev/react-simple-datepicker';
import { logger } from '../logger';
import '../common.scss';

interface IReportSearch {
    brand: IBrand;
    pageInfo: IPageInfo;
	onSearch: (data: any) => void;
    showLoading: (loading: boolean) => void;    
}

export interface IPageInfo {
    page: number;
    pageItemCnt: number;
}

export const report_types = ["모듈 오류(개념/문항/활동)", "시스템 오류", "챗봇 오류", "기타 오류"];

function ReportSearch(props: IReportSearch) {

    const [typeList, setTypeList] = useState<string[]>([]);

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setTypeList(report_types);
        }
        bDone = true;
    }, [])

    function init() {
        const RS_keyword = document.getElementById("RS_keyword") as HTMLInputElement;
        RS_keyword.value = "";
    }
        
    function onChangeType(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("type", e.target.value);        
    }

    function onSearch(): void {

        if(!curriInfo) {
            return;
        }

        const RS_type = document.getElementById("RS_type") as HTMLSelectElement;
        const RS_type_val = RS_type.value;

        const RS_status = document.getElementById("RS_status") as HTMLSelectElement;
        const RS_status_val = RS_status.value;

        const RS_category = document.getElementById("RS_category") as HTMLSelectElement;
        const RS_category_val = RS_category.value;

        const RS_keyword_type = document.getElementById("RS_keyword_type") as HTMLSelectElement;
        const RS_keyword = document.getElementById("RS_keyword") as HTMLInputElement;

        let search = "";
        if(RS_keyword) {
            search = RS_keyword.value;
        }
        let searchField = "";
        if(RS_keyword_type) {
            searchField = RS_keyword_type.value;
        }

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            search: search,
            searchField: searchField,

            type: RS_type_val === "" ? undefined : RS_type_val,
            status: RS_status_val === "" ? undefined : RS_status_val,
            category: RS_category_val === "" ? undefined : RS_category_val,
            // keyword: RS_keyword_val === "" ? undefined : RS_keyword_val,
            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: props.pageInfo.pageItemCnt, 
        };

        logger.log("onSearch", data);

        props.onSearch(data);
    }

    return (
        <div>
            {/* <!-- 검색영역 --> */}
            <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <div className="mb-3">
                        <fieldset>
                            <label htmlFor="type1">구분</label>
                            <select className="mr-5" id="RS_type" onChange={(e) => onChangeType(e)}>
                                <option value="">전체</option>
                                {typeList && typeList.map((type, idx) => {
                                return(
                                <option key={idx} value={type}>{type}</option>              
                                )})}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="type1">처리상태</label>
                            <select className="mr-5" id="RS_status">
                                <option value="">전체</option>
                                <option value="처리대기">처리대기</option>
                                <option value="보류">보류</option>
                                <option value="완료">완료</option>
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="type1">유형</label>
                            <select className="mr-5" id="RS_category">
                                <option value="">전체</option>
                                <option value="문항">문항</option>
                                <option value="개념">개념</option>
                                <option value="활동">활동</option>
                            </select>
                        </fieldset>

                    </div>

                    <div className="text-right">
                        <fieldset>
                        <label htmlFor="keyword_type">검색</label>
                            <select className="mr-2" id="RS_keyword_type" name="keyword_type">
                                <option value={"creator_name"}>이름</option>
                                <option value={"creator"}>아이디</option>
                                <option value={"name"}>타이틀</option>
                                <option value={"id"}>Key</option>
                            </select>
                            <input type="text" name="keyword" id="RS_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>
            </div>
            {/* <!-- .//검색영역 --> */}

        </div>
    );
}
export default ReportSearch;
