import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ILibrary, ILibraryFile, IMeta, IReport, IScript, IText, S3Server, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import { report_types } from "./ReportSearch";

interface IReportRegister {
    brand: IBrand;
    selectedItem: IReport | null;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function ReportRegister(props: IReportRegister) {

    const [typeList, setTypeList] = useState<string[]>([]);

    const curriInfo = util.getCurrentCurriInfo();
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        if(!bDone) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }
        
            setTypeList(report_types);

            const selectedItem = props.selectedItem;
            if(selectedItem) {
                // init(selectedItem.id);
                logger.log("selectedItem", selectedItem);
                
                const RR_type = document.getElementById("RR_type") as HTMLSelectElement;
                RR_type.value = selectedItem.type;
        
                const RR_description = document.getElementById("RR_description") as HTMLInputElement;
                RR_description.value = selectedItem.description;
        
                const RR_school = document.getElementById("RR_school") as HTMLSelectElement;
                RR_school.value = selectedItem.school_name;
                
                const RR_status = document.getElementById("RR_status") as HTMLSelectElement;
                RR_status.value = selectedItem.status;
                
                const RR_subject = document.getElementById("RR_subject") as HTMLSelectElement;
                RR_subject.value = selectedItem.subject;        
            }
        }
        bDone = true;

    }, [])

    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getLibraryInfo(id);
        logger.log("init", result);
        props.showLoading(false);

        if(result) {            
            const selectedItem  = result as IReport;
        }        
    }

    function OnClose(): void {
        props.OnClose();
    }

    function onSave() {

        if(!curriInfo) {
            return;
        }

        const RR_type = document.getElementById("RR_type") as HTMLSelectElement;
        const RR_type_val = RR_type.value;

        const RR_description = document.getElementById("RR_description") as HTMLInputElement;
        const RR_description_val = RR_description.value;
        if(RR_description_val === "") {
            RR_description.focus();
            props.showModal("", "내용을 입력해 주세요.");
            return;
        }

        const RR_school = document.getElementById("RR_school") as HTMLSelectElement;
        // const RR_school_val = curriInfo.curriSchool[RR_school.selectedIndex];
        
        const RR_status = document.getElementById("RR_status") as HTMLSelectElement;
        
        const RR_subject = document.getElementById("RR_subject") as HTMLSelectElement;
        
        const brandId = props.brand.id;
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");

        const data = {
            "id": props.selectedItem ? props.selectedItem.id : undefined,
            "brand_id": brandId,
            "type": RR_type_val,
            "creator": props.selectedItem ? undefined : uid,
            "creator_name": props.selectedItem ? undefined : uname,
            "creator_id": props.selectedItem ? undefined : _id,
            "updater": props.selectedItem ? uid : undefined,
            "updater_name": props.selectedItem ? uname : undefined,
            "updater_id": props.selectedItem ? _id : undefined,
            "description": RR_description_val,
            "school_name": RR_school.value,
            "status": RR_status.value,
            "subject": RR_subject.value, 
        };

        logger.log("onSave", data);
    
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.reportForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }

    const hasAuth = util.getHasAuthMenu(props.selectedItem?.creator ? props.selectedItem?.creator : "");

    return (
        <div>

            <table>
                <caption className="sr-only">신고 내용</caption>
                <tbody>
                    <tr>
                        <th scope="col" className="w-32">학교명</th>
                        <td className="text-left">
                            <input type="text" id="RR_school" className="w-[300px] mr-2" />
                        </td>
                        <th scope="col" className="w-32">교과목</th>
                        <td className="text-left">
                            <input type="text" id="RR_subject" className="w-[300px] mr-2" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" className="w-32">신고자</th>
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>
                        <th scope="col" className="w-32">신고 유형</th>
                        <td className="text-left">
                            <select className="mr-2" id="RR_type">
                                {typeList && typeList.map((type, idx) => {
                                return(
                                <option key={idx} value={type}>{type}</option>              
                                )})}
                            </select>                                
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" className="w-32">등록일</th>
                        <td className="text-left">{util.getDate()}</td>
                        <th scope="col" className="w-32">처리상태</th>
                        <td className="text-left">
                            <select className="mr-2" id="RR_status">
                                <option value="처리대기">처리대기</option>
                                <option value="보류">보류</option>
                                <option value="완료">완료</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">신고내용</th>
                        <td colSpan={3} className="text-left">
                            <div className="flex items-center w-full min-h-[320px]">
                                <textarea id="RR_description" rows={12} cols={120}></textarea>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={OnClose}>목록</button>
                    {!props.selectedItem &&
                    <button type="button" className="btn-sky" onClick={onSave}>{"저장"}</button>}
                    {props.selectedItem &&
                    <button type="button" className="btn-sky" onClick={onSave} disabled={!hasAuth}>{"수정"}</button>}
                </div>
            </div>

        </div>
    );
}
export default ReportRegister;
