import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IAuthGroup, IBrand, IRole, IUser, IUserAuthGroupMap, headers } from "../common";
import PopupCheckID from "./popupDupCheck";
import { logger } from '../logger';
import PopupChangePassword from "./popupChangePassword";

interface IMyPage {
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MyPage(props: IMyPage) {

    const [authGroupList, setAuthGroupList] = useState([] as IAuthGroup[]);    
    const [modalShow, setModalShow] = useState(false);
    const [pwd, setPwd] = useState("");
    const [me, setMe] = useState(null as any);
    
    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            getUser();
        }
        bDone = true;
    }, [])

    async function getUser() {

        props.showLoading(true);

        const userList = await util.getUserList();
        if(!userList) {
            alert("가져오기 실패!!!");
            return;
        }

        const me = userList.find((user) => user.uid === util.getUserInfo().uid)
        if(!me) {
            return;
        }

        setMe(me);

        const U_name = (document.getElementById('U_name') as HTMLInputElement);
        if(U_name) {
            U_name.focus();
        }                    
        const U_is_active = (document.getElementById('U_is_active') as HTMLInputElement);
        if(U_is_active) {
            U_is_active.checked = true;
        }
        
        (document.getElementById('U_name') as HTMLInputElement).value = me.name;
        (document.getElementById('U_id') as HTMLInputElement).value = me.uid;
        (document.getElementById('U_email') as HTMLInputElement).value = me.email ? me.email : "";
        (document.getElementById('U_team') as HTMLInputElement).value = me.team ? me.team : "";

        // (document.getElementById('U_name') as HTMLInputElement).readOnly = true;
        (document.getElementById('U_id') as HTMLInputElement).readOnly = true;
        // (document.getElementById('U_email') as HTMLInputElement).readOnly = true;
        // (document.getElementById('U_team') as HTMLInputElement).readOnly = true;

        (document.getElementById('U_is_active') as HTMLInputElement).checked = me.is_active;    

        const authGroupList = await util.getAuthGroupList();
        if(!authGroupList) {
            alert("가져오기 실패!!!");
            return;
        }

        let _authGroupList : IAuthGroup[] = [];

        const arrAuth = me.authgroup.split(",");
        arrAuth.map((auth, i) => {
            const found = authGroupList.find((item) => item.id === Number(auth));
            if(found) {
                _authGroupList.push(found);
            }
        });    

        setAuthGroupList(_authGroupList);

        props.showLoading(false);
    }

    async function onSave() {

        const uid = (document.getElementById('U_id') as HTMLInputElement).value
        const name = (document.getElementById('U_name') as HTMLInputElement).value
        const email = (document.getElementById('U_email') as HTMLInputElement).value
        const team = (document.getElementById('U_team') as HTMLInputElement).value
        const is_active = (document.getElementById('U_is_active') as HTMLInputElement).checked

        const _me = me as IUser;

        const data = {
            id: _me.id,
            uid: uid,
            name: name,
            password: pwd ? pwd : "",
            email: email,
            team: team,
            is_active: is_active,
            authgroup: _me.authgroup,
            updater_id: _me.id,
            updater: uid,
            updater_name: name,
            // creator: _me.creator,
            // creator_id: _me.creator_id,
            // creator_name: _me.creator_name,
            // role: _me.role,
        }

        props.showLoading(true);

        const result = await util.updateUser(data);

        logger.log("onSave", _me, data, result);

        props.showLoading(false);

        if(result) {
            props.showModal("", "저장이 완료 되었습니다.");
        } else {
            props.showModal("", "저장이 실패 하였습니다.");
        }

        // if(result) {
        //     props.showModal("", "저장이 완료 되었습니다.");
        // } else {
        //     props.showModal("", "사용자 업데이트 실패 하였습니다.");
        // }
        
        // getUser();
    }

    function onChangePassword(): void {
        setModalShow(true);
    }

    function onChangePwd(pwd: string): void {

        if(pwd !== "") {
            setPwd(pwd);
            (document.getElementById('U_pwd') as HTMLInputElement).value = pwd;
        }

        setModalShow(false);
    }
    
    return (

        <>        
        <table>
            <caption className="sr-only">내 계정 관리 {common.VERSION}</caption>
            <tbody>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">이름 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_name" onChange={() => {}}/>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">아이디 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_id" onChange={() => {}}/>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">비밀번호 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="flex text-left items-center">
                        <input type="password" id="U_pwd" readOnly onChange={() => {}} style={{display: pwd === "" ? "none" : "", outline:"none"}}/>
                        <div className="text-right">
                            <button type="button" className={pwd === "" ? "btn-white-s" : "btn-white-s ml-3"} onClick={onChangePassword} >비밀번호 변경</button>                        
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">이메일 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_email" onChange={() => {}}/>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">부서명 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_team" onChange={() => {}}/>
                    </td>
                </tr>

                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">권한 그룹<br/>(KEY)<em className="text-sky-500 align-top">*</em></label></th>
                    <td>
                        <table style={{borderTopWidth:0, borderBottomWidth:0}}>
                            <tbody>
                                {authGroupList && authGroupList.map((item, idx) => {
                                    return(
                                        <tr key={idx} style={{borderTopWidth:0, borderBottomWidth:0}}>
                                            <td className="text-left w-56">
                                                <span style={{paddingLeft:"0px"}}>{item.name} ({item.code})</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </td>

                </tr>

                {/* <tr>
                    <th scope="row" className="w-56">등록자</th>
                    {selectedItem &&
                    <td className="text-left">{selectedItem.creator_name} ({selectedItem.creator})</td>}
                    {!selectedItem &&
                    <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                </tr>
                <tr>
                    <th scope="row" className="w-56">등록일</th>
                    {selectedItem &&
                    <td className="text-left">{selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
                    {!selectedItem &&
                    <td className="text-left">{util.getDate()}</td>}
                </tr> */}

                <tr>
                    <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                    <td className="text-left">
                        <div className="radio-wrap">
                            <span className="inp-radio">
                                <input id="U_is_active" name="rd-use" type="radio" />
                                <label htmlFor="rd-on">사용</label>
                            </span>
                            <span className="inp-radio">
                                <input id="U_non_active" name="rd-use" type="radio" />
                                <label htmlFor="rd-off">미사용</label>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
                
        <div className="relative mt-5">
            <div className="text-right">
                <button type="button" className="btn-sky" onClick={onSave}>{"저장"}</button>
            </div>
        </div>

        <PopupChangePassword view={modalShow} onChangePwd={onChangePwd} showModal={props.showModal} />

        </>);
}
export default MyPage;
