import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, ILibrary, ILibraryFile, IMeta, IMetaMap, IScript, IText } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from 'xlsx';

interface ISetBulkRegister {
    brand: IBrand;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function SetBulkRegister(props: ISetBulkRegister) {

    const [fileContent, setFileContent] = useState(null as any);
    const [fileUploading, setFileUploading] = useState(false);

    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    useEffect(() => {
        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.OnClose();
        //     return;
        // }    
    }, [])

    function OnClose(): void {
        props.OnClose();
    }

    async function onExcelProccessing_sample(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const sheetName = dataParse[0] as string[];
            logger.log("dataParse", file, sheetName[0]);  

            // const arrRows = [];
            // for (let i = 4; i < dataParse.length; i++) {
            //     const arrItem = dataParse[i] as string[];
            //     arrRows.push(arrItem);
            // }                
            // logger.log("arrRows", arrRows);  
        
            let totalCnt = 1;

            // let metaName = ["curriBook"];
            // let metaName = ["curriBook", "curriUnit1", "curriUnit2", "curriUnit3", "curriUnit4", "curriUnit5"];

            let bookCode = "";

            const names = [
                // "curriBook", "curriUnit1", "curriUnit2", "curriUnit3", "curriUnit4", "curriUnit5",
                "curriYear", 
                "curriSchool", 
                "curriSubject", 
                "curriGrade", 
                "curriSemester",
                "unitGroup",
            ];
            const listAll = await util.getMetaNamesList(names);
            logger.log("listAll", listAll);  

            const curriYear = listAll.filter((meta, _) => meta.name === "curriYear");
            const curriSchool = listAll.filter((meta, _) => meta.name === "curriSchool");
            const curriSubject = listAll.filter((meta, _) => meta.name === "curriSubject");
            const curriGrade = listAll.filter((meta, _) => meta.name === "curriGrade");
            const curriSemester = listAll.filter((meta, _) => meta.name === "curriSemester");
            const unitGroup = listAll.filter((meta, _) => meta.name === "unitGroup");

            for (let i = 4; i < dataParse.length; i++) {

                const item = dataParse[i] as string[];
                logger.log("dataParse", item);  

                let code = String(item[5]);

                // if(item[1] === "curriBook") {
                //     bookCode = code;
                // } else {
                //     code = bookCode + "-" + code;
                // }

                // if(item[1] === "curriBook") {
                //     const checkCode = await util.getMetaByCode(code);
                //     if(checkCode) {
                //         continue;
                //     }
                // }

                let id = undefined;

                const checkCode = await util.getMetaByCode(code);
                if(checkCode) {
                    id = (checkCode as IMeta).id;
                    logger.log("DUP code", code);
                    // continue;
                }

                let depth = 1;
                if(item[1] === "curriBook") {
                    depth = 1;
                } else if(item[1] === "curriUnit1") {
                    depth = 2;
                } else if(item[1] === "curriUnit2") {
                    depth = 3;
                } else if(item[1] === "curriUnit3") {
                    depth = 4;
                } else if(item[1] === "curriUnit4") {
                    depth = 5;
                } else if(item[1] === "curriUnit5") {
                    depth = 6;
                } else if(item[1] === "curriUnit6") {
                    depth = 7;
                } else if(item[1] === "curriUnit7") {
                    depth = 8;
                }

                // logger.log(item[1], item);  

                const listMetaMap : IMeta[] = [];
                if(item[6] && item[6].length > 0) {
                    const obj = curriYear.find((_item) => _item.val === item[6]);
                    if(obj) {
                        listMetaMap.push(obj);
                    }
                }
                if(item[7] && item[7].length > 0) {
                    const obj = curriSchool.find((_item) => _item.val === item[7]);
                    if(obj) {
                        listMetaMap.push(obj);
                    }
                }
                if(item[8] && item[8].length > 0) {
                    const obj = unitGroup.find((_item) => _item.val === item[8]);
                    if(obj) {
                        listMetaMap.push(obj);
                    }
                }
                if(item[9] && item[9].length > 0) {
                    const obj = curriSubject.find((_item) => _item.val === item[9]);
                    if(obj) {
                        listMetaMap.push(obj);
                    }
                }
                if(item[10] && item[10].length > 0) {
                    const obj = curriGrade.find((_item) => _item.val === item[10]);
                    if(obj) {
                        listMetaMap.push(obj);
                    }
                }
                if(item[11] && item[11].length > 0) {
                    const obj = curriSemester.find((_item) => _item.val === item[11]);
                    if(obj) {
                        listMetaMap.push(obj);
                    }
                }

                const { v4: uuidv4 } = require('uuid');            
                const uuid = uuidv4();

                let pcode = item[1] === "curriBook" ? 0 : -1;
                if(pcode === -1) {
                    let parentCode = "";
                    if(Number(item[15]) > 0) {
                        pcode = Number(item[15]);
                    } else {
                        const arrCode = code.split("-");
                        arrCode.map((item, idx) => {
                            if(idx < arrCode.length-1) {
                                if(parentCode === "") {
                                    parentCode = item
                                } else {
                                    parentCode = parentCode + "-" + item;
                                }                                
                            }
                        });

                        const parentMeta = await util.getMetaByCode(parentCode);
                        // logger.log("getMetaByCode", code, parentCode, parentMeta);
                        // const parentMeta = listAll.find((item) => item.code === parentCode);
                        if(parentMeta) {
                            const _parent = parentMeta as IMeta;
                            pcode = _parent.id!;
                        } else {
                            logger.log("no parent", parentCode);
                        }
                    }
                }

                const meta = {
                    // brand_id: item[0] ? item[0] : props.brand.id,
                    brand_id: 6,
                    id: id,        
                    code: code,
                    name: item[1],
                    val: item[3],
                    depth: depth,
                    parent_id: pcode,
                    is_active: String(item[19]) === "1" ? true : false,
                    creator: item[16] ? item[16] : "cbstest1",
                    // creator_id: _id,
                    // creator_name: uname,
                    description: item[4],
        
                    // updater: undefined,
                    // updater_id: undefined,
                    // updater_name: undefined,
        
                    max_depth: item[14] ? Number(item[14]) : undefined,
                    
                    uuid: uuid,
                }
        
                const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": listMetaMap}

                // if(item[1] === "curriBook") {
                //     logger.log(totalCnt, "insertMetaMetaMap", metamap);
                // }
                logger.log(totalCnt, id ? "update" : "insert", metamap);

                // let result = null;
                // if(id) {
                //     result = await util.updateMetaMetaMap(metamap);
                // } else {
                //     result = await util.insertMetaMetaMap(metamap);
                // }
                // logger.log(totalCnt, id ? "update" : "insert", metamap, result);

                totalCnt = totalCnt + 1;                
            }

            setFileUploading(false);                
        }
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const sheetName = dataParse[0] as string[];
            // logger.log("dataParse", file, sheetName[0]);  

            // const arrRows = [];
            // for (let i = 4; i < dataParse.length; i++) {
            //     const arrItem = dataParse[i] as string[];
            //     arrRows.push(arrItem);
            // }                
            // logger.log("arrRows", arrRows);  
        
            let totalCnt = 1;

            for (let i = 2; i < dataParse.length; i++) {

                const item = dataParse[i] as string[];
                // logger.log("dataParse", item);  

                // logger.log(item[1], item);  

                const listMetaMap : IMeta[] = [];


                // 0브랜드
                // 1저작자 아이디(creator)	
                // 2수업자료 명(name)	

                // 3수업자료 유형(setCategory)	
                // 4교육과정년도(curriYear)	
                // 5학교급(curriSchool)	
                // 6교과(curriSubject)	
                // 7교과과정(curriBook)	
                // 8단원(curriUnit1)	
                // 9단원(curriUnit2)	
                // 10단원(curriUnit3)	
                // 11교수자료 유형(teachingCategory)	
                // 12교수자료 유형(teachingUsage)	
                // 13수업자료 난이도(difficulty)	

                // 14아티클 아이디 배열(articleList)
                // 15콘텐츠 공개 설정	
                // 16세트지 공개 설정	
                // 17수업 자료실 공개 설정

                for (let j = 3; j < 14; j++) {
                    if(item[j] && Number(item[j]) > 0) {
                        const meta = await util.getMetaById(item[j]);
                        if(meta) {
                            listMetaMap.push(meta);
                        }                        
                    }                        
                }
                
                const articles = [];
                const sA = item[14] + "\n";
                const arr = sA.split("\n");
                for (let k = 0; k < arr.length; k++) {
                    let id = arr[k];
                    id = id.replaceAll(" ", "");
                    id = id.replaceAll("\r", "");
                    id = id.trim();
                    if(id && Number(id) > 0) {
                        articles.push({"article_id": id});
                    }
                }    

                const data = {
                    // id: item[15] ? item[15] : undefined,
                    brand_id: item[0] ? item[0] : props.brand.id,
                    creator: item[1] ? item[1] : "cbstest1",
                    updater: item[1] ? item[1] : "cbstest1",
                    name: item[2],
                    
                    articles: articles,

                    metaMap: listMetaMap,

                    is_active: true,
                    is_materialOpen: true,
                    is_publicOpen: true,

                    autofillSetSummary: true,
                    
                    description: JSON.stringify(file.name),
                }

                logger.log(totalCnt, data);

                const result = await util.setForSave(data, item[15] ? "update" : "insert");
                logger.log(totalCnt, data, result);

                // if(result) {
                //     const result = await util.setSummaryForSave(data, item[15] ? "update" : "insert");
                // }

                totalCnt = totalCnt + 1;                
            }

            setFileUploading(false);                
        }
    }

    async function onFileUploadForSave() {

        if(!fileContent) {
            props.showModal("", "엑셀 파일을 등록해주세요.");
            return;
        }

        logger.log("onFileUploadForSave", fileContent.file);      

        var reader = new FileReader();
        reader.onload = (e) => onExcelProccessing(e, fileContent.file);
        reader.readAsBinaryString(fileContent.file)
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files![0];
        const _content : ILibraryFile = {
            type: _url.type,
            file: _url,
            url: _url.name,
            thumbnail: _url.name,
            isDelete: false,
            isUploaded: false
        }
        logger.log("handleChange", _content);
    
        setFileContent(_content);
    }
    
    return (
        <div>

            <div>

                <h4>일괄 등록</h4>

                {/* <!-- table --> */}
				<table>
					<caption className="sr-only">이미지 관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
							<td className="text-left">
								<label htmlFor="file-input" className="sr-only">파일찾기</label>
								{/* <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file"/> */}
								<input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange(e)}/>
							</td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onFileUploadForSave}>저장</button>
					</div>
				</div>

            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

        </div>
    );
}

export default SetBulkRegister;