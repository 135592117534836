import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, IUser, IBrand } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DatePicker from "@gsebdev/react-simple-datepicker/dist/DatePicker";
import "../library/LibrarySearch.scss";
import { IServerInfo } from "./PopupDeployProcess";

interface IPopupDeployTable {
    selectedItem: ITextBook;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

interface IkeyMessage {
    keyMessage: string;
}

function PopupDeployTable(props: IPopupDeployTable) {

    const [list, setList] = useState([] as any);
    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [listCount, setListCount] = useState(0);
    const [typeTextBook, setTypeTextBook] = useState("");

    const [selectedTarget, setSelectedTarget] = useState("");
    const [selectedServer, setSelectedServer] = useState("");
    const [serverList, setServerList] = useState<IServerInfo[]>([]);
    const [brandList, setBrandList] = useState<IBrand[]>([]);

    const curriInfo = util.getCurrentCurriInfo();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setSelectedTarget("교과서");
            getBrandList();
            getDeployServer();
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(serverList.length > 0) {
            refreshList();
        }
    }, [serverList])

    function onClose() {
        props.onClose();
    }

    const refreshList = () => {

        setCurrPage(1);

        const data = {
            target: "textbook",   // article, sets 
            deployServer_code: undefined, 

            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        const list = await util.deployContentsHistoryList(data);

        setList(list);
        logger.log("onSearch", data, list);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onChangeServer(server: IServerInfo | undefined = undefined) {

        let serverName = "";
        if(server) {
            serverName = server.name;
        }
        setSelectedServer(serverName);

        if(searchData) {
            searchData.deployServer_code = serverName === "" ? undefined : server?.code;
            setSearchData(searchData);
            _onSearch(searchData);    
        } else {
            // onSearch();
        }
    }

    function onChangeTarget(target: string) {

        setSelectedTarget(target);

        let _target = "";
        if(target === "교과서" ) {
            _target = "textbook";
        } else if(target === "세트지" ) {
            _target = "sets";
        } else if(target === "아티클" ) {
            _target = "article";
        }
        
        if(searchData) {
            searchData.target = _target;
            setSearchData(searchData);
            _onSearch(searchData);    
        } else {
            // onSearch();
        }
    }

    const TAB_BUTTON_STYLE = {
        borderRadius: "1rem", // 모든 방향의 border-radius를 한번에 지정
    };

    async function getDeployServer() {
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        const serverList = await util.getDeployServer(brand.id);
        logger.log("getDeployServer", serverList);
        setServerList(serverList);
    }

    async function getBrandList() {
        const brandList = await util.getBrandList();
        setBrandList(brandList);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{padding: "4rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">배포테이블​</h2>

                            <div className="overflow-x-auto px-5 mt-5 mb-3">
                                <ul className="tab-list !flex-nowrap !mb-0" style={{borderBottomWidth: 0}}>
                                    <li onClick={() => {
                                        onChangeServer();
                                    }}>
                                        <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedServer === "" ? "page" : undefined} className={selectedServer === "" ? "active" : undefined}>전체</a>
                                    </li>
                                    
                                    {serverList.map((server, idx) => {
                                        return(
                                            <li key={idx} className="ml-1" onClick={() => {
                                                onChangeServer(server);
                                            }}>
                                                <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedServer === server.name ? "page" : undefined} className={selectedServer === server.name ? "active" : undefined}>{server.name}</a></li>
                                        );
                                    })}

                                </ul>
                            </div>

                            <div className="overflow-x-auto px-5 mb-3">
                                <ul className="tab-list !flex-nowrap !mb-0" style={{borderBottomWidth: 0}}>
                                    <li  onClick={() => {
                                        onChangeTarget("교과서")
                                    }}>
                                        <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedTarget === "교과서" ? "page" : undefined} className={selectedTarget === "교과서" ? "active" : undefined}>교과서</a>
                                    </li>
                                    <li className="ml-1" onClick={() => {
                                        onChangeTarget("세트지")
                                    }}>
                                        <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedTarget === "세트지" ? "page" : undefined} className={selectedTarget === "세트지" ? "active" : undefined}>세트지</a>
                                    </li>
                                    <li className="ml-1" onClick={() => {
                                        onChangeTarget("아티클")
                                    }}>
                                        <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedTarget === "아티클" ? "page" : undefined} className={selectedTarget === "아티클" ? "active" : undefined}>아티클</a>
                                    </li>
                                    
                                </ul>
                            </div>

                            {/* <!-- table --> */}
                            <div className="ml-5 mr-5 mt-5">
                                <table>
                                    <caption className="sr-only">목차 리스트</caption>
                                    <thead>
                                        {selectedTarget === "교과서" && 
                                        <tr>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "4rem"}}>번호</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "4rem"}}>ID</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>교과서ID</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>배포서버</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>목차ID</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>교과서유형</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "4rem"}}>버전</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>교과서명</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "12rem"}}>배포브랜드</th>
                                        </tr>}
                                        {selectedTarget === "세트지" && 
                                        <tr>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "4rem"}}>번호</th>
                                            {/* <th scope="col" className="px-3" style={{textAlign: "center", width: "4rem"}}>ID</th> */}
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>세트지ID</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>배포서버</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>세트지코드</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>세트지유형</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "4rem"}}>버전</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>세트지명</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>사용여부</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>수업자료실공개여부</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>배포브랜드</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center", width: "6rem"}}>배포요청일</th>
                                        </tr>}
                                        {selectedTarget === "아티클" && 
                                        <tr>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>번호</th>
                                            {/* <th scope="col" className="px-3" style={{textAlign: "center"}}>Key</th> */}
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>아티클ID</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>배포서버</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>아티클코드</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>아티클유형</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>버전</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>아티클명</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>사용여부</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>공개여부</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>배포브랜드</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>배포요청일</th>
                                        </tr>}
                                    </thead>
                                    <tbody>
                                        {list && (list as any[]).map((item, idx) => {

                                            const totalCnt = item.full_count ? item.full_count : 0;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                            let deployedServer = "";
                                            const deployStatusList = item.deployStatusList as any[];

                                            const bd = brandList.find((brand) => brand.id === item.brand_id);
                                            
                                            let updateDate = "-";
                                            if(item.updatedate) {
                                                updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                                            }
                    
                                            if(selectedTarget === "교과서") {
                                                return(                                                
                                                    <tr key={idx} onClick={() => {}}>
                                                        <td>{_num}</td>
                                                        <td>{item.contentsHistory_id}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.deployServer_code}</td>
                                                        <td>{item.textbookIndex_id}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.version}</td>
                                                        <td style={{cursor: "pointer"}} onClick={async () => {

                                                            const data = {
                                                                textbook_id: item.id,
                                                                deployServer_code: item.deployServer_code,
                                                            }
                                                            const result = await util.deployTextbookInfo(data);
                                                            logger.log("deployTextbookInfo", data, result);
                                                            if(result) {
                                                                const msg : IkeyMessage = result;
                                                                if(msg.keyMessage) {
                                                                    props.showModal("keyMessage", msg.keyMessage);
                                                                }                                                                
                                                            }
                                                            // alert(JSON.stringify(result));

                                                        }}>{item.name}</td>
                                                        <td>{bd?.name}</td>
                                                    </tr>    
                                                );    
                                            }
                                            else if(selectedTarget === "세트지") {
                                                return(                                                
                                                    <tr key={idx} onClick={() => {}}>
                                                        <td>{_num}</td>
                                                        <td>{item.contentsHistory_id}</td>
                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.deployServer_code}</td>
                                                        <td>{item.targetId}</td>
                                                        <td>{item.targetCategory}</td>
                                                        <td>{item.version}</td>
                                                        <td>{item.targetName}</td>
                                                        <td>{item.is_active ? "사용" : "미사용"}</td>
                                                        <td>{item.is_publicOpen ? "공개" : "비공개"}</td>
                                                        <td>{bd?.name}</td>
                                                        <td>{updateDate}</td>
                                                        {/* <td dangerouslySetInnerHTML={{ __html: deploydate }}></td> */}
                                                    </tr>    
                                                );    
                                            }
                                            else if(selectedTarget === "아티클") {
                                                return(                                                
                                                    <tr key={idx} onClick={() => {}}>
                                                        <td>{_num}</td>
                                                        <td>{item.contentsHistory_id}</td>
                                                        {/* <td>{item.id}</td> */}
                                                        <td>{item.deployServer_code}</td>
                                                        <td>{item.targetId}</td>
                                                        <td>{item.targetCategory}</td>
                                                        <td>{item.version}</td>
                                                        <td>{item.targetName}</td>
                                                        <td>{item.is_active ? "사용" : "미사용"}</td>
                                                        <td>{item.is_publicOpen ? "공개" : "비공개"}</td>
                                                        <td>{bd?.name}</td>
                                                        <td>{updateDate}</td>
                                                        {/* <td dangerouslySetInnerHTML={{ __html: deploydate }}></td> */}
                                                    </tr>    
                                                );    
                                            }

                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- .//table -->	 */}                

                            <div className="relative mt-10 mb-5">

                                {/* <!-- pagenation --> */}
                                <div className="text-center">
                                    <nav className="pagenation" aria-label="Pagination">

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}

                                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                        return (
                                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                        )})}

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Next</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        
                                    </nav>
                                </div>
                                {/* <!-- .// pagenation --> */}
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupDeployTable;
