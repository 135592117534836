import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, IUser } from "../common";  
import { logger } from "../logger";
import { IEngMeta } from "./MetaStudyMapEngEdit";

interface IPopupLanguageFormatSearch {
    onSelect: (item: IEngMeta) => void;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function PopupLanguageFormatSearch(props: IPopupLanguageFormatSearch) {
      
    const [list, setList] = useState<IMeta[]>([]);
    const [list0, setList0] = useState<IMeta[]>([]);
    const [list1, setList1] = useState<IMeta[]>([]);
    const [list2, setList2] = useState<IMeta[]>([]);
    const [list3, setList3] = useState<IMeta[]>([]);

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            getLanguageFormatList();
        }
        bDone = true;
    }, [])

    async function getLanguageFormatList() {

        props.showLoading(true);

        const list = await getMetaList("1", "languageFormat");
        logger.log("languageFormat", list);
        setList0(list);

        props.showLoading(false);

    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function onChangeLanguageFormat1(e: ChangeEvent<HTMLSelectElement>) {

        setList1([]);
        setList2([]);
        setList3([]);

        if(e.target.selectedIndex === 0) {
            return;
        }

        const _languageFormat1 = list0[e.target.selectedIndex-1];

        props.showLoading(true);

        const list = await util.getDescendantsList(_languageFormat1.code);
        const _list = list.filter((item) => item.code !== _languageFormat1.code);
        const __list = _list.filter((item) => item.depth === _languageFormat1.depth + 1);

        logger.log("_languageFormat1", _languageFormat1, __list);        

        setList1(__list);
        props.showLoading(false);
    }

    async function onChangeLanguageFormat2(e: ChangeEvent<HTMLSelectElement>) {

        setList2([]);
        setList3([]);

        if(e.target.selectedIndex === 0) {
            return;
        }

        const _languageFormat2 = list1[e.target.selectedIndex-1];
        logger.log("_languageFormat2", _languageFormat2);        

        props.showLoading(true);

        const list = await util.getDescendantsList(_languageFormat2.code);
        const _list = list.filter((item) => item.code !== _languageFormat2.code);
        const __list = _list.filter((item) => item.depth === _languageFormat2.depth + 1);

        setList2(__list);

        props.showLoading(false);
    }

    async function onChangeLanguageFormat3(e: ChangeEvent<HTMLSelectElement>) {

        setList3([]);

        if(e.target.selectedIndex === 0) {
            return;
        }

        const _languageFormat3 = list2[e.target.selectedIndex-1];
        logger.log("_languageFormat3", _languageFormat3);        

        props.showLoading(true);

        const list = await util.getDescendantsList(_languageFormat3.code);
        const _list = list.filter((item) => item.code !== _languageFormat3.code);
        const __list = _list.filter((item) => item.depth === _languageFormat3.depth + 1);

        setList3(__list);

        props.showLoading(false);
    }

    function onClose() {
        props.onClose();
    }

    function onSelect() {

        const SM_Depth0 = document.getElementById("SM_Depth0") as HTMLSelectElement;
        const SM_Depth1 = document.getElementById("SM_Depth1") as HTMLSelectElement;
        const SM_Depth2 = document.getElementById("SM_Depth2") as HTMLSelectElement;
        const SM_Depth3 = document.getElementById("SM_Depth3") as HTMLSelectElement;
    
        const SM_Depth0_val = list0[SM_Depth0.selectedIndex-1];
        const SM_Depth1_val = list1[SM_Depth1.selectedIndex-1];
        const SM_Depth2_val = list2[SM_Depth2.selectedIndex-1];
        const SM_Depth3_val = list3[SM_Depth3.selectedIndex-1];
        
        const data : IEngMeta = {
            depth0: SM_Depth0_val,
            depth1: SM_Depth1_val,
            depth2: SM_Depth2_val,
            depth3: SM_Depth3_val,
        }
        props.onSelect(data);
    }

    return (
                
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{width:"60rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">언어형식 선택</h2>
                            <div className="px-5">
                                <div className="py-8 text-center">

                                    <table>
                                        <caption className="sr-only">관리 테이블</caption>
                                        <tbody>

                                        <tr>
                                                <td className="text-left">
                                                    <div>
                                                        <select className="mr-2" id="SM_Depth0" name="SM_Depth0" onChange={(e) => onChangeLanguageFormat1(e)}>
                                                            <option value={""}>언어형식</option>
                                                            {list0 && list0.map((sm0, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm0.val}>{sm0.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth1"  onChange={(e) => onChangeLanguageFormat2(e)}>
                                                            <option value={""}>Depth 1</option>
                                                            {list1 && list1.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth2" onChange={(e) => onChangeLanguageFormat3(e)}>
                                                            <option value={""}>Depth 2</option>
                                                            {list2 && list2.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <select className="mr-2" id="SM_Depth3">
                                                            <option value={""}>Depth 3</option>
                                                            {list3 && list3.map((sm, idx) => {
                                                                return(
                                                                    <option key={idx} value={sm.id}>{sm.val}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>   

                                        </tbody>
                                    </table>
                                    
                                </div>

                                <div className="my-5 text-right">
                                    <button type="button" className="btn-sky" onClick={onSelect}>저장</button>
                                </div>

                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}
  
export default PopupLanguageFormatSearch;
