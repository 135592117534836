import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { count } from "console";
import MetaAddEdit from "./MetaAddEdit";
import { CurriInfo, IBrand, ICurriInfo, IMenu, IMeta, IMetaMap, MetaMenuInfo, headers, isDev } from "../common";
import '../common.scss';
import { logger } from '../logger';
import MetaStudyMap from "./MetaStudyMap";
import MetaContentsItem from "./MetaContentsItem";
import MetaBulkRegister from "./MetaBulkRegister";
import MetaAchievementStandard from "./MetaAchievementStandard";
import MetaMaterial from "./MetaMaterial";
import MetaCommunication from "./MetaCommunication";
import MetaLanguageFormat from "./MetaLanguageFormat";
import MetaStudyMapEng from "./MetaStudyMapEng";

import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import MetaEvaluationComm from "./MetaEvaluationComm";
import MetaServiceMap from "./MetaServiceMap";

interface IMetaManager {
    view: boolean;
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

export interface ISearchData {
    year: string;
    school: string;
    subject: string;
    unit: string;
    keyword: string;
}

function MetaManager(props: IMetaManager) {

    const [metaList, setMetaList] = useState([] as IMeta[]);
    const [viewList, setViewList] = useState(false);
    const [metaHList, setMetaHList] = useState([] as IMeta[]);
    const [viewHList, setViewHList] = useState(false);
    const [viewSearch, setViewSearch] = useState(false);

    const [currItem, setCurrItem] = useState(null as any);
    const [parentItem, setParentItem] = useState(null as any);
    // const [selectedBook, setSelectedBook] = useState(null as any);
    const [searchData, setSearchData] = useState(null as any);

    const [isEditing, setIsEditing] = useState(false);
    const [isBulkEditing, setIsBulkEditing] = useState(false);

    const [title, setTitle] = useState("");

    const [viewStudyMap, setViewStudyMap] = useState(false);
    const [viewContentsItem, setViewContentsItem] = useState(false);
    const [viewAchievementStandard, setViewAchievementStandard] = useState(false);
    const [viewMaterial, setViewMaterial] = useState(false);
    const [viewCommunication, setViewCommunication] = useState(false);
    const [viewLanguageFormat, setViewLanguageFormat] = useState(false);
    const [viewStudyMapEng, setViewStudyMapEng] = useState(false);
    const [viewEvaluationCommu, setViewEvaluationCommu] = useState(false);
    const [viewServiceMap, setViewServiceMap] = useState(false);

    const [selectedMeta, setSelectedMeta] = useState(null as any);

    const [bulkUpdate, setBulkUpdate] = useState(false);

    const [metaName, setMetaName] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaType, setMetaType] = useState("");

    const [selectedItemId, setSelectedItemId] = useState(null as any);

    let prevView = false;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {    
            setViewList(false);
            setIsEditing(false);
            setViewHList(false);
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.view && !prevView) {
        }
        prevView = props.view;
    }, [props.view])

    useEffect(() => {

        logger.log("viewHList", viewHList, searchData);

        if(viewHList) {
        } else {
            if(searchData) {
                const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
                if(MM_School) {
                    MM_School.value = (searchData as ISearchData).school;
                }
                const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
                if(MM_Unit) {
                    MM_Unit.value = (searchData as ISearchData).unit;
                }
                const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement);
                if(searchKeyword) {
                    (document.getElementById('searchKeyword') as HTMLInputElement).value = (searchData as ISearchData).keyword;
                }    
                const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
                if(MM_Subject) {
                    MM_Subject.value = (searchData as ISearchData).subject;
                }
                const MM_Year = document.getElementById("MM_Year") as HTMLSelectElement;
                if(MM_Year) {
                    MM_Year.value = (searchData as ISearchData).year;
                }
            }
        }
    }, [viewHList])

    useEffect(() => {

        logger.log("isEditing", isEditing, searchData);

        if(isEditing) {
        } else {
            if(searchData) {
                const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
                if(MM_School) {
                    MM_School.value = (searchData as ISearchData).school;
                }
                const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
                if(MM_Unit) {
                    MM_Unit.value = (searchData as ISearchData).unit;
                }
                const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement);
                if(searchKeyword) {
                    (document.getElementById('searchKeyword') as HTMLInputElement).value = (searchData as ISearchData).keyword;
                }    
                const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
                if(MM_Subject) {
                    MM_Subject.value = (searchData as ISearchData).subject;
                }
                const MM_Year = document.getElementById("MM_Year") as HTMLSelectElement;
                if(MM_Year) {
                    MM_Year.value = (searchData as ISearchData).year;
                }
            }
        }
    }, [isEditing])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu");
            changeMenu();
        }
    }, [props.menu])

    useEffect(() => {
        if(props.brand) {
        }
    }, [props.brand])

    useEffect(() => {
        if(metaHList && metaHList.length > 0) {
            //정합성 체크로직
            // testParent(metaHList);
        }
    }, [metaHList])

    async function testParent(metalist: IMeta[]) {

        for (let i = 0; i < metalist.length; i++) {
            const meta = metalist[i];
            let _pcode = "";
            const arr = meta.code.split("-");
            for (let i = 0; i < arr.length-1; i++) {
                if(_pcode === "")
                    _pcode = arr[i];
                else 
                    _pcode = _pcode + "-" + arr[i];
            }
            
            const parent = await util.getMetaByCode(_pcode);
            if(parent) {
                if(meta.parent_id !== (parent as IMeta).id) {
                    logger.log("testParent", meta, (parent as IMeta));
                    alert("정합성 오류!!! " + meta.name + "(" + meta.id + ")");
                } else {
                    logger.log("testParent ok", i, metalist.length);
                }
            }
        }

        logger.log("정합성 테스트 완료!!!");
    }

    const changeMenu = () => {

        const menu = util.getCurrentMenu();
        logger.log("menu", menu);

        setViewList(false);

        if(menu) {

            const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
            if(MM_School) {
                MM_School.value = "";
            }
            const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
            if(MM_Unit) {
                MM_Unit.value = "";
            }
            const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement);
            if(searchKeyword) {
                searchKeyword.value = "";
            }    
            const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
            if(MM_Subject) {
                MM_Subject.value = "";
            }
            const MM_Year = document.getElementById("MM_Year") as HTMLSelectElement;
            if(MM_Year) {
                MM_Year.value = "";
            }

            let isCommon = true;
            if(menu.menu1 === 9) {                
            } else {
                isCommon = false;
            }

            const _metaName = MetaMenuInfo[isCommon ? 0 : 1][menu.menu2].name;
            const _metaTitle = MetaMenuInfo[isCommon ? 0 : 1][menu.menu2].title;
            const _metaType = MetaMenuInfo[isCommon ? 0 : 1][menu.menu2].type; 

            setMetaName(_metaName);
            setMetaTitle(_metaTitle);
            setMetaType(_metaType ? _metaType : "");

            getMetaList("1", _metaName);

            setBulkUpdate(false);
            // setBulkUpdate(true);
            if(_metaName === "studyMap") {
                // const currBrand = util.getCurrentBrand();
                // if(currBrand && currBrand.code === "VAMT01") {
                //     setBulkUpdate(true);
                // }
                setBulkUpdate(true);    
            }
            if(_metaName === "curriBook") {
                setBulkUpdate(true);
            }
                
        } else {
            
        }

        // if(_name === "teachingCategory") {
        //     const brand = util.getCurrentBrand();
        //     if(brand && brand.code === "VSTB00") {
        //         _title = "도서분류";
        //     } 
        // }
        
        // setTitle(_title);
        setIsEditing(false);
        setViewStudyMap(false);
        setViewContentsItem(false);
        setViewAchievementStandard(false);
        setViewMaterial(false);
        setViewCommunication(false);
        setViewLanguageFormat(false);
        setViewStudyMapEng(false);
        setViewEvaluationCommu(false);
        setViewServiceMap(false);
        setSearchData(null);

        // if(menu.menu2 === 17) {
        //     const data = {
        //         code : "templateDiv",
        //     }
        //     getDescendantsList(data as IMeta);
        // }

    }

    async function getDescendantsList(meta: IMeta) {

        props.showLoading(true);

        const list = await util.getDescendantsList(meta.code+"-", false);
        const _list = list.filter((item) => item.code !== meta.code);

        for (let i = 0; i < _list.length; i++) {
            const item = _list[i];
            item.isOpened = true;
            item.hasChild = _list.find((_item) => _item.parent_id === item.id) ? true : false;
        }

        logger.log("getDescendantsList", _list);  

        setMetaHList(_list);
        setViewHList(true);

        props.showLoading(false);
    }

    async function updateMetaForEvaluationArea(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("updateMetaForEvaluationArea getMetaOfMetaExById", list);

        const _curriSchool = list.find((meta, _) => meta.name === "curriSchool");
        const _curriYear = list.find((meta, _) => meta.name === "curriYear");

        if(_curriSchool || _curriYear) {
            const meta = {
                id: Number(id),
                
                name: "evaluationArea",

                name1: "curriSchool",
                val1: _curriSchool ? _curriSchool.val : "",

                name2: "curriYear",
                val2: _curriYear ? _curriYear.val : "",
            }              

            const metamap = {"meta": meta as IMeta, "metamapList": null}
            logger.log("meta_mapping_meta", metamap);
    
            const result = await util.updateMetaMetaMap(metamap as unknown as IMetaMap);
            logger.log("updateMeta", meta, result);
        }    
    }

    async function updateMetaForCurriBook(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _curriSchool = list.find((meta, _) => meta.name === "curriSchool");
        const _curriYear = list.find((meta, _) => meta.name === "curriYear");

        if(_curriSchool) {
            const meta = {
                id: Number(id),
                
                name: "curriBook",

                name1: "curriSchool",
                val1: _curriSchool.val,

                name2: "curriYear",
                val2: _curriYear ? _curriYear.val : undefined,
            }              

            const metamap = {"meta": meta as IMeta, "metamapList": null}
            logger.log("meta_mapping_meta", metamap);
    
            const result = await util.updateMetaMetaMap(metamap as unknown as IMetaMap);
            logger.log("updateMeta", meta, result);
        }    
    }

    async function updateMetaForStudyMap(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _curriBook = list.find((meta, _) => meta.name === "curriBook");

        if(_curriBook) {

            const list = await util.getMetaOfMetaExById(String(_curriBook.id));
            const _curriSchool = list.find((meta, _) => meta.name === "curriSchool");
            
            const meta = {
                id: Number(id),
                
                name: "studyMap",

                name1: "curriBook",
                val1: _curriBook ? _curriBook.val : undefined,

                name2: "curriSchool",
                val2: _curriSchool ? _curriSchool.val : undefined,
            }              

            const metamap = {"meta": meta as IMeta, "metamapList": null}
            logger.log("meta_mapping_meta", metamap);
    
            const result = await util.updateMetaMetaMap(metamap as unknown as IMetaMap);
            logger.log("updateMeta", meta, result);
        }    
    }

    async function getMetaList(depth: string, name: string) {

        props.showLoading(true);

        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	

        if(name === "curriBook") {
            for (let i = 0; i < _list.length; i++) {
                const cb = _list[i];
                if(cb && (!cb.name1 || !cb.name2)) {
                    updateMetaForCurriBook(String(cb.id));
                }                
            }    
        } else if(name === "studyMap") {
            for (let i = 0; i < _list.length; i++) {
                const cb = _list[i];
                if(cb && (!cb.name1 || !cb.name2)) {
                    updateMetaForStudyMap(String(cb.id));
                }                
            }    
        } else if(name === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01") {
            for (let i = 0; i < _list.length; i++) {
                const cb = _list[i];
                if(cb && (!cb.name1 || !cb.name2)) {
                    updateMetaForEvaluationArea(String(cb.id));
                }                
            }    
        }

        const activeList = _list.filter((meta) => meta.is_active);
        const nonActiveList = _list.filter((meta) => !meta.is_active);

        setMetaList(activeList.concat(nonActiveList));
        setViewList(true);
        setViewHList(false);

        logger.log("getMetaList", activeList.concat(nonActiveList));

        props.showLoading(false);
    }

    function onAddTopMeta(): void {
        setParentItem(null);
        setCurrItem(null);
        setIsEditing(true);
    }

    function onAddMetaToCurrent(): void {
        logger.log("onAddMetaToCurrent setParentItem", selectedMeta);
        setParentItem(selectedMeta);
        setCurrItem(null);
        setIsEditing(true);
    }
    
    function onAddChildMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        logger.log("onAddChildMeta setParentItem", item);
        setParentItem(item);  
        setCurrItem(null);
        setIsEditing(true);  
        setSelectedItemId("id"+item.id);
    }

    function onEditMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        if(viewHList) {            
            
            logger.log("onEditMeta item", item);

            let _pcode = "";
            const arr = item.code.split("-");
            for (let i = 0; i < arr.length-1; i++) {
                if(_pcode === "")
                    _pcode = arr[i];
                else 
                    _pcode = _pcode + "-" + arr[i];
            }
            // const _pcode = item.code.substring(0, item.code.length-5);
            logger.log("onEditMeta _pcode", _pcode, selectedMeta);

            let _parentItem = metaHList.find((item) => item.code === _pcode);
            if(!_parentItem) {
                if(metaName === "curriBook") {
                    _parentItem = (curriInfo?.curriBook as IMeta[]).find((item) => item.code === _pcode);
                    if(!_parentItem) {
                        logger.log("onEditMeta setCurrItem", _parentItem, _pcode);
                        alert("parentItem 가져오기 실패!!!");
                        return;
                    }    
                } else if(metaName === "templateDiv") {
                    _parentItem = selectedMeta;
                }
            }
            setParentItem(_parentItem);
            logger.log("onEditMeta setCurrItem", item, _parentItem);
        } else {
            setParentItem(null);
        }
        setCurrItem(item);
        setIsEditing(true);  
        setSelectedItemId("id"+item.id);
    }

    function onCloseEdit(isEdited: boolean, from: string): void {
        setIsEditing(false);
        if(isEdited) {            
            if(from === "") {
                const _name = metaName;
                getMetaList("1", _name);
            } else if(from === "H") {
                getDescendantsList(selectedMeta);
            } 
        }

        setSelectedItemId(null);

        setTimeout(() => {
            const element = document.getElementById(selectedItemId);
            // logger.log("onCloseEdit", currItem, "id"+currItem.id, element);
            element?.scrollIntoView({
              behavior: 'auto',
              block: "start",
              inline: "nearest",
            }); 

            setTimeout(() => {
                const position = window.pageYOffset - 0; //84
                logger.log("pageOffset", window.pageXOffset, position);
                window.scrollTo({
                    left: window.pageXOffset,
                    top: position,
                });
            }, 0);
        }, 0);

    }

    function onClickName(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, item: common.IMeta): void {
        const _type = metaType;
        if(_type !== "H") {
            return;
        }
        if(metaName === "curriBook") {
            setSelectedMeta(item);
            getDescendantsList(item);
        } else if(metaName === "studyMap") {
            setSelectedMeta(item);
            const currBrand = util.getCurrentBrand();
            if(currBrand && currBrand.code === "VAET01") {
                setViewStudyMapEng(true);
            } else {
                setViewStudyMap(true);
            }
        } else if(metaName === "templateDiv") {
            setSelectedMeta(item);
            getDescendantsList(item);
        } else if(metaName === "contentsItem") {
            setSelectedMeta(item);
            setViewContentsItem(true);
        } else if(metaName === "achievementStandard") {
            setSelectedMeta(item);
            setViewAchievementStandard(true);
        } else if(metaName === "material") {
            setSelectedMeta(item);
            setViewMaterial(true);
        } else if(metaName === "communication") {
            setSelectedMeta(item);
            setViewCommunication(true);
        } else if(metaName === "languageFormat") {
            setSelectedMeta(item);
            setViewLanguageFormat(true);
        } else if(metaName === "evaluationCommu") {
            setSelectedMeta(item);
            setViewEvaluationCommu(true);
        } else if(metaName === "serviceMap") {
            setSelectedMeta(item);
            setViewServiceMap(true);
        }

        //|| metaName === "material" || metaName === "communication" || metaName === "languageFormat")
    }

    function onSearch(): void {

        const MM_Year = document.getElementById("MM_Year") as HTMLSelectElement;
        const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
        const MM_Unit = document.getElementById("MM_Unit") as HTMLSelectElement;
        const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value
        const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;

        const data : ISearchData = {
            year: MM_Year ? MM_Year.value : "",
            school: MM_School ? MM_School.value : "",
            unit: MM_Unit ? MM_Unit.value : "",
            keyword: searchKeyword,
            subject: MM_Subject ? MM_Subject.value : "",
        }
        logger.log("onSearch", data);
        setSearchData(data);
        _onSearch(data);            
    }

    async function _onSearch(data: ISearchData) {

        const currBrand = util.getCurrentBrand();

        if(data.school === "" && data.year === "" && data.subject === "" && data.keyword === "") {
            getMetaList("1", metaName);
        } else {
            props.showLoading(true);
            if(metaName === "studyMap") {
                const list = await util.searchStudyMap(data);
                const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
                const newList = _list.filter((meta) => meta.brand_id === currBrand?.id);
                logger.log("searchStudyMap", data, newList);
                // setMetaList(newList);        

                const activeList = newList.filter((meta) => meta.is_active);
                const nonActiveList = newList.filter((meta) => !meta.is_active);
        
                setMetaList(activeList.concat(nonActiveList));

            } else if(metaName === "curriBook") {
                const list = await util.searchCurriBook(data);
                const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
                const newList = _list.filter((meta) => meta.brand_id === currBrand?.id);
                logger.log("searchCurriBook", data, newList);
                // setMetaList(newList);        

                // const list = await util.searchCurriBook(data);
                // let _list = list.filter((meta) => meta.brand_id === currBrand?.id);
                // logger.log("searchCurriBook", _list);
                
                // if(data.year) {
                //     _list = _list.filter((meta) => meta.val2 === data.year);
                // }
                // logger.log("searchCurriBook", _list);

                const activeList = newList.filter((meta) => meta.is_active);
                const nonActiveList = newList.filter((meta) => !meta.is_active);
        
                setMetaList(activeList.concat(nonActiveList));

            } else if(metaName === "evaluationArea") {

                const MM_Year = document.getElementById("MM_Year") as HTMLSelectElement;
                const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
        
                const searchKeyword = document.getElementById("searchKeyword") as HTMLInputElement;        
                const searchType = document.getElementById("searchType") as HTMLInputElement;
        
                let search = "";
                if(searchKeyword) {
                    search = searchKeyword.value;
                }
                let searchField = "";
                if(searchType) {
                    searchField = searchType.value;
                }
        
                const metaMap : IMeta[] = [];
                if(MM_Year.selectedIndex > 0) {
                    const meta = curriInfo?.curriYear[MM_Year.selectedIndex - 1];
                    if(meta) {
                        metaMap.push(meta);
                    }
                }
                if(MM_School.selectedIndex > 0) {
                    const meta = curriInfo?.curriSchool[MM_School.selectedIndex - 1];
                    if(meta) {
                        metaMap.push(meta);
                    }
                }
                            
                const data = {
                    "brand_id": util.getCurrentBrand()?.id,
                    "name": "evaluationArea",
                    "depth": "1",
                    "metaMap": metaMap,
                    "is_active": true,
                    "search": search === "" ? undefined : search,
                    "searchField": search === "" ? undefined : searchField,    
                }
        
                logger.log("onSearch", data);
                setSearchData(data);
                        
                const list = await util.searchMeta(data);
                if(list) {
                    const _list = (list as IMeta[]).sort((a, b) => a.id! < b.id! ? 1 : -1);	
                    setMetaList(_list);    
                    logger.log("onSearch", data, _list);
                }
            }

            props.showLoading(false);
        }
    }

    function onChangeTree(item: IMeta) {
        const _metaHList = JSON.parse(JSON.stringify(metaHList));
        const _item = (_metaHList as IMeta[]).find((meta) => meta.id === item.id);
        if(_item) {            
            _item.isOpened = !item.isOpened;
            setMetaHList(_metaHList);
        }                    
    }

    let parents : IMeta[] = [];
    function getParents(item: IMeta, start: boolean) {
        if(start) {
            parents = [];
        }
        const parent = metaHList.find((meta) => meta.id === item.parent_id);
        if(parent) {
            parents.push(parent);
            getParents(parent, false);
        }
    }

    function onDownload() {
        const _metaList = metaList.filter((meta) => meta.is_active === true);
        onDownload2File(_metaList);
    }

    async function onDownload2File(metas: IMeta[]) {

        props.showLoading(true);

        const column = [
        "브랜드",
        "교과과정(key)",
        "교과과정(val)",
        "학교급(key)",
        "학교급(val)",
        "교과(key)",
        "교과(val)",
        "학년(key)",
        "학년(val)",
        "단원1(key)",
        "단원1(val)",
        "단원2(key)",
        "단원2(val)",
        "단원3(key)",
        "단원3(val)",
        "단원4(key)",
        "단원4(val)",
        "단원5(key)",
        "단원5(val)"];

        const checkedList = [];
        checkedList.push(column);

        for (let j = 0; j < metas.length; j++) {

            const excelData = [];
            for (let i = 0; i < column.length; i++) {
                excelData[i] = "";
            }

            const curriBook = metas[j];
            
            if(curriBook) {

                const curriBookInfo = await util.getMetaOfMetaExById(String(curriBook.id));
                // logger.log("curriBookInfo", curriBookInfo);

                const curriUnits = await util.getDescendantsList(curriBook.code);
            
                for (let k = 0; k < curriUnits.length; k++) {

                    const curriUnit = curriUnits[k];

                    let curriUnit1K = curriUnit.name === "curriUnit1" ? curriUnit.id : "";
                    let curriUnit2K = curriUnit.name === "curriUnit2" ? curriUnit.id : "";
                    let curriUnit3K = curriUnit.name === "curriUnit3" ? curriUnit.id : "";
                    let curriUnit4K = curriUnit.name === "curriUnit4" ? curriUnit.id : "";
                    let curriUnit5K = curriUnit.name === "curriUnit5" ? curriUnit.id : "";

                    let curriUnit1Val = curriUnit.name === "curriUnit1" ? curriUnit.val : "";
                    let curriUnit2Val = curriUnit.name === "curriUnit2" ? curriUnit.val : "";
                    let curriUnit3Val = curriUnit.name === "curriUnit3" ? curriUnit.val : "";
                    let curriUnit4Val = curriUnit.name === "curriUnit4" ? curriUnit.val : "";
                    let curriUnit5Val = curriUnit.name === "curriUnit5" ? curriUnit.val : "";

                    // if(curriUnit.name === "curriUnit1") {
                    //     curriUnit1Val = curriUnit.val";
                    // } else if(curriUnit.name === "curriUnit2") {
                    //     curriUnit1Val = curriUnits.find((meta) => meta.id ===  curriUnit.parent_id curriUnit.val";
                    //     curriUnit2Val = curriUnit.val";
                    // } else if(curriUnit.name === "curriUnit3") {
                    //     curriUnit3Val = curriUnit.val";
                    // } else if(curriUnit.name === "curriUnit4") {
                    //     curriUnit4Val = curriUnit.val";
                    // } else if(curriUnit.name === "curriUnit5") {
                    //     curriUnit5Val = curriUnit.val";
                    // }

                    const curriSchool = curriBookInfo.find((meta) => meta.name === "curriSchool");
                    const curriSubject = curriBookInfo.find((meta) => meta.name === "curriSubject");
                    const curriGrade = curriBookInfo.find((meta) => meta.name === "curriGrade");

                    excelData[0] = util.getCurrentBrand()?.id;
                    excelData[1] = curriBook.id;
                    excelData[2] = curriBook.val;
                    excelData[3] = curriSchool ? curriSchool.id : "";
                    excelData[4] = curriSchool ? curriSchool.val : "";
                    excelData[5] = curriSubject ? curriSubject.id : "";
                    excelData[6] = curriSubject ? curriSubject.val : "";
                    excelData[7] = curriGrade ? curriGrade.id : "";
                    excelData[8] = curriGrade ? curriGrade.val : "";
                    excelData[9] = curriUnit1K;
                    excelData[10] = curriUnit1Val;
                    excelData[11] = curriUnit2K;
                    excelData[12] = curriUnit2Val;
                    excelData[13] = curriUnit3K;
                    excelData[14] = curriUnit3Val;
                    excelData[15] = curriUnit4K;
                    excelData[16] = curriUnit4Val;
                    excelData[17] = curriUnit5K;
                    excelData[18] = curriUnit5Val;
                            
                    checkedList.push(JSON.parse(JSON.stringify(excelData)));                    
                }
            }
        }

        logger.log("checkedList", checkedList);
        exportToExcel(checkedList);            

        props.showLoading(false);
    }

    const exportToExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        const today = new Date();
        saveAs(blob, "downloaded_data_" + today.toISOString() + ".xlsx");
    };

    return (
    <div>

        {/* <Latex>{"$\\notin$"}</Latex> */}

        <div className="top-title">
            <h3>{metaTitle + "(" + metaName + ")"}</h3>
        </div>

        {/* <!-- 검색영역 --> */}
        {!viewHList && !isEditing && !isBulkEditing && !viewStudyMap && !viewStudyMapEng && (metaName === "curriBook" || metaName === "studyMap" || (metaName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01" )) &&
        <div className="pl-4 py-8 mb-8 border border-gray-300 rounded-xl">
            <div className="pl-4">
                <fieldset>
                {/* <label htmlFor="sel-title">{metaName === "curriBook" ? "학교급" : "교과목"}</label> */}
                    
                    {metaName === "curriBook" && <>
                    <label htmlFor="sel-title">{"교육과정 년도"}</label>
                    <select id="MM_Year">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriYear && (curriInfo.curriYear as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select>                        
                    <label className="ml-2" htmlFor="sel-title">{"학교급"}</label>
                    <select id="MM_School">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select></>}
                    {metaName === "studyMap" && <>
                    <label className="ml-2" htmlFor="sel-title">{"학교급"}</label>
                    <select id="MM_School">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select></>}

                    {metaName === "evaluationArea" && <>
                    <label htmlFor="sel-title">{"교육과정 년도"}</label>
                    <select id="MM_Year">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriYear && (curriInfo.curriYear as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select>                        
                    <label className="ml-2" htmlFor="sel-title">{"학교급"}</label>
                    <select id="MM_School">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select>

                    <label htmlFor="sel-meta" className="ml-2">검색</label>
                    <select id="searchType">
                        <option value={"val"}>타이틀</option>
                        <option value={"id"}>KEY</option>
                        <option value={"creator"}>등록자</option>
                        <option value={"code"}>코드</option>
                    </select>
                    <input type="text" name="search" id="searchKeyword" className="w-[300px] ml-2 mr-2" onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            onSearch();
                        }
                    }} /></>}

                    {/* {metaName === "studyMap" &&
                    <select id="MM_Subject">
                        <option value="">전체</option>
                        {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                            return(
                                <option key={idx} value={item.val}>{item.val}</option>              
                        )})}
                    </select>} */}
                    
                    {(metaName === "curriBook" || metaName === "studyMap") && <>

                    <label htmlFor="sel-meta" className="ml-3">메타 값</label>
                    <select id="MM_Unit">
                        <option value="">전체</option>
                        <option value="curriUnit1">1Depth</option>
                        <option value="curriUnit2">2Depth</option>
                        <option value="curriUnit3">3Depth</option>
                        <option value="curriUnit4">4Depth</option>
                        <option value="curriUnit5">5Depth</option>
                    </select>
                    <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            onSearch();
                        }
                    }} /></>}

                    <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                        <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                    </button>

                </fieldset>
            </div>
        </div>}
        {/* <!-- .//검색영역 --> */}

        {!viewHList && !isEditing && !isBulkEditing && viewList && 
        !viewStudyMap && 
        !viewContentsItem && 
        !viewAchievementStandard && 
        !viewMaterial && 
        !viewCommunication && 
        !viewLanguageFormat && 
        !viewStudyMapEng && 
        !viewEvaluationCommu && 
        !viewServiceMap &&

        <div>
            <table>
                <caption className="sr-only">{title}</caption>
                <thead>
                    <tr>
                        <th scope="col" className="pl-4 pr-3" style={{width:"80px"}}>번호</th>
                        <th scope="col" className="px-3">메타 값</th>
                        {metaName === "curriBook" &&
                        <th scope="col" className="px-3">학교급</th>}
                        {metaName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01" && <>
                        <th scope="col" className="px-3">교육과정 연도</th>
                        <th scope="col" className="px-3">학교급</th></>}
                        <th scope="col" className="px-3">Key</th>
                        <th scope="col" className="px-3">코드</th>
                        <th scope="col" className="px-3">설명</th>
                        <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                        <th scope="col" className="px-3" style={{width:"100px"}}>등록자</th>
                        <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                    </tr>
                </thead>

                <tbody>
                    {viewList && metaList && (metaList as IMeta[]).length === 0 &&
                    <tr>
                        <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                    </tr>}

                    {metaList && (metaList as IMeta[]).map((item, idx) => {
                        const type = metaType ? metaType : "";
                        const bLinkCls = item.name === "curriBook" || item.name === "studyMap" ? true : false;

                        const curriSchool = item.val1 ? item.val1 : "";
                        const curriYear = item.val2 ? item.val2 : "";

                        return (
                            <tr key={idx} onClick={(e) => onClickName(e, item)} style={{cursor: type === "H" ? "pointer" : "default", backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}>
                                <td>{metaList.length-idx}</td>
                                {bLinkCls && <td><a href="#" className="link">{item.val}</a></td>}
                                {!bLinkCls && <td>{item.val}</td>}
                                {metaName === "curriBook" &&
                                <td className={"text-center"}>{curriSchool}</td>}
                                {metaName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01" && <>
                                <td className={"text-center"}>{curriYear}</td>
                                <td className={"text-center"}>{curriSchool}</td></>}
                                <td className={"text-center"}>{item.id}</td>
                                <td>{item.code}</td>
                                <td className="text-left"><p className="xl:w-80 truncate">{item.description}</p></td>
                                <td>{item.is_active ? "사용" : "미사용"}</td>
                                <td className="leading-4">{item.creator}</td>
                                <td>
                                    <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div className="relative mt-5 mb-5">
                <div className="flex">
                    <div className="absolute top-0 right-0 text-right">
                        {isDev && !viewHList && bulkUpdate && 
                        <button type="button" className="btn-sky mr-2" onClick={() => {
                            onDownload();
                        }}>다운로드</button>}
                        {isDev && !viewHList && bulkUpdate && 
                        <button type="button" className="btn-sky mr-2" onClick={() => {
                            setIsBulkEditing(true);
                        }}>일괄등록</button>}
                        <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                    </div>
                </div>
            </div>

        </div>}

        {viewHList && !isEditing && !isBulkEditing && viewList && 
        !viewStudyMap && 
        !viewContentsItem && 
        !viewAchievementStandard && 
        !viewMaterial && 
        !viewCommunication && 
        !viewLanguageFormat && 
        !viewStudyMapEng && 
        !viewEvaluationCommu &&
        
        <div style={{width: "91rem", paddingRight: "3rem"}}>

            <div style={{display: isEditing ? "none" : ""}}>
                <table>
                    <caption className="sr-only">{title}</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="" style={{width: metaName === "curriBook" ? "40rem" : "20rem"}}>메타 값</th>
                            <th scope="col" className="" style={{width: "5rem"}}>Key</th>
                            <th scope="col" className="" style={{width: "17rem"}}>코드</th>
                            <th scope="col" className="" style={{width:"5rem"}}>사용</th>
                            <th scope="col" className="" style={{width:"7rem"}}>추가</th>
                            <th scope="col" className="" style={{width:"7rem"}}>등록자</th>
                            <th scope="col" className="" style={{width:"7rem"}}>상세보기</th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div className="overflow-y-auto index-scroll" style={{display: isEditing ? "none" : "", height: "42rem"}}>

                <table>

                    {/* <caption className="sr-only">{title}</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="px-3">메타 값</th>
                            <th scope="col" className="px-3">Key</th>
                            <th scope="col" className="px-3">코드</th>
                            <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                            <th scope="col" className="px-3" style={{width:"80px"}}>추가</th>
                            <th scope="col" className="px-3" style={{width:"100px"}}>등록자</th>
                            <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                        </tr>
                    </thead> */}

                    <tbody>

                        <tr>
                            <td className="" style={{width: metaName === "curriBook" ? "40rem" : "20rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width: "5rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width: "17rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"5rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                        </tr>

                        {metaHList && (metaHList as IMeta[]).length === 0 &&
                        <tr>
                            <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                        </tr>}

                        {metaHList && (metaHList as IMeta[]).map((item, idx) => {
                            let _code = item.code;
                            const arrCode = item.code.split("-");
                            // _code = arrCode[arrCode.length-1];
                            _code = _code.replace(arrCode[0]+"-", "");

                            let clsName = "";
                            if(item.name === "curriBook") {
                                clsName = "";
                            } else if(item.name === "curriUnit1") {
                                clsName = "pl-5";
                            } else if(item.name === "curriUnit2") {
                                clsName = "pl-10";
                            } else if(item.name === "curriUnit3") {
                                clsName = "pl-15";
                            } else if(item.name === "curriUnit4") {
                                clsName = "pl-20";
                            } else if(item.name === "curriUnit5") {
                                clsName = "pl-25";
                            } else if(item.name === "curriUnit6") {
                                clsName = "pl-30";
                            }
                            
                            const maxDepth = selectedMeta.max_depth ? selectedMeta.max_depth : 5;
                            const bAdd = maxDepth >= item.depth;

                            let view = true;                
                            getParents(item, true);
                            for (let i = 0; i < parents.length; i++) {
                                const parent = parents[i];    
                                if(!parent.isOpened) {
                                    view = false;
                                    break;
                                }
                            }
                            // logger.log("=>", item.id, item.code, view);
    
                        return (
                            <tr key={idx} id={"id"+item.id} style={{display: view ? "" : "none", backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}>
                            {/* <tr key={idx} style={{backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}> */}
                                {/* <td className="w-20">{metaHList.length-idx}</td> */}
                                <td className="text-left">                                
                                    <span className={clsName} onClick={() => {
                                            if(item.hasChild) {
                                                onChangeTree(item);
                                            }
                                    }} style={{cursor: "pointer"}} >
                                        <button className="mr-2" type="button" style={{width: "1rem"}} >
                                            {item.hasChild && item.isOpened &&
                                            <img src="./images/arrow-up.png" alt="arrow-up" style={{width: "0.95rem"}} />}
                                            {item.hasChild && !item.isOpened &&
                                            <img src="./images/arrow-right.png" alt="arrow-right" style={{width: "0.75rem"}} />}
                                        </button>
                                        {item.val}{/* <Latex delimiters={latex_delimiters} macros={latex_macros}>{name}</Latex> */}
                                    </span>
                                </td>
                                {/* <td className="text-left"><p className={clsName}>{item.val}</p></td> */}
                                <td className={"text-center"}>{item.id}</td>
                                <td className="text-left">{_code}</td>
                                <td>{item.is_active ? "사용" : "미사용"}</td>
                                <td className="w-24">
                                    {bAdd &&
                                    <button type="button" className="btn-white-s" onClick={(e) => onAddChildMeta(e, item)}>추가</button>}
                                </td>
                                <td className="leading-4">{item.creator}</td>
                                <td className="w-24">
                                    <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>
                                </td>
                            </tr>
                        )
                        })}
                    </tbody>   
                </table>                 

                <div className="relative mt-5 mb-5">
                    <div className="flex">
                        <button type="button" className="btn-white mr-2" onClick={() => {
                            setViewHList(false);
                        }}>목록</button>
                        <button type="button" className="btn-white" onClick={onAddMetaToCurrent}>추가<br/>(1Depth)</button>

                        <div className="absolute top-0 right-0 text-right">
                            <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>}
                
            
        {isEditing && 
            <MetaAddEdit brand={props.brand} menuName={metaName} item={currItem} parentItem={parentItem} onCloseEdit={onCloseEdit} showModal={props.showModal} showLoading={props.showLoading} />
        }

        {isBulkEditing &&
            <MetaBulkRegister OnClose={() => {
                setIsBulkEditing(false);
            }} brand={props.brand} menuName={metaName} showModal={props.showModal} showLoading={props.showLoading} />}

        {viewStudyMap && 
            <MetaStudyMap brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewStudyMap(false);
            }} />
        }

        {viewStudyMapEng && 
            <MetaStudyMapEng brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewStudyMapEng(false);
            }} />
        }

        {viewContentsItem && 
            <MetaContentsItem brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewContentsItem(false);
            }} />
        }

        {viewAchievementStandard && 
            <MetaAchievementStandard brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewAchievementStandard(false);
            }} />
        }

        {viewMaterial && 
            <MetaMaterial brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewMaterial(false);
            }} />
        }

        {viewCommunication && 
            <MetaCommunication brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewCommunication(false);
            }} />
        }

        {viewLanguageFormat && 
            <MetaLanguageFormat brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewLanguageFormat(false);
            }} />
        }

        {viewEvaluationCommu && 
            <MetaEvaluationComm brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewEvaluationCommu(false);
            }} />
        }

        {viewServiceMap && 
            <MetaServiceMap brand={props.brand} menu={props.menu} item={selectedMeta} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewServiceMap(false);
            }} />
        }

    </div>
    );
}

export default MetaManager;
