import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';
import PopupCurriBookSearch from "./PopupCurriBookSearch";
import { IEngMeta } from "./MetaStudyMapEngEdit";

interface IMetaInfo {
	menuName: string;
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaAddEdit(props: IMetaInfo) {

    const [UUID, setUUID] = useState("");
    const [contentArea, setContentArea] = useState<IMeta[]>([]);
    const [unitGroup, setUnitGroup] = useState<IMeta[]>([]);
    const [studyMap, setStudyMap] = useState<IMeta[]>([]);

    const [teachingSubject, setTeachingSubject] = useState<IMeta[]>([]);

    const [selectedCurriBooks, setSelectedCurriBooks] = useState<IMeta[]>([]);
    const [viewCurriBookSearch, setViewCurriBookSearch] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }    

        if(!bDone) {

            if(props.menuName === "evaluationArea" && props.brand.code === "VAET01") {
                getContentArea();
            }
            if(props.menuName === "curriBook" && props.brand.code === "VIVA00") {
                getUnitGroup();
            }
            if(props.menuName === "evaluationArea" && props.brand.code === "VIVA01") {
                // getTeachingSubject();
                onChangeForCurriSubject();
            }    
            if(props.menuName === "serviceMap") {
                getStudyMap();
            }
    
            (document.getElementById('active') as HTMLInputElement).checked = true;
            (document.getElementById('non_active') as HTMLInputElement).checked = false;
    
            if(props.item) {
                if(!props.item.is_active) {
                    (document.getElementById('active') as HTMLInputElement).checked = false;
                    (document.getElementById('non_active') as HTMLInputElement).checked = true;
                }
                (document.getElementById('meta_val') as HTMLInputElement).value = props.item.val;
                (document.getElementById('meta_description') as HTMLInputElement).value = props.item.description ? props.item.description : "";
                (document.getElementById('meta_code') as HTMLInputElement).value = props.item.code;
                // (document.getElementById('meta_code') as HTMLInputElement).readOnly = (props.menuName === "curriBook" || props.menuName === "studyMap" || props.menuName === "templateDiv") ? true : false;
                // (document.getElementById('meta_code') as HTMLInputElement).disabled = (props.menuName === "curriBook" || props.menuName === "studyMap" || props.menuName === "templateDiv") ? true : false;
                (document.getElementById('meta_code') as HTMLInputElement).readOnly = true;
                (document.getElementById('meta_code') as HTMLInputElement).disabled = true;
                const maxDepth = (document.getElementById('max_depth') as HTMLSelectElement);
                if(maxDepth) {
                    maxDepth.value = props.item.max_depth ? String(props.item.max_depth) : "";
                }

                if(props.menuName === "studyMap") {
                    const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
                    if(curriBook) {
                        // curriBook.disabled = true;
                    }    
                }

                if(props.menuName === "curriBook") {
                    // logger.log("curriBook", props.item.id);   
                    // getStudyMap();                 
                    // props.item.isUsingStudyMap = 
                }

                setUUID(props.item.uuid ? props.item.uuid : "");    
                getMetaOfMetaExById(String(props.item.id));
                
            } else {
                const { v4: uuidv4 } = require('uuid');            
                const uuid = uuidv4();
                setUUID(uuid);    
            }  
            (document.getElementById('meta_val') as HTMLInputElement).focus();
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(contentArea && contentArea.length > 0) {
            if(props.item) {
                getMetaOfMetaExById(String(props.item.id));
            }
        }
    }, [contentArea])

    useEffect(() => {
        if(unitGroup && unitGroup.length > 0) {
            if(props.item) {
                getMetaOfMetaExById(String(props.item.id));
            }    
        }
    }, [unitGroup])

    // useEffect(() => {
    //     if(teachingSubject && teachingSubject.length > 0) {
    //         if(props.item) {
    //             getMetaOfMetaExById(String(props.item.id));
    //         }    
    //     }
    // }, [teachingSubject])

    useEffect(() => {
        if(studyMap && studyMap.length > 0) {
            if(props.item) {
                getMetaOfMetaExById(String(props.item.id));
            }
        }
    }, [studyMap])

    async function getContentArea() {

        props.showLoading(true);

        const list = await util.getMetaList("1", "contentArea");
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        logger.log("getContentArea", _list);
        setContentArea(_list);
        
        props.showLoading(false);
    }

    async function getUnitGroup() {

        props.showLoading(true);

        const list = await util.getMetaList("1", "unitGroup");
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        logger.log("getUnitGroup", _list);
        setUnitGroup(_list);
        
        props.showLoading(false);
    }

    async function getTeachingSubject() {

        props.showLoading(true);

        const list = await util.getMetaList("1", "teachingSubject");
        if(list) {
            const _list = list.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            logger.log("getTeachingSubject", _list);
            setTeachingSubject(_list);    
        }
        
        props.showLoading(false);
    }

    async function getStudyMap() {

        props.showLoading(true);

        const listAll = await util.getMetaList("1", "studyMap");
        const _sortedStudyMap = listAll.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const _studyMap = _sortedStudyMap.filter((item) => item.is_active === true);

        logger.log("getStudyMap", _studyMap);
        setStudyMap(_studyMap);
        
        props.showLoading(false);
    }

    async function getMetaOfMetaExById(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _studyMap = list.find((meta, _) => meta.name === "studyMap");
        if(_studyMap) {
            const maxDepth = (document.getElementById('max_depth') as HTMLSelectElement);
            if(maxDepth) {
                maxDepth.disabled = true;
            }
        }    

        const curriYear = document.getElementById("meta_curriYear") as HTMLSelectElement;
        if(curriYear) {
            const _curriYear = list.find((meta, _) => meta.name === "curriYear");
            if(_curriYear) {
                curriYear.value = _curriYear.val;
            }    
        }
        const curriSchool = document.getElementById("meta_curriSchool") as HTMLSelectElement;
        if(curriSchool) {
            const _curriSchool = list.find((meta, _) => meta.name === "curriSchool");
            if(_curriSchool) {
                curriSchool.value = _curriSchool.val;
            }    
        }
        const curriGrade = document.getElementById("meta_curriGrade") as HTMLSelectElement;
        if(curriGrade) {
            const _curriGrade = list.find((meta, _) => meta.name === "curriGrade");
            if(_curriGrade) {
                curriGrade.value = _curriGrade.val;
            }
        }
        const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
        if(curriSubject) {
            const _curriSubject = list.find((meta, _) => meta.name === "curriSubject");
            if(_curriSubject) {
                curriSubject.value = _curriSubject.val;
            }
        }
        const curriSemester = document.getElementById("meta_curriSemester") as HTMLSelectElement;
        if(curriSemester) {
            const _curriSemester = list.find((meta, _) => meta.name === "curriSemester");
            if(_curriSemester) {
                curriSemester.value = _curriSemester.val;
            }
        }
        const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
        if(curriBook) {
            const _curriBook = list.find((meta, _) => meta.name === "curriBook");
            if(_curriBook) {
                curriBook.value = _curriBook.val;
            }    
        }
        const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
        if(meta_contentArea) {
            const _contentArea = list.find((meta, _) => meta.name === "contentArea");
            if(_contentArea) {
                meta_contentArea.value = _contentArea.val;
            }
        }                
        const meta_unitGroup = document.getElementById("meta_unitGroup") as HTMLSelectElement;
        if(meta_unitGroup) {
            const _unitGroup = list.find((meta, _) => meta.name === "unitGroup");
            if(_unitGroup) {
                meta_unitGroup.value = _unitGroup.val;
            }
        }                
        const meta_serviceType = document.getElementById("meta_serviceType") as HTMLSelectElement;
        if(meta_serviceType) {
            const _serviceType = list.find((meta, _) => meta.name === "serviceType");
            if(_serviceType) {
                meta_serviceType.value = _serviceType.val;
            } else {
                meta_serviceType.value = "";
            }
        }                
        const meta_teachingSubject = document.getElementById("meta_teachingSubject") as HTMLSelectElement;
        if(meta_teachingSubject) {
            const _teachingSubject = list.find((meta, _) => meta.name === "teachingSubject");
            if(_teachingSubject) {
                meta_teachingSubject.value = _teachingSubject.val;
            }
        }          
        
        if((props.menuName === "evaluationType" && props.brand.code === "VIVA01") && props.parentItem === null) {
            const curriBooks = list.filter((meta, _) => meta.name === "curriBook");
            setSelectedCurriBooks(curriBooks);
            // curriBook.selectedIndex = 0;
        }

        const meta_studyMap = document.getElementById("meta_studyMap") as HTMLSelectElement;
        if(meta_studyMap) {
            const _studyMap = list.find((meta, _) => meta.name === "studyMap");
            if(_studyMap) {
                meta_studyMap.value = _studyMap.val;
            }
        }          

        onChangeForCurriSubject(list);
    }

    async function onChangeForCurriSubject(metalist: IMeta[] | undefined = undefined) {
        
        logger.log("onChangeForCurriSubject", props.menuName, util.getCurrentBrand()?.code);

        if((props.menuName === "curriBook" && props.parentItem === null && util.getCurrentBrand()?.code === "VIVA01") 
            || (props.menuName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01")) {
            
            const brand = util.getCurrentBrand();

            const metaMap: {"name": string, "id": number}[] = [];
    
            const data = {
                "brand_id": brand?.id,
                "name":"teachingSubject",
                "is_active": true,
                "metaMap": metaMap,
            }
    
            const meta_curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            if(meta_curriSubject) {
                const _curriSubject = curriInfo?.curriSubject.find((meta) => meta.val === meta_curriSubject.value)
                if(_curriSubject) {
                    metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
                }
            }
    
            data.metaMap = metaMap;
            
            props.showLoading(true);
    
            const list = await util.searchMeta(data);
            if(list) {
                setTeachingSubject(list);
                setTimeout(() => {
                    const meta_teachingSubject = document.getElementById("meta_teachingSubject") as HTMLSelectElement;
                    if(meta_teachingSubject) {
                        const _teachingSubject = metalist?.find((meta, _) => meta.name === "teachingSubject");
                        if(_teachingSubject) {
                            meta_teachingSubject.value = _teachingSubject.val;
                        } else {
                            meta_teachingSubject.selectedIndex = 0;
                        }
                    }                      
                }, 1);
            }
    
            logger.log("onChangeForCurriSubject", data, list);
    
            props.showLoading(false);
    
            return list;
        }
    }

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function addStudyMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.addStudyMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function insertMeta(data: IMeta[]) {

        props.showLoading(true);
        
        const result = await util.insertMeta(data);
        if(props.parentItem) {
            props.onCloseEdit(true, "H");
        } else {
            props.onCloseEdit(true, "");
        }

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMeta(data: IMeta) {

        props.showLoading(true);
        
        const result = await util.updateMeta(data);
        if(props.parentItem) {
            props.onCloseEdit(true, "H");
        } else {
            props.onCloseEdit(true, "");
        }

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function onSave() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        const val = (document.getElementById('meta_val') as HTMLInputElement).value
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked
        const maxDepth = (document.getElementById('max_depth') as HTMLSelectElement) ? (document.getElementById('max_depth') as HTMLSelectElement).value : undefined;

        if(val === "") {
            props.showModal("", "메타값을 입력해 주세요.");
            return;
        }
        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        const parentItem = props.parentItem;
        logger.log('parentItem', parentItem);

        let _name = props.menuName;
        let _depth = 1;
        let _code = code;
        let _parent_id = 0;
        if(parentItem) {

            let arr = [];

            if(parentItem.name === "templateDiv") {
                arr = ["templateDiv1", "templateDiv2", "templateDiv3", "templateDiv4", "templateDiv5"];
            } else {
                arr = ["curriUnit1", "curriUnit2", "curriUnit3", "curriUnit4", "curriUnit5"];
            }
    
            _name = arr[parentItem.depth - 1];
            _depth = parentItem.depth + 1;
            _code = props.item ? code : parentItem.code + "-" + code;
            _parent_id = parentItem.id!;
        // } else {
        //     var regType1 = /^[A-Za-z0-9]+$/;
        //     if (!regType1.test(code)) { 
        //         props.showModal("", "code는 영문자, 숫자만 가능합니다.");
        //         return;
        //     }    
        }

        //code check
        if(props.item === null) {
            const newCode = (curriInfo?.all as IMeta[]).find((item) => item.code === _code);
            if(newCode) {
                props.showModal("", "중복된 코드가 사용되었습니다.");
                (document.getElementById('meta_code') as HTMLInputElement).focus();
                (document.getElementById('meta_code') as HTMLInputElement).select();
                return;
            }    
        }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const meta: IMeta = {
            id: props.item ? props.item.id : undefined,

            code: _code,
            name: _name,
            val: val,
            depth: _depth,
            parent_id: _parent_id,
            is_active: is_active ? true : false,
            creator: props.item ? undefined : uid,
            creator_id: props.item ? undefined : _id,
            creator_name: props.item ? undefined : uname,
            description: description,
            brand_id: brand.id ? brand.id : null,

            updater: props.item ? uid : undefined,
            updater_id: props.item ? _id : undefined,
            updater_name: props.item ? uname : undefined,

            max_depth: maxDepth ? Number(maxDepth) : undefined,
            
            uuid: props.item ? props.item.uuid : UUID,
        }
    
        let list: IMeta[] = [];
        list.push(meta);
        logger.log("onSave", list);

        if(props.menuName === "curriBook" && props.parentItem === null) {

            const curriYear = document.getElementById("meta_curriYear") as HTMLSelectElement;
            const curriSchool = document.getElementById("meta_curriSchool") as HTMLSelectElement;
            const curriGrade = document.getElementById("meta_curriGrade") as HTMLSelectElement;
            const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            const curriSemester = document.getElementById("meta_curriSemester") as HTMLSelectElement;
                
            const curriYear_val = curriInfo?.curriYear[curriYear.selectedIndex] as IMeta;
            const curriSchool_val = curriInfo?.curriSchool[curriSchool.selectedIndex] as IMeta;
            const curriGrade_val = curriInfo?.curriGrade[curriGrade.selectedIndex] as IMeta;
            const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex] as IMeta;
            const curriSemester_val = curriInfo?.curriSemester[curriSemester.selectedIndex] as IMeta;

            let meta_mapping_meta : IMeta[] = [];
            meta_mapping_meta.push(curriYear_val)
            meta_mapping_meta.push(curriSchool_val)
            meta_mapping_meta.push(curriGrade_val)
            meta_mapping_meta.push(curriSubject_val)
            meta_mapping_meta.push(curriSemester_val)
  
            if(brand && brand.code === "VIVA00") {
                const meta_unitGroup = document.getElementById("meta_unitGroup") as HTMLSelectElement;
                const unitGroup_val = unitGroup[meta_unitGroup.selectedIndex] as IMeta;
                meta_mapping_meta.push(unitGroup_val)
            }

            const meta_teachingSubject = document.getElementById("meta_teachingSubject") as HTMLSelectElement;
            if(meta_teachingSubject && meta_teachingSubject.selectedIndex > 0) {
                const teachingSubject_val = teachingSubject[meta_teachingSubject.selectedIndex-1];
                meta_mapping_meta.push(teachingSubject_val);
            }

            meta.name1 = "curriSchool";
            meta.val1 = curriSchool_val ? curriSchool_val.val : "";

            meta.name2 = "curriYear";
            meta.val2 = curriYear_val ? curriYear_val.val : "";

            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  

                if(props.item.is_active !== is_active) {
                    //교재 is_active 변경시 하위 계증도 변경
                    util.changeActiveStatus(meta.code, is_active);
                }
            }

        } else if(props.menuName === "subjectAbility") {

            const curriYear = document.getElementById("meta_curriYear") as HTMLSelectElement;
            const curriYear_val = curriInfo?.curriYear[curriYear.selectedIndex] as IMeta;
            const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex] as IMeta;
            let meta_mapping_meta : IMeta[] = [];
            meta_mapping_meta.push(curriYear_val)
            meta_mapping_meta.push(curriSubject_val)
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }

        } else if(props.menuName === "contentArea") {

            const curriYear = document.getElementById("meta_curriYear") as HTMLSelectElement;
            const curriSchool = document.getElementById("meta_curriSchool") as HTMLSelectElement;
            const curriYear_val = curriInfo?.curriYear[curriYear.selectedIndex] as IMeta;
            const curriSchool_val = curriInfo?.curriSchool[curriSchool.selectedIndex] as IMeta;
            let meta_mapping_meta : IMeta[] = [];
            meta_mapping_meta.push(curriYear_val)
            meta_mapping_meta.push(curriSchool_val)
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }
        } else if(props.menuName === "evaluationArea") {

            const metamap : IMetaMap = {meta: null as any, metamapList: []}

            let meta_mapping_meta : IMeta[] = [];

            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            if(meta_contentArea) {
                const meta_contentArea_val = contentArea[meta_contentArea.selectedIndex] as IMeta;
                meta_mapping_meta.push(meta_contentArea_val)    
            }

            const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            if(curriSubject) {
                const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex] as IMeta;
                meta_mapping_meta.push(curriSubject_val)    
            }

            let curriSchool_val = undefined;
            const curriSchool = document.getElementById("meta_curriSchool") as HTMLSelectElement;
            if(curriSchool) {
                curriSchool_val = curriInfo?.curriSchool[curriSchool.selectedIndex] as IMeta;
                meta_mapping_meta.push(curriSchool_val)

                meta.name1 = "curriSchool";
                meta.val1 = curriSchool_val ? curriSchool_val.val : "";
            }

            let curriYear_val = undefined;
            const curriYear = document.getElementById("meta_curriYear") as HTMLSelectElement;
            if(curriYear) {
                curriYear_val = curriInfo?.curriYear[curriYear.selectedIndex] as IMeta;
                meta_mapping_meta.push(curriYear_val)
            
                meta.name2 = "curriYear";
                meta.val2 = curriYear_val ? curriYear_val.val : "";
            }

            metamap.meta = meta;
            metamap.metamapList = meta_mapping_meta;

            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }

        } else if(props.menuName === "studyMap" && props.parentItem === null) {

            // const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            // const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex] as IMeta;
            
            const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
            const curriBook_val = curriInfo?.curriBook[curriBook.selectedIndex] as IMeta;

            meta.max_depth = curriBook_val.max_depth;
            if(!meta.max_depth || meta.max_depth < 1) {
                props.showModal("", "교과과정에서 max depth를 넣어 주셔야 합니다.");
                return;
            }
            
            let meta_mapping_meta : IMeta[] = [];
            meta_mapping_meta.push(curriBook_val)
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            meta.name1 = "curriBook";
            meta.val1 = curriBook_val ? curriBook_val.val : "";

            if(curriBook_val) {
                const list = await util.getMetaOfMetaExById(String(curriBook_val.id));
                const _curriSchool = list.find((meta, _) => meta.name === "curriSchool");
    
                meta.name2 = "curriSchool";
                meta.val2 = _curriSchool ? _curriSchool.val : "";    
            }

            if(props.item === null) {
                // insertMetaMetaMap(metamap);  
                addStudyMap(metamap);
            } else {
                updateMetaMetaMap(metamap);  

                if(props.item.is_active !== is_active) {
                    //학습맵 is_active 변경시 하위 계증도 변경
                    util.changeActiveStatus(meta.code, is_active);
                }
            }

        } else if(props.menuName === "contentsItem") {

            const metamap : IMetaMap = {meta: null as any, metamapList: []}

            if(props.brand.code === "VAET01") {
                const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
                const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex] as IMeta;
                let meta_mapping_meta : IMeta[] = [];
                meta_mapping_meta.push(curriSubject_val)

                metamap.meta = meta;
                metamap.metamapList = meta_mapping_meta;

                logger.log("meta_mapping_meta", metamap);
            } else {
            }

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     
        } else if(props.menuName === "evaluationArea3") {

            const metamap : IMetaMap = {meta: null as any, metamapList: []}

            const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex] as IMeta;
            let meta_mapping_meta : IMeta[] = [];
            meta_mapping_meta.push(curriSubject_val)

            metamap.meta = meta;
            metamap.metamapList = meta_mapping_meta;

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     

        } else if((props.menuName === "achievementStandard" || props.menuName === "material" || props.menuName === "communication" || props.menuName === "languageFormat" || props.menuName === "evaluationCommu") && props.parentItem === null) {

            const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
            const curriBook_val = curriInfo?.curriBook[curriBook.selectedIndex] as IMeta;
            
            let meta_mapping_meta : IMeta[] = [];
            meta_mapping_meta.push(curriBook_val)
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     
            
        } else if((props.menuName === "teachingCategory" && props.brand.code === "VIVA01") && props.parentItem === null) {
            let meta_mapping_meta : IMeta[] = [];

            const meta_serviceType = document.getElementById("meta_serviceType") as HTMLSelectElement;
            if(meta_serviceType && meta_serviceType.selectedIndex > 0) {
                const serviceType_val = curriInfo?.serviceType[meta_serviceType.selectedIndex-1] as IMeta;
                meta_mapping_meta.push(serviceType_val);
            }
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     
        } else if((props.menuName === "teachingUsage" && props.brand.code === "VIVA01") && props.parentItem === null) {

            let meta_mapping_meta : IMeta[] = [];

            const meta_serviceType = document.getElementById("meta_serviceType") as HTMLSelectElement;
            if(meta_serviceType && meta_serviceType.selectedIndex > 0) {
                const serviceType_val = curriInfo?.serviceType[meta_serviceType.selectedIndex-1] as IMeta;
                meta_mapping_meta.push(serviceType_val);
            }
            const curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
            if(curriBook) {
                if(curriBook.selectedIndex > 0) {
                    const curriBook_val = curriInfo?.curriBook[curriBook.selectedIndex-1] as IMeta;            
                    meta_mapping_meta.push(curriBook_val);    
                }
            }
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     

        } else if((props.menuName === "teachingSubject" && props.brand.code === "VIVA01") && props.parentItem === null) {

            let meta_mapping_meta : IMeta[] = [];

            const curriSubject = document.getElementById("meta_curriSubject") as HTMLSelectElement;
            if(curriSubject && curriSubject.selectedIndex > 0) {
                const curriSubject_val = curriInfo?.curriSubject[curriSubject.selectedIndex-1] as IMeta;
                meta_mapping_meta.push(curriSubject_val)    
            }

            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}

            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     
        } else if((props.menuName === "evaluationType" && props.brand.code === "VIVA01") && props.parentItem === null) {

            let meta_mapping_meta : IMeta[] = [];

            const meta_serviceType = document.getElementById("meta_serviceType") as HTMLSelectElement;
            if(meta_serviceType && meta_serviceType.selectedIndex > 0) {
                const serviceType_val = curriInfo?.serviceType[meta_serviceType.selectedIndex-1] as IMeta;
                meta_mapping_meta.push(serviceType_val);
            }

            for (let i = 0; i < selectedCurriBooks.length; i++) {
                const cb = selectedCurriBooks[i];
                meta_mapping_meta.push(cb);                
            }
            
            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}

            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     
        } else if((props.menuName === "serviceMap") && props.parentItem === null) {

            let meta_mapping_meta : IMeta[] = [];

            const meta_studyMap = document.getElementById("meta_studyMap") as HTMLSelectElement;
            if(studyMap.length > 0 && meta_studyMap) {
                const studyMap_val = studyMap[meta_studyMap.selectedIndex] as IMeta;
                meta_mapping_meta.push(studyMap_val);

                meta.name1 = "studyMap";
                meta.val1 = studyMap_val ? studyMap_val.val : "";    
            }

            const metamap : IMetaMap = {"meta": meta, "metamapList": meta_mapping_meta}

            logger.log("meta_mapping_meta", metamap);

            if(props.item === null) {
                insertMetaMetaMap(metamap);  
            } else {
                updateMetaMetaMap(metamap);  
            }     
            
        } else {

            if(props.item === null) {
                insertMeta(list);
            } else {
                updateMeta(meta);
            }      
        }

        logger.log("onSave", meta);
    }

    const hasAuth = util.getHasAuthMenu(props.item ? props.item.creator : "");

    return (
        <div>

            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">교과역량 관리테이블</caption>
                <tbody>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val" className="w-1/2"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                        <td className="text-left">
                            <input type="text" id="meta_description" className="w-1/2"/>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_code" className="w-1/2" />
                        </td>
                    </tr>

                    {((props.menuName === "curriBook" && props.parentItem === null) || (props.menuName === "subjectAbility") 
                    || (props.menuName === "contentArea") || (props.menuName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01")) && 
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">교육과정 년도<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriYear">
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {((props.menuName === "curriBook" && props.parentItem === null) || (props.menuName === "contentArea") 
                    || (props.menuName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01")) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">학교급<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriSchool">
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {((props.menuName === "curriBook" || props.menuName === "subjectAbility" || (props.menuName === "evaluationArea")  
                    || (props.menuName === "contentsItem" && props.brand.code === "VAET01") || (props.menuName === "evaluationArea3" && props.brand.code === "VAET01")
                    || ((props.menuName === "teachingSubject" && util.getCurrentBrand()?.code === "VIVA01"))) && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-subject">교과<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriSubject" name="sel-subject" onChange={() => {
                                onChangeForCurriSubject();
                            }}>
                                {(props.menuName === "teachingSubject" && util.getCurrentBrand()?.code === "VIVA01") &&
                                <option value="">교과선택</option>}
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {((props.menuName === "curriBook" && props.parentItem === null && util.getCurrentBrand()?.code === "VIVA01") || (props.menuName === "evaluationArea" && util.getCurrentBrand()?.code === "VIVA01")) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-class">과목</label></th>
                        <td className="text-left">
                            <select id="meta_teachingSubject">
                                <option value="">과목선택</option>
                                {teachingSubject && (teachingSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {((props.menuName === "evaluationArea" && props.brand.code === "VAET01") && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-subject">내용영역<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_contentArea" name="sel-contentArea">
                                {contentArea.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {(props.menuName === "evaluationType" && props.brand.code === "VIVA01") && <>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-subject">서비스 유형<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_serviceType" name="sel-serviceType">
                                <option value="">서비스 유형 선택</option>
                                {curriInfo?.serviceType.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-grade">교과과정</label></th>
                        <td className="text-left">
                            {/* <select id="meta_curriBook">
                                <option value="">교과과정</option>
                                {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select> */}
                            <button type="button" className="btn-sky-s ml-2" onClick={() => {
                                setViewCurriBookSearch(true);
                                // const meta_curriBook = document.getElementById("meta_curriBook") as HTMLSelectElement;
                                // if(meta_curriBook && meta_curriBook.selectedIndex > 0) {
                                //     const curriBook_val = curriInfo?.curriBook[meta_curriBook.selectedIndex-1] as IMeta;
                                //     const _selectedCurriBooks = JSON.parse(JSON.stringify(selectedCurriBooks)) as IMeta[];                                
                                //     if(!_selectedCurriBooks.find((meta) => meta.id === curriBook_val.id)) {
                                //         _selectedCurriBooks.push(curriBook_val);
                                //         setSelectedCurriBooks(_selectedCurriBooks);    
                                //     }
                                //     logger.log("selectedCurriBooks", _selectedCurriBooks);
                                // }
                            }}>설정</button>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-grade">선택 교과과정</label></th>
                        <td className="text-left">
                            {selectedCurriBooks && selectedCurriBooks.map((item, idx) => {
                                return(
                                    <div key={idx} className="flex items-center mt-1">
                                    <button type="button" className="btn-sky-s" onClick={() => {
                                        const _selectedCurriBooks = selectedCurriBooks.filter((meta) => meta.id !== selectedCurriBooks[idx].id)
                                        setSelectedCurriBooks(_selectedCurriBooks);    
                                    }}>삭제</button>
                                    <span className="ml-2">{item.val}</span>
                                    </div>
                                );
                            })}
                        </td>
                    </tr>
                    </>}

                    {((props.menuName === "teachingUsage" && props.brand.code === "VIVA01") || (props.menuName === "teachingCategory" && props.brand.code === "VIVA01")) && <>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-subject">서비스 유형<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_serviceType" name="sel-serviceType">
                                <option value="">서비스 유형 선택</option>
                                {curriInfo?.serviceType.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-grade">교과과정</label></th>
                        <td className="text-left">
                            <select id="meta_curriBook">
                                <option value="">교과과정</option>
                                {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>
                    </>}

                    {((props.menuName === "studyMap" || props.menuName === "achievementStandard" || props.menuName === "material" || props.menuName === "communication" || props.menuName === "languageFormat" || props.menuName === "evaluationCommu") && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-grade">교과과정<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriBook">
                                {curriInfo && curriInfo?.curriBook && (curriInfo?.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {(props.menuName === "curriBook" && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-grade">학년<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriGrade">
                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}
                    {(props.menuName === "curriBook" && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-semester">학기<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_curriSemester">
                                {curriInfo && curriInfo?.curriSemester && (curriInfo?.curriSemester as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {((props.menuName === "curriBook" && props.brand.code === "VIVA00") && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-unitGroup">단원그룹<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_unitGroup">
                                {unitGroup.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {(props.menuName === "curriBook" && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">차시 Depth <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="max_depth">
                                <option value={"2"}>2</option>
                                <option value={"3"}>3</option>
                                <option value={"4"}>4</option>
                                <option value={"5"}>5</option>
                                <option value={"6"}>6</option>
                            </select>
                        </td>
                    </tr>}

                    {((props.menuName === "contentArea" || props.menuName === "studyMap" || props.menuName === "evaluationArea") && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56">고유코드</th>
                        <td className="text-left">{UUID}</td>
                    </tr>}

                    {(props.menuName === "serviceMap" && props.parentItem === null) &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">학습맵 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_studyMap">
                                {studyMap.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>                            
                        </td>
                    </tr>}

                    <tr>
                        <th scope="row" className="w-56">등록자</th>
                        {props.item &&
                        <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                        {!props.item &&
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록일</th>
                        {props.item &&
                        <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                        {!props.item &&
                        <td className="text-left">{util.getDate()}</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
                    
            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                    {!props.item &&
                    <button type="button" className="btn-sky" onClick={onSave}>{"등록"}</button>}
                    {props.item &&
                    <button type="button" className="btn-sky" onClick={onSave} disabled={!hasAuth}>{"수정"}</button>}
                </div>
            </div>

            {viewCurriBookSearch && <PopupCurriBookSearch selectedCurriBooks={selectedCurriBooks} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewCurriBookSearch(false);
            } } onSelect={function (items: IMeta[]): void {
                // const _selectedCurriBooks = JSON.parse(JSON.stringify(selectedCurriBooks));
                // for (let i = 0; i < items.length; i++) {
                //     const item = items[i];                    
                //     if(!(_selectedCurriBooks as IMeta[]).find((meta) => meta.id === item.id)) {
                //         _selectedCurriBooks.push(item);
                //     }
                // }                
                // setSelectedCurriBooks(_selectedCurriBooks);
                setSelectedCurriBooks(items);
                setViewCurriBookSearch(false);
            } } />}

        </div>
    );
}

export default MetaAddEdit;
