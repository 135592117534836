import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ICurriInfo, IInstrument, ILibrary, ILibrarySearchData, IMenu, IMeta, isDev, ITextBook, S3Server } from "../common";
import { logger } from '../logger';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import InstrumentSearch from "./InstrumentSearch";
import InstrumentRegister from "./InstrumentRegister";
import PopupMediaPreview from "../library/PopupMediaPreview";
import InstrumentBulkRegister from "./InstrumentBulkRegister";

interface IInstrumentMath {
    brand: IBrand;
    menu?: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function InstrumentMath(props: IInstrumentMath) {
    
    const [list, setList] = useState([] as any);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewList, setViewList] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isBulkEditing, setIsBulkEditing] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [files, setFiles] = useState([] as any);
    
    const [viewMediaPreview, setViewMediaPreview] = useState(false);
    const [mediaType, setMediaType] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaName, setMediaName] = useState("");

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            setViewList(true);
            setIsEditing(false);
            setIsBulkEditing(false);
            setSelectedItem(null);

            const _pageNum = localStorage.getItem("Instrument_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(_pageNum) {
                pagenum.value = _pageNum;
            } else {
                pagenum.value = "10";                
            }
            if(props.brand) {
                refreshList();
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            setListOfPage([]);

            if(isEditing) {
                onEditClose();
            }
            refreshList();
        }
    }, [props.menu])

    useEffect(() => {
        if(isEditing) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     setIsBulkEditing(false);
            // }
        } 

        if(!isEditing) {
            
            logger.log("isEditing", isEditing);

            const _searchData = searchData;
            if(_searchData) {
                onSearch(_searchData);            
            }
        }

    }, [isEditing])

    const refreshList = () => {

        setIsEditing(false);
        setIsBulkEditing(false);

        setSelectedItem(null);

        setSearchData(null);
        setList([]);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Instrument_numOfPage") ? Number(localStorage.getItem("Instrument_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);
        const list = await util.getCurriToolSearchList(data);
        setList(list);
        logger.log("onSearch", data, list);
        if(list) {
            const _list = list as IInstrument[];
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(_list.length > 0) {
                totalCnt = _list[0].full_count;
            }
            calcPage(totalCnt, currentPage);
        }
        setViewList(true);

        props.showLoading(false);
    }

    function onSearch(data: any): void {
        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                onChangePage(_currPage);
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                onChangePage(_currPage);
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Instrument_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        logger.log("numOfPage", numOfPage);
        onSearch(_searchData);            
    }

    function onAdd(): void {
        setIsEditing(true);
        setIsBulkEditing(false);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onEditClose(): void {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        // refreshList();
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onChangePage(page: number) {
        
        const _searchData = searchData;
        _searchData.limit_page = page;
        onSearch(_searchData);
        
        logger.log("onChangePage", page, searchData, _searchData);

        setCurrPage(page);
    }
    
    function onConfirm(item: IInstrument): void {
        setIsEditing(true);
        setIsBulkEditing(false);
        setSelectedItem(item);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }
        
    async function onBulk() {

        if(files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                var reader = new FileReader();
                reader.onload = (e) => onExcelProccessing(e, file);
                reader.readAsText(file)    
            }
        }
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {
    
            props.showLoading(true);

            var data = e.target.result;

            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            logger.log("dataParse", dataParse);  

            for (let i = 2; i < dataParse.length; i++) {

                const item = dataParse[i];

                const _item = item as string[];

                if(_item.length > 0) {

                    const _data = {
                        "id": _item[0],                        
                        "description": _item[1],
                    }

                    logger.log("_data", _data);
                    const result = await util.curriToolForSave(_data, "update");
                    logger.log("curriToolForSave", _data, result);   
                }
            }

            props.showLoading(false);

            props.showModal("", "등록이 완료 되었습니다.");
        }
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files;    
        logger.log("files", _url);

        setFiles(e.target.files);
    }

    async function onDelete() {

        // const IM_chk = document.getElementsByName("IM_chk");

        // let checkedCnt = 0;
        // for (let i = 0; i < IM_chk.length; i++) {
        //     const element = IM_chk[i] as HTMLInputElement;
        //     if(element.checked) {
        //         checkedCnt++;
        //         if(checkedCnt > 1) {
        //             props.showModal("", "삭제할 번호 하나만 선택해 주세요!");
        //             return;        
        //         }
        //     }
        // }

        if(window.confirm("정말 삭제 하시겠습니까?") === true) {

            props.showLoading(true);

            for (let i = 0; i < list.length; i++) {

                const item = list[i] as IInstrument;
                const radio = document.getElementById("IM_chk_"+i) as HTMLInputElement;
                if(item && radio && radio.checked) {
    
                    logger.log("onDelete", item);
    
                    const isAdmin = util.getHasAuthMenu("");
                    if(isAdmin) {
                    } else {
                        if(util.getUserInfo("uid") !== item.creator) {
                            props.showModal("", "본인이 등록한 도구만 삭제할 수 있습니다.");
                            continue;        
                        }
                    }
    
                    const result = await util.deleteCurriTool(item.id!);
                    logger.log("onDelete", item, result);
                }            
            }

            _onSearch(searchData);        
            props.showLoading(false);
        }
    }

    async function onUse(use: boolean) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        props.showLoading(true);

        let cnt = 0;
        for (let i = 0; i < list.length; i++) {

            const item = list[i] as IInstrument;
            const radio = document.getElementById("IM_chk_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {

                logger.log("onUse", item);

                const isAdmin = util.getHasAuthMenu("");
                if(isAdmin) {
                } else {
                    if(util.getUserInfo("uid") !== item.creator) {
                        // props.showModal("", "본인이 등록한 교수자료만 수정할 수 있습니다.");
                        continue;        
                    }
                }

                const _id = util.getUserInfo("id");
                const uid = util.getUserInfo("uid");
                const uname = util.getUserInfo("name");
        
                const data = {

                    brand_id: brand.id,

                    id: item.id,
        
                    updater: uid,
                    updater_id: _id,
                    updater_name: uname,
        
                    is_active: use,
                };
                const result = await util.curriToolForSave(data, "update");
                cnt++;
            }            
        }

        props.showLoading(false);

        if(cnt > 0) {
            const _searchData = searchData;
            _onSearch(_searchData);                
        }
    }

    return (
        <div>
            <div className="top-title">
                <h3>수학도구 관리</h3>
            </div>

            {/* <div style={{display: isDev ? "" : "none"}}>
                <table>
                    <caption className="sr-only"></caption>
                    <tbody>
                        <tr>
                            <th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
                            <td className="text-left">
                                <label htmlFor="file-input" className="sr-only">파일찾기</label>
                                <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange(e)}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" className="btn-sky" onClick={onBulk}>설명 일괄등록</button>
            </div> */}

            <div style={{display: isEditing || isBulkEditing ? "none" : ""}}>
            
                {/* <!-- 검색영역 --> */}
                <InstrumentSearch view={true} onSearch={onSearch} brand={props.brand} pageInfo={{ page: currPage, pageItemCnt: localStorage.getItem("Instrument_numOfPage") ? Number(localStorage.getItem("Instrument_numOfPage")) : 10 }} showLoading={props.showLoading} />
                {/* <!-- .//검색영역 --> */}

                <div className="mt-5 mb-3 text-right">
                    <label htmlFor="page-num">페이지당 표기 수</label>
                    <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </select>
                </div>

                <div className="mt-3 mb-5 text-right" style={{display: ""}}>
                    <div className="w-avail" style={{display:"ruby"}}>
                        <button type="button" className="btn-white mr-2" onClick={onDelete}>삭제</button>
                        <button type="button" className="text-sky-500 btn-white mr-2" onClick={() => onUse(true)}><span className="text-sky-500">사용</span></button>
                        <button type="button" className="text-red-600 btn-white" onClick={() => onUse(false)}> <span className="text-red-600">미사용</span></button>
                    </div>
                </div>

                {!isEditing && viewList && <>

                {/* <!-- table --> */}
                <table>
                    <caption className="sr-only">텍스트 관리 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="pl-4 pr-3">
                                <input id="all_check" type="checkbox" className="h-4 w-4 rounded" onClick={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    if(input) {
                                        (list as IInstrument[]).map((_, idx) => {
                                            const IM_check = document.getElementById("IM_chk_"+idx) as HTMLInputElement;
                                            IM_check.checked = input.checked;
                                        })
                                    }
                                }}                                
                                />
                            </th>

                            <th scope="col" className="px-3">번호</th>
                            <th scope="col" className="px-3">KEY</th>
                            <th scope="col" className="px-3">도구 이름</th>
                            <th scope="col" className="px-3">타입</th>
                            <th scope="col" className="px-3">포함방법</th>
                            {/* <th scope="col" className="px-3">코드</th> */}
                            <th scope="col" className="px-3">교과</th>
                            <th scope="col" className="px-3">사용</th>
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">미리보기</th>
                            <th scope="col" className="pl-3 pr-4">상세보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewList && list &&
                        <tr style={{display: (list as IInstrument[]).length === 0 ? "" : "none"}}>
                            <td colSpan={9}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as IInstrument[]).map((item, idx) => {

                            let _unitVal = "";
                            let _curriBookVal = ""

                            if(curriInfo && curriInfo.curriBook) {
                                const curriBook = curriInfo.curriBook as IMeta[];
                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                _curriBookVal = _curriBook ? _curriBook.val : "";

                            //     const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                            //     const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                            //     const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                            //     const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                            //     const curriUnit5 = curriInfo.curriUnit5 as IMeta[];
    
                            //     const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                            //     const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                            //     const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                            //     const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                            //     const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);
    
                            //     const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                            //     arr.map((val, idx) => {
                            //         if(val) {
                            //             if(_unitVal === "") {
                            //                 _unitVal = val;    
                            //             } else {
                            //                 if(idx === 2) {
                            //                     _unitVal = _unitVal + " > " + "<br/>" + val;

                            //                 } else {
                            //                     _unitVal = _unitVal + " > " + val;
                            //                 }
                            //             }
                            //         }
                            //     });
                            }

                            const totalCnt = item.full_count;
                            const _numOfPage = localStorage.getItem("Instrument_numOfPage") ? Number(localStorage.getItem("Instrument_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            let type = item.type;
                            // logger.log(totalCnt, _numOfPage, currPage, _num);

                            let title = item.name;
                            if(title && title.length > 50) {
                                title = title.substring(0, 47) + "...";
                            }

                            return (
                                // <div key={idx}><img src={item.url} width={40}/>{JSON.stringify(item)}</div>
                                <tr key={idx}>   
                                    <td>
                                        <input id={"IM_chk_"+idx} type="checkbox" name="IM_chk" className="h-4 w-4 rounded" onClick={(e) => {
                                            const text_ck = document.getElementsByName("IM_chk");
                                            let cntChecked = 0;
                                            for (let i = 0; i < text_ck.length; i++) {
                                                const element = text_ck[i] as HTMLInputElement;
                                                if(element.checked) {
                                                    cntChecked = cntChecked + 1;
                                                }
                                            }
                                            const all_check = document.getElementById("all_check") as HTMLInputElement;
                                            if(cntChecked === text_ck.length) {                                                
                                                all_check.checked = true;                                                
                                            } else {
                                                all_check.checked = false;                                                
                                            }
                                        }}/>
                                    </td>

                                    <td>{_num}</td>
                                    <td>{item.id}</td>
                                    <td>{title}</td>
                                    <td>{type}</td>
                                    <td>{item.includeType}</td>
                                    {/* <td>{item.code}</td> */}
                                    <td>{_curriBookVal}</td>
                                    <td>{item.is_active ? "사용" : "미사용"}</td>
                                    <td>{item.creator_name} ({item.creator})</td>
                                    {/* <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td> */}
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => {
                                            // const url = S3Server + item.imageUrl;
                                            // window.open(url, "_blank", "noopener, noreferrer");      
                                            setMediaType("image");
                                            setMediaUrl(S3Server+item.imageUrl);
                                            setMediaName(title);
                                            setViewMediaPreview(true);                                                                      
                                        }}>미리보기</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => onConfirm(item)}>확인</button>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                {/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    <div className="absolute top-0 right-0 text-right">
                        <button type="button" className="btn-sky mr-2" onClick={() => {
                            setIsEditing(false);
                            setIsBulkEditing(true);
                        }}>일괄등록</button>
                        <button type="button" className="btn-sky" onClick={onAdd}>등록</button>
                    </div>

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}

                </div></>}

            </div>

            {isEditing && <InstrumentRegister OnClose={onEditClose} brand={props.brand} selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} />}
            {isBulkEditing && <InstrumentBulkRegister brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}            

            {viewMediaPreview &&
            <PopupMediaPreview type={mediaType} url={mediaUrl} name={mediaName} onClose={function (): void {
                setViewMediaPreview(false);
            } } />}

        </div>
    );  
}
export default InstrumentMath;