import { MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { count } from "console";
import MetaAddEdit from "./MetaAddEdit";
import { CurriInfo, IBrand, ICurriInfo, IMenu, IMeta, headers } from "../common";
import '../common.scss';
import { logger } from '../logger';
import MetaStudyMapEdit from "./MetaStudyMapEdit";
import MetaContentsItemEdit from "./MetaContentsItemEdit";
import MetaMaterialEdit from "./MetaMaterialEdit";
import MetaCommunicationEdit from "./MetaCommunicationEdit";
import MetaServiceMapEdit from "./MetaServiceMapEdit";

interface IMetaServiceMap {
    brand: IBrand;
    menu: IMenu;
    item: IMeta;
    onClose: () => void;    
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaServiceMap(props: IMetaServiceMap) {

    const [viewList, setViewList] = useState(false);
    const [topMeta, setTopMeta] = useState(null as any);
    const [metaHList, setMetaHList] = useState([] as IMeta[]);
    const [oriMetaHList, setOriMetaHList] = useState([] as IMeta[]);

    const [currItem, setCurrItem] = useState(null as any);
    const [parentItem, setParentItem] = useState(null as any);
    const [isEditing, setIsEditing] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            logger.log("selected item", props.item);
            if(props.item) {
                setParentItem(props.item);
                getDescendantsList(props.item);
            } else {
                props.onClose();
            }
        }
        bDone = true;
    }, [])

    async function getDescendantsList(meta: IMeta) {

        props.showLoading(true);

        const list = await util.getDescendantsList(meta.code, false);
        const _list = list.filter((item) => item.code !== meta.code);
        const top = list.find((item) => item.code === meta.code);
        setTopMeta(top);

        for (let i = 0; i < _list.length; i++) {
            const item = _list[i];
            item.isOpened = true;
            item.hasChild = _list.find((_item) => _item.parent_id === item.id) ? true : false;
        }

        logger.log("getDescendantsList", meta, list, _list);  

        if(oriMetaHList.length === 0) {
            setOriMetaHList(_list);
        }
        setMetaHList(_list);

        props.showLoading(false);
    }

    function onAddTopMeta(): void {
        setCurrItem(null);
        setIsEditing(true);
    }
    
    function onAddChildMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta, type: string): void {
        e.stopPropagation();

        logger.log("onAddChildMeta setParentItem", item);
        setParentItem(item);  
        setCurrItem(null);
        setIsEditing(true);
    }

    function onEditMeta(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, item: IMeta): void {
        e.stopPropagation();

        let _pcode = "";
        const arr = item.code.split("-");
        for (let i = 0; i < arr.length-1; i++) {
            if(_pcode === "")
                _pcode = arr[i];
            else 
                _pcode = _pcode + "-" + arr[i];
        }
        // logger.log("onEditMeta setCurrItem", item, _pcode);
        // const _pcode = item.code.substring(0, item.code.length-5);

        let _parentItem = metaHList.find((item) => item.code === _pcode);
        if(!_parentItem) {
            _parentItem = topMeta;
            if(!_parentItem) {
                logger.log("onEditMeta setCurrItem", _parentItem, _pcode);
                alert("parentItem 가져오기 실패!!!");
                return;
            }    
        }
        setParentItem(_parentItem);
        logger.log("onEditMeta setCurrItem", item, _parentItem);

        setCurrItem(item);
        setIsEditing(true);
    }

    function onCloseEdit(isEdited: boolean, from: string): void {

        if(isEdited) {            
            getDescendantsList(props.item);
        }

        setIsEditing(false);
    }

    function onChangeTree(item: IMeta) {
        const _metaHList = JSON.parse(JSON.stringify(metaHList));
        const _item = (_metaHList as IMeta[]).find((meta) => meta.id === item.id);
        if(_item) {            
            _item.isOpened = !item.isOpened;
            setMetaHList(_metaHList);
        }                    
    }

    let parents : IMeta[] = [];
    function getParents(item: IMeta, start: boolean) {
        if(start) {
            parents = [];
        }
        const parent = metaHList.find((meta) => meta.id === item.parent_id);
        if(parent) {
            parents.push(parent);
            getParents(parent, false);
        }
    }

    return (

        <div style={{width: "91rem", paddingRight: "3rem"}}>

            <div style={{display: isEditing ? "none" : ""}}>
                <table>
                    <caption className="sr-only"></caption>
                    <thead>
                        <tr>
                            <th scope="col" className="" style={{width: "40rem", maxWidth: "40rem"}}>메타 값</th>
                            <th scope="col" className="" style={{width: "5rem"}}>Key</th>
                            <th scope="col" className="" style={{width: "15rem"}}>코드</th>
                            <th scope="col" className="" style={{width:"5rem"}}>사용</th>
                            <th scope="col" className="" style={{width:"7rem"}}>추가</th>
                            <th scope="col" className="" style={{width:"9rem"}}>등록자</th>
                            <th scope="col" className="" style={{width:"7rem"}}>상세보기</th>
                        </tr>
                    </thead>
                </table>
            </div>

            {viewList &&
            <div className="overflow-y-auto index-scroll" style={{display: isEditing ? "none" : "", height: "42rem"}}>

                <table>
                    {/* <caption className="sr-only"></caption>
                    <thead>
                        <tr>
                            <th scope="col" className="px-3">메타 값</th>
                            <th scope="col" className="px-3">Key</th>
                            <th scope="col" className="px-3">코드</th>
                            <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                            <th scope="col" className="px-3" style={{width:"80px"}}>추가</th>
                            <th scope="col" className="px-3" style={{width:"100px"}}>등록자</th>
                            <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                        </tr>
                    </thead> */}
                    
                    <tbody>

                        <tr>
                            <td className="" style={{width: "40rem", maxWidth: "40rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width: "5rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width: "15rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"5rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"9rem", height: 0, padding: 0}}></td>
                            <td className="" style={{width:"7rem", height: 0, padding: 0}}></td>
                        </tr>

                        {metaHList && (metaHList as IMeta[]).length === 0 &&
                        <tr>
                            <td colSpan={6}>등록된 메타 정보가 없습니다.</td>
                        </tr>}

                        {metaHList && (metaHList as IMeta[]).map((item, idx) => {
                            let _code = item.code;
                            const arrCode = item.code.split("-");
                            // _code = arrCode[arrCode.length-1];
                            _code = _code.replace(arrCode[0]+"-", "");

                            let clsName = "";
                            if(item.depth === 1) {
                                clsName = "";
                            } else if(item.depth === 2) {
                                clsName = "";
                            } else if(item.depth === 3) {
                                clsName = "pl-5";
                            } else if(item.depth === 4) {
                                clsName = "pl-10";
                            } else if(item.depth === 5) {
                                clsName = "pl-15";
                            } else if(item.depth === 6) {
                                clsName = "pl-20";
                            } else if(item.depth === 7) {
                                clsName = "pl-25";
                            }
                            
                            let val = item.title ? item.title : item.val;
                            val = val.replaceAll("#^|", ", ");
                            if(val.length > 55) {
                                val = val.substring(0, 55) + "<br/><span class=\"ml-6\">" + val.substring(55) + "</span>";
                            }

                            const bAdd1 = item.depth === 2;

                            let view = true;                
                            getParents(item, true);
                            for (let i = 0; i < parents.length; i++) {
                                const parent = parents[i];    
                                if(!parent.isOpened) {
                                    view = false;
                                    break;
                                }
                            }

                        return (
    						<tr key={idx} id={"id"+item.id} style={{display: view ? "" : "none", backgroundColor: item.is_active ? "#ffffff" : "#f0f0f0"}}>
                                <td className="text-left">                                
                                    <span className={clsName} onClick={() => {
                                            if(item.hasChild) {
                                                onChangeTree(item);
                                            }
                                    }} style={{cursor: "pointer"}} >
                                        <button className="mr-2" type="button" style={{width: "1rem"}} >
                                            {item.hasChild && item.isOpened &&
                                            <img src="./images/arrow-up.png" alt="arrow-up" style={{width: "0.95rem"}} />}
                                            {item.hasChild && !item.isOpened &&
                                            <img src="./images/arrow-right.png" alt="arrow-right" style={{width: "0.75rem"}} />}
                                        </button>

                                        <span dangerouslySetInnerHTML={{__html: val}}></span>
                                        {/* <Latex delimiters={latex_delimiters} macros={latex_macros}>{name}</Latex> */}
                                    </span>
                                </td>

                                {/* <td className="text-left"><p className={clsName}>{val}</p></td> */}
                                <td className="text-center">{item.id}</td>
                                <td className="text-left">{_code}</td>
                                <td>{item.is_active ? "사용" : "미사용"}</td>
                                <td className="w-24">
                                    {bAdd1 &&
                                    <button type="button" className="btn-white-s" onClick={(e) => onAddChildMeta(e, item, "1")}>추가</button>}
                                </td>
                                <td className="leading-4">{item.creator}</td>
                                <td className="w-24">
                                    <button type="button" className="btn-white-s" onClick={(e) => onEditMeta(e, item)}>보기</button>
                                </td>
                            </tr>
                        )
                        })}
                    </tbody>

                </table>

                <div className="relative mt-5">
                    <div className="flex">
                        <button type="button" className="btn-white mr-2" onClick={() => {
                            props.onClose();
                        }}>목록</button>
                        {/* <button type="button" className="btn-white" onClick={onAddMetaToCurrent}>추가<br/>(1Depth)</button> */}
                        <div className="text-right" style={{width: "100%"}}>
                            <button type="button" className="btn-sky" onClick={onAddTopMeta}>등록</button>
                        </div>
                    </div>
                </div>
            
            </div>}
        
        {isEditing && 
            <MetaServiceMapEdit brand={props.brand} item={currItem} parentItem={parentItem} onCloseEdit={onCloseEdit} showModal={props.showModal} showLoading={props.showLoading} />
        }

    </div>
    );
}

export default MetaServiceMap;
