import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, IUser, ITextBookHistory } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DatePicker from "@gsebdev/react-simple-datepicker/dist/DatePicker";
import "../library/LibrarySearch.scss";

interface IPopupUpdateHistory {
    selectedItem: ITextBookHistory;
    title: string;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupUpdateHistory(props: IPopupUpdateHistory) {

    const [list, setList] = useState([] as any);
    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [listCount, setListCount] = useState(0);

    const curriInfo = util.getCurrentCurriInfo();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            logger.log("selectedItem", props.selectedItem);
            refreshList();
        }
        bDone = true;
    }, [])

    function onClose() {
        props.onClose();
    }

    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        const data = {
            brand_id: brand.id ? brand.id : null,
            // targetId: props.selectedItem.id,
            // type: props.selectedItem.type,
            // detail: true,
            targetId: props.selectedItem.textbookSetId,
            target: "sets",
            type: "sets",
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        const list = await util.contentsHistoryList(data);

        setList(list);
        logger.log("onSearch", data, list);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{/*maxWidth:"60rem", maxHeight:"60rem", minHeight:"40rem"*/padding: "4rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">세트지 업데이트 이력</h2>

                            <div className="overflow-x-auto px-5 mt-5 mb-3">
                                {props.title}<br/>{props.selectedItem.textbookSetName}
                            </div>

                            {/* <!-- table --> */}
                            <div className="ml-5 mr-5 mt-5">
                                <table>
                                    <caption className="sr-only">목차 리스트</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-4 pr-3" style={{textAlign: "center"}}>번호</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>업데이트 일</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>버전</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>세트지명</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>변경 건수(key)</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>저작자</th>
                                            {/* <th scope="col" className="pl-3" style={{textAlign: "center"}}>바로가기</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && (list as any[]).map((item, idx) => {

                                            const totalCnt = listCount;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);
                                            
                                            const deployStatus = item.deployStatus === 1 ? "(배포중)" : item.deployStatus === 2 ? "(배포종료)" : "";

                                            // const deploydate_1 = item?.deploydate_1?.substring(0, 10).replaceAll("-", ".");
                                            // const deploydate_2 = item?.deploydate_2?.substring(0, 10).replaceAll("-", ".");

                                            // let deploydate = ""
                                            // if(deploydate_1) {
                                            //     deploydate = deploydate_1 + "</br> ~ </br>";
                                            //     if(deploydate_2) {
                                            //         deploydate = deploydate + deploydate_2;
                                            //     }
                                            // }

                                            const version = 1.0 + (_num-1)*0.1;

                                            // const data = JSON.parse(item.data);
                                            // let len = 0;
                                            // if(data && data.articles) {
                                            //     logger.log("data", data);
                                            //     len = data.articles.length;    
                                            // }

                                            let updateKey = "-";
                                            if(item.updateKey) {
                                                const arr = item.updateKey.split(",");
                                                updateKey = arr.length + " (" + item.updateKey + ")";
                                            }
                                            
                                            return(
                                                <tr key={idx} onClick={() => {}}>
                                                    <td>{_num}</td>
                                                    {/* <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td> */}
                                                    <td>{item.regdate}</td>
                                                    {/* <td>{version.toFixed(1)}{deployStatus} </td> */}
                                                    <td>{item.version}{deployStatus} </td>
                                                    <td>{item.targetName}</td>
                                                    <td>{updateKey}</td>
                                                    <td>{item.creator_name}</td>
                                                    {/* <td>                            
                                                        <button type="button" className="btn-white-s" onClick={onClose}>바로가기</button>
                                                    </td> */}
                                                </tr>    
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- .//table -->	 */}                

                            <div className="relative mt-10 mb-5">

                                {/* <!-- pagenation --> */}
                                <div className="text-center">
                                    <nav className="pagenation" aria-label="Pagination">

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}

                                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                        return (
                                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                        )})}

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Next</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        
                                    </nav>
                                </div>
                                {/* <!-- .// pagenation --> */}
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupUpdateHistory;
