import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { headers, IArticle, IBrand, IMenu, IMeta, isDev, ITextBook, ITextbookBasicData, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import ContentsList from "./TextBookIndexList";
import TextBookEdit from "./TextBookEdit";
import Tooltip from "@mui/material/Tooltip";
import PopupHistoryNDeploy from "./PopupHistoryNDeploy";
import PopupDeployHistory from "./PopupDeployHistory";
import TextBookEdit_1 from "./TextBookEdit_1";
import PopupConfirm from "../contents/PopupConfirm";
import TextBookEvaluation from "./TextBookEvaluation";
import PopupUpdateHistory from "./PopupUpdateHistory";
import TextBookHistoryList from "./TextBookHistoryList";
import PopupDeployProcess, { IServerInfo } from "./PopupDeployProcess";
import PopupDeployTable from "./PopupDeployTable";

interface ITextBookDeployList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    showContentsList: (view: boolean) => void;
}
  
function TextBookDeployList(props: ITextBookDeployList) {
    
    const [list, setList] = useState([] as any);
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewHistoryDeploy, setViewHistoryDeploy] = useState(false);
    const [viewDeployHistory, setViewDeployHistory] = useState(false);
    const [viewDeployTable, setViewDeployTable] = useState(false);

    const [viewConfirm, setViewConfirm] = useState(false);    
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmDesc, setConfirmDesc] = useState("");

    const [deployType, setDeployType] = useState("");
    const [brandList, setBrandList] = useState<IBrand[]>([]);
    const [contentsType, setContentsType] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [viewDeployProcess, setViewDeployProcess] = useState(false);

    const [deployContents, setDeployContents] = useState(null as any);

    const [serverList, setServerList] = useState<IServerInfo[]>([]);
    const [selectedServer, setSelectedServer] = useState("");

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // setServerList([
            //     {id: 0, name: "TT"},
            //     {id: 1, name: "SS"},
            //     {id: 2, name: "BB"},
            // ]);            
                
            const _pageNum = localStorage.getItem("TextBook_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            // const _contentsType = document.getElementById("contentsType") as HTMLSelectElement;
            // if(_contentsType) {
            //     _contentsType.value = "textbook";
            //     _contentsType.disabled = true;
            //     setContentsType("textbook");
            // }
            
            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                const new_reg_sdate = document.getElementById("new_reg_sdate") as HTMLInputElement;
                if(!new_reg_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_reg_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)    
                }
                const reg_sdate = document.getElementById("reg_sdate") as HTMLInputElement;
                if(reg_sdate) {
                    reg_sdate.style.display = "none";
                }

                const new_reg_edate = document.getElementById("new_reg_edate") as HTMLInputElement;
                if(!new_reg_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_reg_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }
                const reg_edate = document.getElementById("reg_edate") as HTMLInputElement;
                if(reg_edate) {
                    reg_edate.style.display = "none";
                }

                const new_deploy_sdate = document.getElementById("new_deploy_sdate") as HTMLInputElement;
                if(!new_deploy_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_deploy_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[2].appendChild(node)    
                }
                const deploy_sdate = document.getElementById("deploy_sdate") as HTMLInputElement;
                if(deploy_sdate) {
                    deploy_sdate.style.display = "none";
                }

                const new_deploy_edate = document.getElementById("new_deploy_edate") as HTMLInputElement;
                if(!new_deploy_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_deploy_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[3].appendChild(node_e)
                }

                const deploy_edate = document.getElementById("deploy_edate") as HTMLInputElement;
                if(deploy_edate) {
                    deploy_edate.style.display = "none";
                }                
            }

            getBrandList();
            getDeployServer();
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(serverList) {
            // logger.log("serverList", serverList);
        }
    }, [serverList])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
            getDeployServer();
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
        }
    }, [props.menu])

    async function getBrandList() {
        const brandList = await util.getBrandList();
        setBrandList(brandList);
    }

    async function getDeployServer() {
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        const serverList = await util.getDeployServer(brand.id);
        logger.log("getDeployServer", serverList);
        setServerList(serverList);
    }

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);

        // const brand = util.getCurrentBrand();
        // if(!brand) {
        //     return;
        // }
        // setCurrPage(1);

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        if(data) {
            // data.detail = true;
        }
        
        setViewList(false);

        const list = await util.textbookDeployList_new(data);
        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_keyword = document.getElementById("TB_keyword") as HTMLInputElement;
        const TB_keyword_type = document.getElementById("TB_keyword_type") as HTMLInputElement;

        const publicOpen = document.getElementById("publicOpen") as HTMLSelectElement;

        const articleCategory = document.getElementById("articleCategory") as HTMLSelectElement;
        
        const selectedServerCode = serverList.find((server: IServerInfo) => server.name === selectedServer)?.code;

        let curriBookVal = undefined;
        const curriBook = document.getElementById("curriBook") as HTMLSelectElement;
        if(curriBook && curriBook.selectedIndex > 0) {
            curriBookVal = curriInfo?.curriBook[curriBook.selectedIndex - 1].id;
        }
        let serviceTypeVal = undefined;
        const serviceType = document.getElementById("serviceType") as HTMLSelectElement;
        if(serviceType && serviceType.selectedIndex > 0) {
            serviceTypeVal = curriInfo?.serviceType[serviceType.selectedIndex - 1].id;
        }

        const new_reg_sdate = document.getElementById("new_reg_sdate") as HTMLInputElement;
        const new_reg_edate = document.getElementById("new_reg_edate") as HTMLInputElement;        
    
        let sdate = "";
        let edate = "";
        const arrSD = new_reg_sdate.value.split("-");
        const arrED = new_reg_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = new_reg_sdate.value;
            edate = new_reg_edate.value;
        }  

        const new_deploy_sdate = document.getElementById("new_deploy_sdate") as HTMLInputElement;
        const new_deploy_edate = document.getElementById("new_deploy_edate") as HTMLInputElement;        

        let sdate1 = "";
        let edate1 = "";
        const arrSD1 = new_deploy_sdate.value.split("-");
        const arrED1 = new_deploy_edate.value.split("-");
        if(arrSD1.length === 3 && arrED1.length === 3) {
            sdate1 = new_deploy_sdate.value;
            edate1 = new_deploy_edate.value;
        }  

        const data = {
            // brand_id: selectedBrand === "" ? undefined : selectedBrand,
            brand_id: brand.id,

            type: contentsType === "" ? undefined : contentsType,
            targetCategory: contentsType === "article" && articleCategory.value !== "" ? articleCategory.value : undefined,

            name: TB_keyword_type && TB_keyword_type.value === "name" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,
            targetId: TB_keyword_type && TB_keyword_type.value === "key" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,

            is_publicOpen: publicOpen ? publicOpen.value === "" ? undefined : publicOpen.value === "1" ? true : false : undefined,

            deployStatus: deployType === "" ? undefined : deployType,
            deployServer_code: selectedServer === "" ? undefined : selectedServerCode,

            target: contentsType === "" ? undefined : contentsType,
            targetName: TB_keyword_type && TB_keyword_type.value === "name" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,

            curriBook: curriBookVal,
            serviceType: serviceTypeVal,

            creator: TB_keyword_type && TB_keyword_type.value === "creator" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,
            updater: TB_keyword_type && TB_keyword_type.value === "updater" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,
            
            reg_sdate: sdate,  
            reg_edate: edate,

            deploy1_sdate: sdate1,  
            deploy1_edate: edate1,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("TextBook_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onDeployHistory() {
        setViewDeployHistory(true);
    }

    async function onConfirmOK() {
        
        onConfirmClose();

        props.showLoading(true);

        if(confirmTitle === "배포보류") {

            const pendList = [];

            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const _check = document.getElementById("TB_check_"+i) as HTMLInputElement;
                if(item && _check && _check.checked) {
                    
                    // logger.log("onDeployPend", item);
    
                    if(item.deployStatus === 0 || item.deployStatus === 10) {
                        pendList.push(item.id);    
                    } else {
                        // props.showModal("", "배포된 교과서는 배포보류가 불가능합니다.");
                        // return;
                    }    
                }            
            }    

            logger.log("배포보류", pendList);    

            if(pendList.length > 0) {
                const data = {                            
                    "deployStatus": 3,
                    "contentsHistoryIdList": pendList,
                }    
    
                const result = await util.deployChangeStatusId(data);                    
                logger.log("deployChangeStatusId update", data, result);
            }
        } else if(confirmTitle === "배포종료") {

            const endList = [];

            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const _check = document.getElementById("TB_check_"+i) as HTMLInputElement;
                if(item && _check && _check.checked) {
                    
                    // logger.log("onDeployEnd", item);
    
                    if(item.deployStatus === 10) {
                        endList.push(item.id);    
                    } else {
                        // props.showModal("", "배포종료가 불가능합니다.");
                        // return;
                    }    
                }            
            }    

            logger.log("배포종료", endList);    

            if(endList.length > 0) {
                const data = {                            
                    "deployStatus": 2,
                    "contentsHistoryIdList": endList,
                }    
    
                const result = await util.deployChangeStatusId(data);                    
                logger.log("deployChangeStatusId update", data, result);
            }
        } else if(confirmTitle === "배포보류취소") {

            const pendCancelList = [];

            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const _check = document.getElementById("TB_check_"+i) as HTMLInputElement;
                if(item && _check && _check.checked) {
                    pendCancelList.push(item.id);    
                }            
            }    

            logger.log("배포보류취소", pendCancelList);    

            if(pendCancelList.length > 0) {
                const data = {                            
                    "deployStatus": 0,
                    "contentsHistoryIdList": pendCancelList,
                }    
    
                const result = await util.deployChangeStatusId(data);                    
                logger.log("deployChangeStatusId update", data, result);
            }

        }

        props.showLoading(false);

        onSearch();
    }
    
    function onConfirmClose() {
        setViewConfirm(false);
    }

    function onChangeDeployType(type: string) {
        setDeployType(type);

        if(searchData) {
            searchData.deployStatus = type === "" ? undefined : type;
            setSearchData(searchData);
            _onSearch(searchData);    
        } else {
            // onSearch();
        }
    }

    function onChangeServer(server: IServerInfo | undefined = undefined) {

        let serverName = "";
        if(server) {
            serverName = server.name;
        }
        setSelectedServer(serverName);

        if(searchData) {
            searchData.deployServer_code = serverName === "" ? undefined : server?.code;
            setSearchData(searchData);
            _onSearch(searchData);    
        } else {
            // onSearch();
        }
    }

    function onDeployPend() {
        setViewConfirm(true);
        setConfirmTitle("배포보류");
        setConfirmDesc("배포보류를 진행하시겠습니까?");
    }

    function onDeployEnd() {
        setViewConfirm(true);
        setConfirmTitle("배포종료");
        setConfirmDesc("배포종료를 진행하시겠습니까?");
    }

    function onDeployPendCancel() {
        setViewConfirm(true);
        setConfirmTitle("배포보류취소");
        setConfirmDesc("배포보류 취소를 진행하시겠습니까?");
    }
    
    async function onCheckTableCreate() {
        
        // {{APISERVER_HOST}}/api/deploy/deployRequestDateList   request  {"limit_page":1,"limit_pageItemCnt":3}

        const data = {                            
            "limit_page": 1,
            "limit_pageItemCnt": 10,
        }    

        let result : any[] = [];
    
        await axios.post(util.getHost()+'/api/deploy/deployRequestDateList', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
            } else {
                result = response.data.vo;            
            }

        }).catch((err) => {
            logger.log(err);
        });          

        logger.log("onCheckTableCreate", data, result);

        if(result && result.length > 0) {
            const item = result[0];
            if(item.process !== null) {
                alert(item.process);
            }
        }
    }

    async function onCompareDeployTextbook() {
        
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            const _check = document.getElementById("TB_check_"+i) as HTMLInputElement;
            if(item && _check && _check.checked) {

                const data = {                            
                    "id": item.id,
                }    
        
                let result = null;
            
                await axios.post(util.getHost()+'/api/deploy/compareDeployTextbook', data , { headers: headers()
                }).then((response) => {
                    logger.log(response);
                    if(response.data.errorMessage) {
                        alert(response.data.errorMessage);
                    } else {
                        result = response.data.vo;      
                        if(result) {
                            alert(result.message);
                        }      
                    }
        
                }).catch((err) => {
                    logger.log(err);
                });          
        
                logger.log("onCompareDeployTextbook", data, result);
        
                break;
            }            
        }    
    }

    async function onSuccessDeployTextbook() {
        
        let result = null;

        const uid = util.getUserInfo("uid");

        const data = 
        {
            // "deployResult": [{"deployStatus":"10","serverCodeList":["S", "T", "BM", "BE"]}],
            "deployResult": [{"deployStatus":"10"}],
            "description": "TEST_" + uid,
        }

        await axios.post(util.getHost()+'/api/deploy/deployChangeStatus_sy', data , { headers: headers()
        }).then((response) => {
            logger.log(response);
            if(response.data.errorMessage) {
                alert(response.data.errorMessage);
            } else {
                result = response.data.vo;      
                if(result) {
                    alert(result);
                }      
            }

        }).catch((err) => {
            logger.log(err);
        });          

        logger.log("onSuccessDeployTextbook", data, result);

    }

    function onClickDateIcon(id: string) {

        const element = document.getElementById(id) as HTMLInputElement;
        element.value = "";
        element.click();
    }
        
    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        
        logger.log("onChangeCalendar", e.target)

        const arrSD = e.target.value.split("/");
        const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]     
        
        const new_element = document.getElementById("new_"+e.target.id) as HTMLInputElement;
        if(new_element) {
            new_element.value = val;
        }
    }

    const isAdmin = util.getHasAuthMenu("");
    // const isAdmin = true;

    const TAB_BUTTON_STYLE = {
        borderRadius: "1rem", // 모든 방향의 border-radius를 한번에 지정
    };

    return (
        <div>

            <div className="top-title">
                <h3>배포 관리</h3>
            </div>

            <div className="" id="subject" role="tabpanel" aria-labelledby="subject-tab"> 

                <div className="mb-3 text-right">
                    {isDev &&
                    <>
                    <button type="button" className="btn-sky mr-2" disabled={!isAdmin} onClick={() => {
                        onSuccessDeployTextbook();
                    }}>배포성공(시스템 관리자용)</button>
                    <button type="button" className="btn-sky mr-2" onClick={() => {
                        onCheckTableCreate();                        
                    }} disabled={!isAdmin}>요청 리스트</button>
                    </>}

                    <button type="button" className="btn-sky mr-2" onClick={() => {
                        setViewDeployTable(true);
                    }} disabled={!isAdmin}>배포테이블</button>
                    <button type="button" className="btn-sky mr-2" onClick={() => {
                        onDeployHistory();
                    }} disabled={!isAdmin}>배포이력</button>
                    <button type="button" className="btn-sky mr-2" onClick={() => {
                        
                        let deployContents = [];

                        for (let i = 0; i < list.length; i++) {
                            const item = list[i];
                            const _check = document.getElementById("TB_check_"+i) as HTMLInputElement;
                            if(item && _check && _check.checked) {     
                                deployContents.push(item);                           
                            }            
                        }    
                        logger.log("선택배포", deployContents);    

                        if(deployContents.length > 0) {
                            setDeployContents(deployContents);            
                            setViewDeployProcess(true);    
                        } else {
                            props.showModal("", "배포할 콘텐츠를 선택해 주세요.");
                        }
                        
                    }} disabled={!isAdmin}>선택배포</button>
                    <button type="button" className="btn-sky" onClick={() => {            
                        setDeployContents(null);
                        setViewDeployProcess(true);
                    }} disabled={!isAdmin}>일괄배포</button>
                </div>

                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    
                    <div className="mb-2">
                        <fieldset>
                            <label htmlFor="curriBook" className="ml-8">교과과정</label>
                        </fieldset>
                        <fieldset>
                            <select id="curriBook" name="curriBook">
                                <option value="">전체</option>              
                                {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset style={{display: util.getCurrentBrand()?.code === "VIVA01" ? "" : "none"}}>
                            <label htmlFor="serviceType" className="ml-8">서비스 유형</label>
                        </fieldset>
                        <fieldset style={{display: util.getCurrentBrand()?.code === "VIVA01" ? "" : "none"}}>
                            <select id="serviceType" name="serviceType">
                                <option value="">전체</option>              
                                {curriInfo && curriInfo.serviceType && (curriInfo.serviceType as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                    </div>
                    <div className="">
                        <fieldset>
                            <label htmlFor="date" className="ml-8">등록일</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='reg_sdate' name='reg_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={() => onClickDateIcon("reg_sdate")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='reg_edate' name='reg_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={() => onClickDateIcon("reg_edate")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <label htmlFor="date" className="ml-8">배포요청일</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='deploy_sdate' name='deploy_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={() => onClickDateIcon("deploy_sdate")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='deploy_edate' name='deploy_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={() => onClickDateIcon("deploy_edate")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                    </div>

                    <div className="">
                        <fieldset>
                            <label htmlFor="contentsType" className="ml-8">콘텐츠 구분</label>
                            <select id="contentsType" name="contentsType" onChange={(e) => {                                
                                setContentsType(e.currentTarget.value);
                                const articleCategory = document.getElementById("articleCategory") as HTMLSelectElement;
                                if(articleCategory) {
                                    articleCategory.selectedIndex = 0;
                                }
                            }}>
                                <option value="">전체</option>              
                                <option value="article">아티클(콘텐츠)</option>              
                                <option value="sets">세트지(수업자료)</option>              
                                <option value="textbook">교과서</option>      
                            </select>
                        </fieldset>
                        
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">콘텐츠 구분2</label>
                            <select id="articleCategory" name="articleCategory" disabled={contentsType !== "article"}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo.articleCategory && (curriInfo.articleCategory as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">공개여부</label>
                            <select id="publicOpen" name="publicOpen">
                                <option value={""}>전체</option>
                                <option value={"1"}>공개</option>
                                <option value={"0"}>비공개</option>
                            </select>
                        </fieldset>

                        {/* <fieldset>
                            <label htmlFor="search-5" className="ml-8">배포상태</label>
                            <select id="deployStatus" name="deployStatus">
                                <option value={""}>전체</option>
                                <option value={"open"}>보류</option>
                                <option value={"notopen"}>완료</option>
                            </select>
                        </fieldset> */}

                    </div>

                    <div className="mt-5 text-right">

                        <fieldset>
                            <label htmlFor="keyword_type">검색</label>
                            <select className="mr-2" id="TB_keyword_type" name="keyword_type">
                                <option value={"name"}>콘텐츠 명</option>
                                <option value={"key"}>Key</option>
                                <option value={"creator"}>등록자</option>
                                <option value={"updater"}>배포요청자</option>
                            </select>
                            <input type="text" name="keyword" id="TB_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrPage(1);
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={() => {
                                setCurrPage(1);
                                onSearch();
                            }}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>

                    </div>                        
                </div>
                
                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3">
                    <form action="" className="flex justify-between">
                        <fieldset>
                            <span>총 <strong className="text-red">{listCount}</strong>건</span>
                            <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                            <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={100}>100</option>
                            </select>
                        </fieldset>
                    </form>                    
                </div>

                <div className="mt-6 mb-3 font-bold">* 배포진행 목록</div>

                <div className="overflow-x-auto px-5 mt-5 mb-3">
                    <ul className="tab-list !flex-nowrap !mb-0" style={{borderBottomWidth: 0}}>
                        <li onClick={() => {
                            onChangeServer();
                        }}>
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedServer === "" ? "page" : undefined} className={selectedServer === "" ? "active" : undefined}>전체</a></li>
                        
                        {serverList.map((server, idx) => {
                            return(
                                <li key={idx} className="ml-1" onClick={() => {
                                    onChangeServer(server);
                                }}>
                                    <a href="#" style={TAB_BUTTON_STYLE} aria-current={selectedServer === server.name ? "page" : undefined} className={selectedServer === server.name ? "active" : undefined}>{server.name}</a></li>
                            );
                        })}

                    </ul>
                </div>

                <div className="overflow-x-auto px-5 mb-3">
                    <ul className="tab-list !flex-nowrap !mb-0" style={{borderBottomWidth: 0}}>
                        <li onClick={() => onChangeDeployType("")}>
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "" ? "page" : undefined} className={deployType === "" ? "active" : undefined}>전체</a></li>
                        <li onClick={() => onChangeDeployType("0")} className="ml-1">
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "0" ? "page" : undefined} className={deployType === "0" ? "active" : undefined}>미배포</a></li>
                        <li onClick={() => onChangeDeployType("1")} className="ml-1">
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "1" ? "page" : undefined} className={deployType === "1" ? "active" : undefined}>배포대기</a></li>
                        <li onClick={() => onChangeDeployType("10")} className="ml-1">
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "10" ? "page" : undefined} className={deployType === "10" ? "active" : undefined}>배포중</a></li>
                        <li onClick={() => onChangeDeployType("3")} className="ml-1">
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "3" ? "page" : undefined} className={deployType === "3" ? "active" : undefined}>배포보류</a></li>
                        <li onClick={() => onChangeDeployType("2")} className="ml-1">
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "2" ? "page" : undefined} className={deployType === "2" ? "active" : undefined}>배포종료</a></li>
                        {/* <li onClick={() => onChangeDeployType("20")} className="ml-1">
                            <a href="#" style={TAB_BUTTON_STYLE} aria-current={deployType === "20" ? "page" : undefined} className={deployType === "20" ? "active" : undefined}>배포실패</a></li> */}
                    </ul>
                </div>

                <div className="mt-5 mb-5 flex">

                    <span className="flex" style={{display: "none"}}>
                        <select className="" id="currentBrand" name="search-3" onChange={(e) => {
                            setSelectedBrand(e.currentTarget.value);

                            searchData.brand_id = e.currentTarget.value === "" ? undefined : e.currentTarget.value;
                            setSearchData(searchData);
                            _onSearch(searchData);
                        }}>
                            <option value={""}>전체</option>
                            {brandList.map((item, idx) => {
                            return(
                                <option key={idx} value={item.id}>{item.name}</option>
                            );
                        })}
                        </select>                        
                    </span>

                    {/* <span className="mt-3 ml-1 w-[30rem]">디폴트: 전체</span> */}
                    <span className="w-avail text-right">
                        {isDev && <>
                            <button type="button" className="btn-white mr-2" onClick={onCompareDeployTextbook} disabled={!isAdmin}>배포테이블 data 컬럼과 배포전용테이블 비교</button>
                        </>}  
                        {deployType === "10" &&
                        <button type="button" className="btn-white mr-2" onClick={onDeployEnd} disabled={!isAdmin}>배포종료</button>}
                        {(deployType === "0" || deployType === "10") &&
                        <button type="button" className="btn-white" onClick={onDeployPend} disabled={!isAdmin}>배포보류</button>}
                        {deployType === "3" &&
                        <button type="button" className="btn-white" onClick={onDeployPendCancel} disabled={!isAdmin}>배포보류취소</button>}
                    </span>
                </div>

				{/* <!-- table --> */}
                {viewList &&
                <table>
                    <caption className="sr-only">목록</caption>
                    <thead>
                        <tr>
                            <th rowSpan={2} scope="col" className="pl-4 pr-3">
                                <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                const list_ck = document.getElementsByName("list_ck");
                                for (let i = 0; i < list_ck.length; i++) {
                                    const element = list_ck[i] as HTMLInputElement;
                                    if(!element.disabled) {
                                        element.checked = (e.target as HTMLInputElement).checked;
                                    }                                    
                                }
                            }} /></th>
                            <th rowSpan={2} scope="col" className="px-3">번호</th>
                            <th rowSpan={2} scope="col" className="px-3">Key</th>
                            <th rowSpan={2} scope="col" className="px-3">교과과정</th>
                            <th rowSpan={2} scope="col" className="px-3">구분</th>
                            {util.getCurrentBrand()?.code === "VIVA01" && 
                            <th rowSpan={2} scope="col" className="px-3">서비스 유형</th>}
                            <th rowSpan={2} scope="col" className="px-3">브랜드</th>
                            <th rowSpan={2} scope="col" className="px-3">자료명</th>
                            <th rowSpan={2} scope="col" className="px-3">자료구분</th>
                            <th rowSpan={2} scope="col" className="px-3">등록자</th>
                            <th rowSpan={2} scope="col" className="px-3">등록일</th>
                            <th rowSpan={2} scope="col" className="px-3">버전</th>
                            <th rowSpan={2} scope="col" className="px-3">배포요청일</th>
                            <th rowSpan={2} scope="col" className="px-3">배포요청자</th>
                            <th colSpan={selectedServer ? 1 : serverList.length} scope="col" className="pl-3 pr-4">서버</th>
                        </tr>
                        <tr>
                            {selectedServer === "" && serverList.map((server, idx) => {                            
                                return(
                                    <th key={idx} scope="col" className="pl-3 pr-3 border-l" style={{borderColor: "#d1d5db"}}>{server.name}</th>
                                    // <th scope="col" className="pl-3">S</th>
                                    // <th scope="col" className="pl-3 pr-4">B</th>        
                                );
                            })}
                            {selectedServer !== "" && 
                                <th scope="col" className="pl-3 pr-3 border-l" style={{borderColor: "#d1d5db"}}>{selectedServer}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {viewList && list && (list as any[]).length === 0 &&
                        <tr>    
                            <td colSpan={14}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as any[]).map((item, idx) => {

                        const totalCnt = item.full_count ? item.full_count : 0;
                        const _numOfPage = localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        let _target = "";
                        if(item.target === "textbook") {
                            _target = "교과서";
                        } else if(item.target === "article") {
                            _target = "아티클";
                        } else if(item.target === "sets") {
                            _target = "세트지";
                        }
                        
                        const _brand = brandList.find((b) => b.id === item.brand_id);
                        
                        const regdate = item.regdate.substring(0, 10).replaceAll("-", ".") + "<br />" + item.regdate.substring(11, 16);

                        let serviceTypeVal = "-";
                        if(item.serviceType) {
                            const serviceType = curriInfo?.serviceType.find((meta) => meta.id === item.serviceType);
                            serviceTypeVal = serviceType ? serviceType.val : ""
                        }
                        
                        let curriBookVal = "-";
                        if(item.curriBook) {
                            const curriBook = curriInfo?.curriBook.find((meta) => meta.id === item.curriBook);
                            curriBookVal = curriBook ? curriBook.val : ""
                        }

                        const deploydate = item.deploydate_1 ? (item.deploydate_1.substring(0, 10).replaceAll("-", ".") + "<br />" + item.deploydate_1.substring(11, 16)) : "-";

                        return (
                            <tr className="" key={idx}>
                                <td>
                                    <input id={"TB_check_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" onClick={(e) => {
                                        const list_ck = document.getElementsByName("list_ck");
                                        let cntChecked = 0;
                                        for (let i = 0; i < list_ck.length; i++) {
                                            const element = list_ck[i] as HTMLInputElement;
                                            if(element.checked) {
                                                cntChecked = cntChecked + 1;
                                            }
                                        }
                                        const all_check = document.getElementById("all_check") as HTMLInputElement;
                                        if(cntChecked === list_ck.length) {                                                
                                            all_check.checked = true;                                                
                                        } else {
                                            all_check.checked = false;                                                
                                        }
                                    }}/>
                                </td>
                                <td className="" >{_num}</td>
                                <td className="" >{item.targetId}</td>
                                <td className="" >{curriBookVal}</td>
                                <td className="" >{_target}</td>
                                {util.getCurrentBrand()?.code === "VIVA01" && 
                                <td className="" >{serviceTypeVal}</td>}
                                <td className="" >{_brand ? _brand.name : ""}</td>
                                <td className="" >{item.targetName}</td>
                                <td className="" >{item.targetCategoryVal}</td>
                                <td className="" >{item.creator}</td>
                                <td className="" dangerouslySetInnerHTML={{ __html: regdate }}></td>
                                <td className="" >{item.version}</td>
                                <td className="" dangerouslySetInnerHTML={{ __html: deploydate }}></td>
                                <td className="" >{item.updater ? item.updater : "-"}</td>
                                {selectedServer === "" && serverList.map((server, server_idx) => {
                                    let deployStatusVal = "-";
                                    let deployStatusCN = "btn-white-s";

                                    let bDeployType = false;

                                    if(item.deployStatus === 3) {
                                        bDeployType = deployType === "3";
                                        deployStatusVal = "배포보류";   
                                    } 

                                    const deployStatusList = item.deployStatusList;
                                    const deployStatus = deployStatusList.find((status: { deployServer_code: string; deployStatus: string; }) => status.deployServer_code === server.code);

                                    if(deployStatus) {

                                        deployStatusVal = deployStatus.deployStatus;
                                        bDeployType = deployType === deployStatusVal;

                                        // 0(초기값), 1(배포대기), 2(배포종료), 3(배포보류) , 10(배포중), 20(배포실패)
                                        if(deployStatusVal === "0") {
                                            deployStatusVal = "미배포";   
                                        } else if(deployStatusVal === "1") {
                                            deployStatusVal = "배포대기";   
                                            deployStatusCN = "btn-sky-s";
                                        } else if(deployStatusVal === "2") {
                                            deployStatusVal = "배포종료";   
                                        } else if(deployStatusVal === "3") {
                                            deployStatusVal = "배포보류";   
                                        } else if(deployStatusVal === "10") {
                                            deployStatusVal = "배포중";   
                                        // } else if(deployStatusVal === "20") {
                                        //     deployStatusVal = "배포실패";   
                                        }                        
                                    } else {
                                        // deployStatusVal = "상태없슴";
                                    }                            

                                    return(
                                        <td key={server_idx} className="" style={{backgroundColor: bDeployType ? "#909090" : "#ffffff", color: bDeployType ? "#ffffff" : ""}}>{deployStatusVal}</td>
                                    );
                                })}

                                {selectedServer !== "" && serverList.map((server, server_idx) => {
                                    let deployStatusVal = "-";
                                    let deployStatusCN = "btn-white-s";

                                    const bSelectedServer = selectedServer === server.name;

                                    if(item.deployStatus === 3) {
                                        deployStatusVal = "배포보류";   
                                    } else {
                                        const deployStatusList = item.deployStatusList;
                                        const deployStatus = deployStatusList.find((status: { deployServer_code: string; deployStatus: string; }) => status.deployServer_code === server.code);
    
                                        if(deployStatus) {
    
                                            deployStatusVal = deployStatus.deployStatus;
    
                                            // 0(초기값), 1(배포대기), 2(배포종료), 3(배포보류) , 10(배포중), 20(배포실패)
                                            if(deployStatusVal === "0") {
                                                deployStatusVal = "미배포";   
                                            } else if(deployStatusVal === "1") {
                                                deployStatusVal = "배포대기";   
                                                deployStatusCN = "btn-sky-s";
                                            } else if(deployStatusVal === "2") {
                                                deployStatusVal = "배포종료";   
                                            } else if(deployStatusVal === "3") {
                                                deployStatusVal = "배포보류";   
                                            } else if(deployStatusVal === "10") {
                                                deployStatusVal = "배포중";   
                                            // } else if(deployStatusVal === "20") {
                                            //     deployStatusVal = "배포실패";   
                                            }                        
                                        } else {
                                            // deployStatusVal = "상태없슴";
                                        }
                                    }

                                    return(
                                        <td key={server_idx} className="" style={{display: bSelectedServer ? "" : "none"}} >{deployStatusVal}</td>
                                    );
                                })}

                            </tr>
                        )})}
                    </tbody>
                </table>}
				{/* <!-- .//table -->	 */}

                <div className="relative mt-5" style={{display: listCount > 0 ? "" : "none"}}>
                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div>

            {viewHistoryDeploy && <PopupHistoryNDeploy textbook={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewHistoryDeploy(false);
            }} onDeploy={() => {
                const _searchData = searchData;
                _onSearch(_searchData);                    
            }} />}

            {viewDeployHistory && <PopupDeployHistory selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewDeployHistory(false);
            }} />}

            {viewDeployTable && <PopupDeployTable selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewDeployTable(false);
            }} />}

            <PopupConfirm view={viewConfirm} title={confirmTitle} description={confirmDesc} onClose={onConfirmClose} onOk={onConfirmOK} showModal={props.showModal} />

            {/* {viewUpdateHistory && <PopupUpdateHistory selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewUpdateHistory(false);
            }} />} */}

            {viewDeployProcess && <PopupDeployProcess deployContents={deployContents} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewDeployProcess(false);
                onSearch();
            }} />}

        </div>

    );  
}

export default TextBookDeployList;
