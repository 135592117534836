import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, IUser } from "../common";  
import { logger } from "../logger";
import { IEngMeta } from "./MetaStudyMapEngEdit";
import { ISearchData } from "./MetaManager";

interface IPopupCurriBookSearch {
    selectedCurriBooks: IMeta[];
    onSelect: (items: IMeta[]) => void;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function PopupCurriBookSearch(props: IPopupCurriBookSearch) {
      
    const [list, setList] = useState<IMeta[]>([]);
    const [searchData, setSearchData] = useState(null as any);
    const [teachingSubject, setTeachingSubject] = useState<IMeta[]>([]);

    const curriInfo = util.getCurrentCurriInfo();
    
    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            logger.log("selectedCurriBooks", props.selectedCurriBooks);

            getCurriBookList();
            getTeachingSubject();
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(list && list.length > 0) {
            logger.log("list", list);

            if(props.selectedCurriBooks && props.selectedCurriBooks.length > 0) {
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];

                    const element = document.getElementById("IM_chk_"+i) as HTMLInputElement;
                    if(element) {
                        element.checked = false;
                    }
                    if(props.selectedCurriBooks.find((meta) => meta.id === item.id)) {
                        logger.log("found", i, item.id);
                        if(element) {
                            element.checked = true;
                        }
                    }
                }                    
            }
        }
    }, [list])

    async function getCurriBookList() {

        props.showLoading(true);

        const _list = await getMetaList("1", "curriBook");

        const includeList = [];
        const notIncludeList = [];
        for (let i = 0; i < _list.length; i++) {
            const item = _list[i];
            if(props.selectedCurriBooks.find((meta) => meta.id === item.id)) {
                includeList.push(item);
            } else {
                notIncludeList.push(item);
            }
        }
        logger.log("getCurriBookList", _list, includeList, notIncludeList);

        setList(includeList.concat(notIncludeList));    

        props.showLoading(false);
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function getTeachingSubject() {

        props.showLoading(true);

        const list = await util.getMetaList("1", "teachingSubject");
        if(list) {
            const _list = list.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            logger.log("getTeachingSubject", _list);
            setTeachingSubject(_list);    
        }
        
        props.showLoading(false);
    }

    async function onChangeForCurriSubject() {
        
        logger.log("onChangeForCurriSubject");

        const brand = util.getCurrentBrand();

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "name":"teachingSubject",
            "is_active": true,
            "metaMap": metaMap,
        }

        const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
        if(MM_Subject) {
            const _curriSubject = curriInfo?.curriSubject.find((meta) => meta.val === MM_Subject.value)
            if(_curriSubject) {
                metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
            }
        }

        data.metaMap = metaMap;
        
        props.showLoading(true);

        const list = await util.searchMeta(data);
        if(list) {
            setTeachingSubject(list);
            setTimeout(() => {
                const MM_TeachingSubject = document.getElementById("MM_TeachingSubject") as HTMLSelectElement;
                MM_TeachingSubject.selectedIndex = 0;
            }, 1);
        }

        logger.log("onChangeForCurriSubject", data, list);

        props.showLoading(false);

        return list;

    }

    async function onSearch() {

        const MM_Year = document.getElementById("MM_Year") as HTMLSelectElement;
        const MM_School = document.getElementById("MM_School") as HTMLSelectElement;
        const MM_Subject = document.getElementById("MM_Subject") as HTMLSelectElement;
        const MM_TeachingSubject = document.getElementById("MM_TeachingSubject") as HTMLSelectElement;

        const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
        const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

        let search = "";
        if(CL_keyword) {
            search = CL_keyword.value;
        }
        let searchField = "";
        if(CL_keyword_type) {
            searchField = CL_keyword_type.value;
        }

        const currBrand = util.getCurrentBrand();

        if(MM_Year.selectedIndex === 0 && MM_School.selectedIndex === 0 && MM_Subject.selectedIndex === 0 && MM_TeachingSubject.selectedIndex === 0 && searchField === "") {
            getMetaList("1", "curriBook");
        } else {

            const metaMap : IMeta[] = [];
            if(MM_Year.selectedIndex > 0) {
                const meta = curriInfo?.curriYear[MM_Year.selectedIndex - 1];
                if(meta) {
                    metaMap.push(meta);
                }
            }
            if(MM_School.selectedIndex > 0) {
                const meta = curriInfo?.curriSchool[MM_School.selectedIndex - 1];
                if(meta) {
                    metaMap.push(meta);
                }
            }
            if(MM_Subject.selectedIndex > 0) {
                const meta = curriInfo?.curriSubject[MM_Subject.selectedIndex - 1];
                if(meta) {
                    metaMap.push(meta);
                }
            }
            if(MM_TeachingSubject.selectedIndex > 0) {
                const meta = teachingSubject[MM_TeachingSubject.selectedIndex - 1];
                if(meta) {
                    metaMap.push(meta);
                }
            }
            let include = "";
            const MM_Include = document.getElementById("MM_Include") as HTMLSelectElement;
            if(MM_Include.selectedIndex > 0) {
                include = MM_Include.value;
            }
                        
            const data = {
                "brand_id": util.getCurrentBrand()?.id,
                "name": "curriBook",
                "depth": "1",
                "metaMap": metaMap,
                "is_active": true,
                "search": search === "" ? undefined : search,
                "searchField": search === "" ? undefined : searchField,    
                "include": include,
            }
    
            logger.log("onSearch", data);
            setSearchData(data);
    
            // const data = {
            //     "brand_id": util.getCurrentBrand()?.id,
            //     "name": "studyMap",
            //     "depth": "1",
            //     "metaMap": metaMap,
            //     "is_active": true,
            // }
    
            // if(cb_id !== null) {
            //     metaMap.push({"name":"curriBook", "id":Number(cb_id)});
            //     data.metaMap = metaMap;
            // }
            
            // props.showLoading(true);
    
            // const list = await util.searchMeta(data);
    
            props.showLoading(true);
            
            const list = await util.searchMeta(data);
            if(list) {
                const _list = (list as IMeta[]).sort((a, b) => a.id! < b.id! ? 1 : -1);	
            
                const includeList = [];
                const notIncludeList = [];
                for (let i = 0; i < _list.length; i++) {
                    const item = _list[i];
                    if(props.selectedCurriBooks.find((meta) => meta.id === item.id)) {
                        includeList.push(item);
                    } else {
                        notIncludeList.push(item);
                    }
                }
                logger.log("onSearch", data, _list, includeList, notIncludeList);

                if(include === "1") {
                    setList(includeList);    
                } else if(include === "0") {
                    setList(notIncludeList);    
                } else {
                    setList(includeList.concat(notIncludeList));    
                }
            }

            props.showLoading(false);
        }
    }

    // function onSearch() {

    //     if(!curriInfo) {
    //         return;
    //     }
    
    //     const brand = util.getCurrentBrand();
    //     if(!brand) {
    //         return;
    //     }

    //     const CL_curriBook = document.getElementById("CL_curriBook") as HTMLSelectElement;
    //     const CL_curriBook_val = curriInfo.curriBook[CL_curriBook.selectedIndex-1];

    //     const CL_html_curriUnit1_val = curriUnit1 ? (curriUnit1[CL_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
    //     const CL_html_curriUnit2_val = curriUnit2 ? (curriUnit2[CL_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
    //     const CL_html_curriUnit3_val = curriUnit3 ? (curriUnit3[CL_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
    //     const CL_html_curriUnit4_val = curriUnit4 ? (curriUnit4[CL_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
    //     const CL_html_curriUnit5_val = curriUnit5 ? (curriUnit5[CL_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;

    //     const CL_contentsType = document.getElementById("CL_contentsType") as HTMLSelectElement;

    //     const CL_hint = document.getElementById("CL_hint") as HTMLSelectElement;
    //     const CL_answer = document.getElementById("CL_answer") as HTMLSelectElement;
    //     const CL_question = document.getElementById("CL_question") as HTMLSelectElement;

    //     const CL_learning = document.getElementById("CL_learning") as HTMLSelectElement;
    //     const CL_conversion = document.getElementById("CL_conversion") as HTMLSelectElement;

    //     const CL_review = document.getElementById("CL_review") as HTMLSelectElement;

    //     let articleTypeId = undefined;
    //     if(CL_contentsType.value !== "") {
    //         if(curriInfo && curriInfo.articleType) {
    //             const articleType = curriInfo.articleType as IMeta[];
    //             const _articleType = articleType.find((article, _) => article.val === CL_contentsType.value);
    //             if(_articleType) {
    //                 articleTypeId = _articleType.id;
    //             }
    //         }    
    //     }

    //     const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
    //     const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

    //     let search = "";
    //     if(CL_keyword) {
    //         search = CL_keyword.value;
    //     }
    //     let searchField = "";
    //     if(CL_keyword_type) {
    //         searchField = CL_keyword_type.value;
    //     }

    //     const CL_update = document.getElementById("CL_update") as HTMLSelectElement;

    //     const data = {
    //         brand_id: brand.id ? brand.id : null,

    //         // articleCategory: articleCategoryId,
    //         articleType: articleTypeId,

    //         search: search,
    //         searchField: searchField,

    //         hintYN: CL_hint && CL_hint.value !== "" ? CL_hint.value : undefined,
    //         sbsSolutionYN: CL_answer && CL_answer.value !== "" ? CL_answer.value : undefined,
    //         sbsChattingYN: CL_question && CL_question.value !== "" ? CL_question.value : undefined,
    //         articleUpdateFlag: CL_update && CL_update.value !== "" ? CL_update.value === "Y" ? true : false  : undefined,

    //         learning: CL_learning && CL_learning.value !== "" ? CL_learning.value : undefined,
    //         conversion: CL_conversion && CL_conversion.value !== "" ? CL_conversion.value : undefined,

    //         reviewStatus: CL_review && CL_review.value !== "" ? CL_review.value : undefined,

    //         curriBook: CL_curriBook_val ? CL_curriBook_val.id : undefined,
    //         curriUnit1: CL_html_curriUnit1_val ? CL_html_curriUnit1_val.id : undefined,
    //         curriUnit2: CL_html_curriUnit2_val ? CL_html_curriUnit2_val.id : undefined,
    //         curriUnit3: CL_html_curriUnit3_val ? CL_html_curriUnit3_val.id : undefined,
    //         curriUnit4: CL_html_curriUnit4_val ? CL_html_curriUnit4_val.id : undefined,
    //         curriUnit5: CL_html_curriUnit5_val ? CL_html_curriUnit5_val.id : undefined,

    //         orderby_col: "id", 
    //         orderby_dir: "desc",
    //         limit_page: 1,
    //         limit_pageItemCnt: localStorage.getItem("AITutor_numOfPage") ? Number(localStorage.getItem("AITutor_numOfPage")) : 10,
    //     };

    //     logger.log("onSearch", data);

    //     setSearchData(data);
    //     _onSearch(data);
    // }

    function onClose() {
        props.onClose();
    }

    function onSelect() {

        const result = [];

        for (let i = 0; i < list.length; i++) {

            const item = list[i] as IMeta;
            const radio = document.getElementById("IM_chk_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {
                result.push(item);
            }            
        }        
        logger.log("onSelect", result);
        props.onSelect(result);
    }

    return (
                
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{minWidth:"60rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">교재 선택</h2>
                            <div className="px-5">
                                
                                <div className="py-8 text-center">

                                    {/* <!-- 검색영역 --> */}
                                    <div className="pl-4 py-8 mb-8 border border-gray-300 rounded-xl">
                                        <div className="pl-4">
                                            <fieldset className="flex text-left items-center">
                                            {/* <label htmlFor="sel-title">{metaName === "curriBook" ? "학교급" : "교과목"}</label> */}
                                                
                                                <label htmlFor="sel-title">{"교육과정 년도"}</label>
                                                <select id="MM_Year" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="">전체</option>
                                                    {curriInfo && curriInfo.curriYear && (curriInfo.curriYear as IMeta[]).map((item, idx) => {
                                                        return(
                                                            <option key={idx} value={item.val}>{item.val}</option>              
                                                    )})}
                                                </select>                        
                                                <label className="ml-2" htmlFor="sel-title">{"학교급"}</label>
                                                <select id="MM_School" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="">전체</option>
                                                    {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                                        return(
                                                            <option key={idx} value={item.val}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <label className="ml-2" htmlFor="sel-title">{"교과"}</label>
                                                <select id="MM_Subject" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} onChange={onChangeForCurriSubject}>
                                                    <option value="">전체</option>
                                                    {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                                        return(
                                                            <option key={idx} value={item.val}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <label className="ml-2" htmlFor="sel-title">{"과목"}</label>
                                                <select id="MM_TeachingSubject" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="">전체</option>
                                                    {teachingSubject && (teachingSubject as IMeta[]).map((item, idx) => {
                                                        return(
                                                            <option key={idx} value={item.val}>{item.val}</option>              
                                                    )})}
                                                </select>
                                                <label className="ml-2" htmlFor="sel-title">{"설정"}</label>
                                                <select id="MM_Include" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                    <option value="">전체</option>
                                                    <option value="1">적용</option>
                                                    <option value="0">미적용</option>
                                                </select>
                                            </fieldset>

                                            <div className="mt-3 flex justify-center items-center">
                                                <fieldset className="w-avail text-right pr-4">                                                
                                                
                                                    <label htmlFor="sel-meta" className="">검색</label>
                                                    <select id="CL_keyword_type" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}}>
                                                        <option value={"val"}>타이틀</option>
                                                        <option value={"id"}>KEY</option>
                                                        <option value={"creator"}>등록자</option>
                                                        <option value={"code"}>코드</option>
                                                    </select>
                                                    <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-2" style={{paddingTop:"0.2rem", paddingBottom:"0.2rem"}} onKeyUp={(e) => {
                                                        if (e.key === 'Enter') {
                                                            onSearch();
                                                        }
                                                    }} />

                                                    <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                                        <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                                    </button>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- .//검색영역 --> */}

                                    <div className="my-5 text-right">
                                        <button type="button" className="btn-sky" onClick={onSelect}>적용</button>
                                    </div>

                                    <div>
                                        <table>
                                            <caption className="sr-only">"title"</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="pl-4 pr-3">
                                                        <input id="all_check" type="checkbox" className="h-4 w-4 rounded" onClick={(e) => {
                                                            const input = e.target as HTMLInputElement;
                                                            if(input) {
                                                                (list as IMeta[]).map((_, idx) => {
                                                                    const IM_check = document.getElementById("IM_chk_"+idx) as HTMLInputElement;
                                                                    IM_check.checked = input.checked;
                                                                })
                                                            }
                                                        }}                                
                                                        />
                                                    </th>

                                                    <th scope="col" className="pl-4 pr-3" style={{width:"80px"}}>번호</th>
                                                    <th scope="col" className="px-3">메타 값</th>
                                                    {/* <th scope="col" className="px-3">학교급</th> */}
                                                    <th scope="col" className="px-3">Key</th>
                                                    <th scope="col" className="px-3">코드</th>
                                                    <th scope="col" className="px-3">설명</th>
                                                    <th scope="col" className="px-3" style={{width:"80px"}}>설정</th>
                                                    <th scope="col" className="px-3" style={{width:"100px"}}>등록자</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {list && (list as IMeta[]).length === 0 &&
                                                <tr>
                                                    <td colSpan={7}>등록된 메타 정보가 없습니다.</td>
                                                </tr>}

                                                {list && (list as IMeta[]).map((item, idx) => {

                                                    const curriSchool = item.val1 ? item.val1 : "";
                                                    
                                                    return (
                                                        <tr key={idx}>
                                                            <td>
                                                                <input id={"IM_chk_"+idx} type="checkbox" name="IM_chk" className="h-4 w-4 rounded" onClick={(e) => {
                                                                    const text_ck = document.getElementsByName("IM_chk");
                                                                    let cntChecked = 0;
                                                                    for (let i = 0; i < text_ck.length; i++) {
                                                                        const element = text_ck[i] as HTMLInputElement;
                                                                        if(element.checked) {
                                                                            cntChecked = cntChecked + 1;
                                                                        }
                                                                    }
                                                                    const all_check = document.getElementById("all_check") as HTMLInputElement;
                                                                    if(cntChecked === text_ck.length) {                                                
                                                                        all_check.checked = true;                                                
                                                                    } else {
                                                                        all_check.checked = false;                                                
                                                                    }
                                                                }}/>
                                                            </td>                                
                                                            <td>{list.length-idx}</td>
                                                            <td>{item.val}</td>
                                                            {/* <td className={"text-center"}>{curriSchool}</td> */}
                                                            <td className={"text-center"}>{item.id}</td>
                                                            <td>{item.code}</td>
                                                            <td className="text-left"><p className="xl:w-80 truncate">{item.description}</p></td>
                                                            <td>{item.is_active ? "사용" : "미사용"}</td>
                                                            <td className="leading-4">{item.creator}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}
  
export default PopupCurriBookSearch;
