import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, IInstrument, ILibrary, ILibraryFile, IMeta, IScript, IText } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from 'xlsx';
import saveAs from "file-saver";

interface IInstrumentBulkRegister {
    brand: IBrand;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function InstrumentBulkRegister(props: IInstrumentBulkRegister) {

    const [files, setFiles] = useState([] as any);
    const [files2, setFiles2] = useState([] as any);

    const processed : number[] = [];

    const [fileUploading, setFileUploading] = useState(false);

    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    useEffect(() => {
    }, [])

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files;    
        logger.log("files", _url);

        setFiles(e.target.files);
    }
    
    async function onDownload() {

        const data = {
            brand_id: util.getCurrentBrand()?.id,
            limit_page: 1,
            limit_pageItemCnt: 3,
            orderby_col: "id",
            orderby_dir: "desc",
        };
        const list = await util.getCurriToolSearchList(data);
        
        if(list) {

            const checkedList: string[][] = [];

            const column = [
                "브랜드",
                "도구 이름",
                "설명",
                "코드",
                "타입",
                "교과(curriBook)",
                "단원1(curriUnit1)",
                "단원2(curriUnit2)",
                "단원3(curriUnit3)",
                "단원4(curriUnit4)",
                "단원5(curriUnit5)",
                "Intro file url",
                "Font css url",
                "Image url",
                "Width",
                "Height",
                "Tag",
                "Params",
                "Meta",
                "모듈",
                "스케일",
                "교과템 여부",
                "포함 방법",
                "사용 설정",            
            ];
    
            checkedList.push(column);
    
            for (let i = 0; i < (list as IInstrument[]).length; i++) {

                const item = list[i] as IInstrument;
                logger.log("onDownload", item);
        
                const excelData: string[] = [];
                for (let j = 0; j < column.length; j++) {
                    excelData[j] = "";
                }
    
                excelData[0] = String(util.getCurrentBrand()?.id);
                excelData[1] = item.name;
                excelData[2] = item.description;
                excelData[3] = item.code;
                excelData[4] = item.type;
                excelData[5] = item.curriBook ? String(item.curriBook) : "";
                excelData[6] = item.curriUnit1 ? String(item.curriUnit1) : "";
                excelData[7] = item.curriUnit2 ? String(item.curriUnit2) : "";
                excelData[8] = item.curriUnit3 ? String(item.curriUnit3) : "";
                excelData[9] = item.curriUnit4 ? String(item.curriUnit4) : "";
                excelData[10] = item.curriUnit5 ? String(item.curriUnit5) : "";
                excelData[11] = item.introFileUrl;
                excelData[12] = item.fontCssUrl;
                excelData[13] = item.imageUrl;
                excelData[14] = item.width;
                excelData[15] = item.height;
                excelData[16] = item.tag;
                excelData[17] = item.params;
                excelData[18] = item.meta;
                excelData[19] = item.module ? "1" : "0";
                excelData[20] = item.scale ? "1" : "0";
                excelData[21] = item.subjectTemplate ? "1" : "0";
                excelData[22] = item.includeType;
                excelData[23] = item.is_active ? "1" : "0";

                checkedList.push(excelData);      
            }
    
            logger.log("checkedList", checkedList);        
            exportToExcel(checkedList, util.getCurrentBrand()?.id === 1 ? "InstrumentMathBulkRegister" : "InstrumentEngBulkRegister");                    
        }
    }

    const exportToExcel = (data: any[], filename: string) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "sheet");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        const today = new Date();
        // saveAs(blob, "downloaded_data_" + today.toISOString() + ".xlsx");
        saveAs(blob, filename + ".xlsx");
    };

    async function onBulk() {

        if(files2 && files2.length > 0) {
            for (let i = 0; i < files2.length; i++) {
                const file = files2[i];

                var reader = new FileReader();
                reader.onload = (e) => onExcelProccessing(e, file);
                reader.readAsBinaryString(file)    
            }
        }
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {
    
            props.showLoading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            // const names = [
            //     "curriBook",
            //     "curriSchool", 
            //     "curriSubject", 
            //     "articleType",
            //     "articleCategory",
            //     "subjectAbility",
            //     "setCategory",
            //     "difficulty",
            // ];
            // const listAll = await util.getMetaNamesList(names);
            // logger.log("listAll", listAll);  

            // const curriBook = listAll.filter((meta, _) => meta.name === "curriBook");
            // const curriSchool = listAll.filter((meta, _) => meta.name === "curriSchool");
            // const curriSubject = listAll.filter((meta, _) => meta.name === "curriSubject");
            // const articleType = listAll.filter((meta, _) => meta.name === "articleType");
            // const articleCategory = listAll.filter((meta, _) => meta.name === "articleCategory");
            // const subjectAbility = listAll.filter((meta, _) => meta.name === "subjectAbility");
            // const setCategory = listAll.filter((meta, _) => meta.name === "setCategory");
            // const difficulty = listAll.filter((meta, _) => meta.name === "difficulty");

            for (let i = 2; i < dataParse.length; i++) {

                const item = dataParse[i];

                const _item = item as string[];

                if(_item.length > 0) {

                    const code = _item[3];    
                    const data = {
                        
                        "brand_id": _item[0],

                        "name": _item[1], 
                        "description": _item[2],
                        "code": code,
                        "type": _item[4],

                        "curriBook": _item[5] ? _item[5] : undefined,
                        "curriUnit1": _item[6] ? _item[6] : undefined,
                        "curriUnit2": _item[7] ? _item[7] : undefined,
                        "curriUnit3": _item[8] ? _item[8] : undefined,
                        "curriUnit4": _item[9] ? _item[9] : undefined,
                        "curriUnit5": _item[10] ? _item[10] : undefined,

                        "introFileUrl": _item[11],
                        "fontCssUrl": _item[12],
                        "imageUrl": _item[13],

                        "width": _item[14],
                        "height": _item[15],
                        "tag": _item[16],
                        "params": _item[17],
                        "meta": _item[18],

                        "module": _item[19] === "1" ? true : false,
                        "scale": _item[20] === "1" ? true : false,
                        "subjectTemplate": _item[21] === "1" ? true : false,

                        "includeType": _item[22],
            
                        "is_active": _item[23] === "1" ? true : false, 
                        // "is_publicOpen": _item[24] ? true : false,

                        "creator": util.getUserInfo("uid"),
            
                        // "metaMap": [], 
                    };

                    // logger.log("data", data);

                    const result = await util.curriToolForSave(data, "insert");
                    logger.log("_onSave", result);
                }
            }

            props.showLoading(false);

            props.showModal("", "등록이 완료 되었습니다.");
        }
    }

    const handleChange2 = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files;    
        logger.log("files", _url);

        setFiles2(e.target.files);
    }

    return (
        <div>
            <div>

                <h4>수학도구 일괄등록(엑셀)</h4>

                {/* <!-- table --> */}
                <table>
                    <caption className="sr-only"></caption>
                    <tbody>
                        <tr>
                            <th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
                            <td className="text-left">
                                <label htmlFor="file-input" className="sr-only">파일찾기</label>
                                {/* <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file"/> */}
                                <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange2(e)}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <!-- .//table -->" */}

            </div>

            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-sky" onClick={() => {
                        onDownload();
                        // const url = common.S3Server + "sampleForm/registmathtool.xlsx";
                        // window.open(url, "_blank", "noopener, noreferrer");                            
                    }}>엑셀다운로드</button>
                    <button type="button" className="btn-sky mr-3" onClick={onBulk}>저장</button>
                    </div>
            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

        </div>
    );
}

export default InstrumentBulkRegister;